import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as actions from '../../actions';
import PropTypes from 'prop-types';
import axios from 'axios';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { REACT_APP_JAAS_APP_ID } from '../../constants';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  withStyles,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import './patientRoomStyles.css';
import pharmacyIcon from '../../images/pharmacy_64';
import uploadIcon from '../../images/upload_64';
import FileUpload from '../dialogs/FileUpload';

import constants from '../../constants';
import eventsApi from '../../api/events-api';

const styles2 = (theme) => ({
  iconButton: {
    borderRadius: 0,
    height: '16.67%',
    padding: 0,
    width: '100%',
    flex: 'wrap',
  },
  // iconButtonHighlighted: {
  //   borderRadius: 0,
  //   padding: 0,
  //   height: '16.67%',
  //   width: '100%',
  //   backgroundColor: '#db4437'
  // },
  //actualIcon: {
  //  fontSize: 30,
  //   color: 'white'
  // },

  roomContainer: {
    borderRadius: 10,
    overflow: 'hidden',
    margin: 0,
    position: 'relative',
    height: '90vh',
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class PatientVideoChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: {},
      timeWarning: false,
      jaasApi: null,
      doctorCallId: '',
      openPharmacyDialog: false,
      openUploadDialog: false,
      openConfirmationDialog: false,
    };
  }

  async componentDidMount() {
    this.subscribeToUpdates();
  }

  manageUpdates = (data) => {
    switch (data) {
      case 'doctor_left_appointment':
        this.state.jaasApi.executeCommand('hangup');
        break;
      default:
        break;
    }
  };

  subscribeToUpdates = () => {
    eventsApi.on(`appt-updates:${this.props.room}`, (message) =>
      this.manageUpdates(message)
    );
  };

  timeWarning = () => {
    this.setState({ timeWarning: true });
  };

  closePharmacyDialog = () => {
    this.setState({ openPharmacyDialog: false });
  };

  closeConfirmationDialog = (endCall) => {
    const { jaasApi } = this.state;
    this.setState({ openConfirmationDialog: false });
    if (endCall) {
      jaasApi.executeCommand('hangup');
    }
  };

  getDoctorId = (rooms) => {
    const participants = rooms[0].participants;
    const doctor = participants.find(
      (participant) => participant.role === 'moderator'
    );
    if (doctor) {
      this.setState({ doctorCallId: doctor.id });
    }
  };

  updatePatientStatus = async (room, status) => {
    const body = {
      apptId: room,
    };
    switch (status) {
      case 'connected':
        body.connected = true;
        break;
      case 'pre-meeting':
        body.preMeeting = true;
        break;
      default:
        break;
    }
    await axios.put('/api/patient-video-call-status', body);
  };

  render() {
    const { classes, token, auth, room } = this.props;
    const { openPharmacyDialog, openConfirmationDialog, openUploadDialog } =
      this.state;

    return (
      <Grid container className={classes.roomContainer} direction="row">
        <Grid item xs={12}>
          <JaaSMeeting
            appId={REACT_APP_JAAS_APP_ID}
            roomName={room}
            jwt={token}
            configOverwrite={{
              readOnlyName: true, // disable name editing; if disableProfile is true, this has no effect
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              disablePolls: true,
              disableModeratorIndicator: true,
              disableProfile: true,
              disableSelfViewSettings: true,
              enableSaveLogs: false,
              disableShowMoreStats: true,
              disableAddingBackgroundImages: true,
              disableReactions: true,
              disableChatSmileys: true,
              // hideConferenceSubject: true,
              // hideConferenceTimer: true,
              localSubject: 'SnapMED Virtual Call',
              toolbarConfig: {
                alwaysVisible: true,
              },
              toolbarButtons: [
                'camera',
                'microphone',
                'chat',
                'fullscreen',
                'hangup',
                'desktop', // 'desktop' controls the "Share your screen" button
              ],
              hiddenPremeetingButtons: [
                'select-background',
                'settings',
                'invite',
              ],
              customToolbarButtons: [
                {
                  icon: pharmacyIcon,
                  id: 'custom-pharmacy-button',
                  text: 'View pharmacy',
                },
                {
                  icon: uploadIcon,
                  id: 'upload-docs-button',
                  text: 'Upload',
                },
              ],
              disabledNotifications: ['lobby.notificationTitle'],
              buttonsWithNotifyClick: ['hangup'],
              remoteVideoMenu: {
                disablePrivateChat: true,
              },
              recordings: {
                //If true, shows a notification at the start of the meeting with a call to action button
                //to start recording (for users who can do so, like moderators).
                suggestRecording: false,
                //If true, shows a warning label in the prejoin screen to point out the possibility that
                //the call you're joining might be recorded.
                showPrejoinWarning: false,
              },
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: 'nocrop',
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4,
              SHARING_FEATURES: ['dial-in', 'url'],
            }}
            onApiReady={(api) => {
              this.setState({ jaasApi: api });
              this.updatePatientStatus(room, 'pre-meeting');
              api.addListener('videoConferenceJoined', (obj) => {
                api.getRoomsInfo().then((response) => {
                  this.getDoctorId(response.rooms);
                });
                this.updatePatientStatus(room, 'connected');
              });
              api.addListener('notificationTriggered', (obj) => {
                if (obj.title === 'lobby.joinRejectedTitle') {
                  api.executeCommand('hangup');
                }
              });
              api.addListener('toolbarButtonClicked', (obj) => {
                if (obj.key === 'hangup') {
                  this.setState({ openConfirmationDialog: true });
                }
                if (obj.key === 'custom-pharmacy-button') {
                  this.setState({ openPharmacyDialog: true });
                }
                if (obj.key === 'upload-docs-button') {
                  this.setState({ openUploadDialog: true });
                }
              });
            }}
            onReadyToClose={() => {
              this.props.handleCallDisconnected();
            }}
            getIFrameRef={(iframeRef) => {
              this.setState({ iframeRef });
              iframeRef.style.height = '100%';
              iframeRef.style.width = '100%';
              iframeRef.style.position = 'absolute';
            }}
          />
        </Grid>
        <Dialog
          open={openPharmacyDialog}
          onClose={this.closePharmacyDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>
            <Typography variant="h5" color="primary">
              Your Pharmacy
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                {auth.patient.pharmacy.premiumPharmacy
                  ? auth.patient.pharmacy.premiumPharmacy.name
                  : auth.patient.pharmacy.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {auth.patient.pharmacy.premiumPharmacy
                  ? auth.patient.pharmacy.premiumPharmacy.address.street
                  : auth.patient.pharmacy.address.street}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {auth.patient.pharmacy.premiumPharmacy
                  ? `${auth.patient.pharmacy.premiumPharmacy.address.city},`
                  : auth.patient.pharmacy.address.city}
                {auth.patient.pharmacy.premiumPharmacy
                  ? auth.patient.pharmacy.premiumPharmacy.address.province
                  : auth.patient.pharmacy.address.province}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {auth.patient.pharmacy.premiumPharmacy
                  ? auth.patient.pharmacy.premiumPharmacy.address.postalCode
                  : auth.patient.pharmacy.address.postalCode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                Fax:{' '}
                {auth.patient.pharmacy.premiumPharmacy
                  ? auth.patient.pharmacy.premiumPharmacy.faxNumber
                  : auth.patient.pharmacy.faxNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                Phone:{' '}
                {auth.patient.pharmacy.premiumPharmacy
                  ? auth.patient.pharmacy.premiumPharmacy.phoneNumber
                  : auth.patient.pharmacy.phoneNumber}
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={this.closePharmacyDialog}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmationDialog}
          onClose={() => this.closeConfirmationDialog(false)}
        >
          <DialogTitle>
            <Typography variant="h5" color="primary">
              Are you sure?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Typography color="primary">
                This will end your virtual medical consultation and you will be
                redirected to your dashboard.
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.closeConfirmationDialog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.closeConfirmationDialog(true)}
            >
              End call
            </Button>
          </DialogActions>
        </Dialog>
        <FileUpload
          open={openUploadDialog}
          onClose={() => this.setState({ openUploadDialog: false })}
        />
      </Grid>
    );
  }
}

PatientVideoChat.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    patientId: state.appointmentParams.patientId,
  };
}

PatientVideoChat = connect(mapStateToProps, actions)(PatientVideoChat);

PatientVideoChat = reduxForm({
  //validate,
  form: 'noteForm',
  //destroyOnUnmount: false
})(PatientVideoChat);

export default withStyles(styles2)(PatientVideoChat);
