import React, { Component, Fragment, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, FieldArray } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  InputAdornment,
  CircularProgress,
  Collapse,
  InputLabel,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import symptomsFormFields1 from './symptomsFormFields1';
import symptomsFormFields2 from './symptomsFormFields2';
import DropzoneInput from '../commonform/FileInput';
import * as actions from '../../actions';
import '../../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../../node_modules/dropzone/dist/min/dropzone.min.css';
import '../../styles/patientSettings.css';
import { withRouter } from 'react-router-dom';
import { createTextMask } from 'redux-form-input-masks';
import DatePicker from 'react-datepicker';
import cardPlaceholder from '../../images/ohip_card_placeholder.png';
import { RESET_APPT_PARAMS } from './../../actions/types';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/safariDatePicker.css';

const constants = require('../../constants');

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    float: 'right',
  },
});

const customOhipNumberMask = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const customOhipExpirationMask = {
  9: {
    regExp: /[0-9]/,
  },
};

const ohipNumberMask = createTextMask({
  pattern: '9999-999-999-AA',
  guide: true,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customOhipNumberMask,
});

const ohipExpirationMask = createTextMask({
  pattern: '9999-99-99',
  guide: true,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customOhipExpirationMask,
});

const ShortOhipSymptomsTextField = ({
  input,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    //required
    fullWidth
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    label="Your Symptoms"
  />
);

const OhipNumberField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    disabled
    variant="outlined"
    size="small"
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    label="OHIP Number"
  />
);

const SymptomsField = ({ name, input, label, meta: { touched, error } }) => (
  <FormControlLabel
    style={{ display: 'flex' }}
    control={
      <Checkbox
        {...input}
        id={name}
        checked={input.value ? label : false}
        onChange={input.onChange}
        //value="checkedB"
        color="primary"
      />
    }
    label={label}
  />
);

const RequireNote = ({ name, input, meta: { touched, error } }) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          id={name}
          checked={input.value ? true : false}
          //value="checkedB"
          color="primary"
        />
      }
    />
  </FormControl>
);

const ShortReasonTextField = ({
  input,
  name,
  type,
  characterCount,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    //required
    fullWidth
    autoComplete="off"
    type={type}
    id={name}
    label={`${characterCount} characters`}
    error={touched && error}
    helperText={touched && error}
    inputProps={{
      maxLength: 100,
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

const TemperatureTextField = ({ input, name, type }) => (
  <TextField
    {...input}
    //required
    //fullWidth
    type={type}
    id={name}
    variant="outlined"
    size="small"
    placeholder="Temp."
    InputProps={{
      style: {
        marginTop: '8px',
      },
      endAdornment: <InputAdornment position="end">&#176;C</InputAdornment>,
    }}
    inputProps={{
      style: {
        padding: '6px',
        fontSize: '0.85rem',
      },
    }}
  />
);

const HeartRateField = ({ input, name }) => (
  <TextField
    {...input}
    id={name}
    type="input"
    variant="outlined"
    size="small"
    label="Heart Rate"
  />
);

const BloodPressureField = ({ input, name }) => (
  <TextField
    {...input}
    id={name}
    type="input"
    variant="outlined"
    size="small"
    label="Blood Pressure"
  />
);

const OhipExpirationDate = ({ input, name, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    disabled
    variant="outlined"
    size="small"
    label="Expiration"
    error={touched && error}
    helperText={touched && error}
  />
);

const OhipCardError = () => {
  return (
    <Typography color="error">
      <b>Missing health card picture. Click card to upload.</b>
    </Typography>
  );
};

const isDateSupported = () => {
  let i = document.createElement('input');
  i.setAttribute('type', 'date');
  return i.type !== 'text';
};

const SafariDatePicker = ({ id, input, meta: { touched, error } }) => {
  const [startDate, setStartDate] = useState();
  const CustomInput = ({ value, onClick }) => {
    return (
      <TextField
        value={value}
        onClick={onClick}
        //{...input}
        fullWidth
        variant="outlined"
        size="small"
        label="Expiration"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="yyyy-mm-dd"
        error={touched && error}
        helperText={(touched && error) || 'YYYY-MM-DD'}
      />
    );
  };
  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={(date) => {
          setStartDate(date);
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
class SymptomsFormOhip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      error: false,
      reasonString: '',
      characterMax: 100,
      characterCount: 100,
      showProgressBar: false,
      progress: 0,
    };
  }

  // hasDatePicker() {
  //   var input = document.createElement('input');
  //   input.setAttribute('type', 'date');
  //   input.value = '2018-01-01';
  //   console.log(input.valueAsDate);
  //   return !!input.valueAsDate;
  // }

  componentDidMount() {
    if (!this.props.patientId) {
      this.props.history.push('/dashboard');
    }
  }

  renderShortReasonField() {
    return (
      <Field
        name="short_reason"
        component={ShortReasonTextField}
        characterCount={this.state.characterCount}
        onChange={(event) =>
          this.setState(
            {
              reasonString: event.target.value,
            },
            () =>
              this.setState({
                characterCount:
                  this.state.characterMax - this.state.reasonString.length,
              })
          )
        }
      />
    );
  }

  renderSymptomsList1() {
    return _.map(symptomsFormFields1, ({ label, name, type, id }) => {
      if (label === 'Fever') {
        return (
          <Grid container direction="row">
            <Grid item xs={3}>
              <Field
                key={id}
                name={name}
                label={label}
                type={type}
                parse={(value, name) => (value ? label : '')}
                component={SymptomsField}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="temperature"
                type="number"
                component={TemperatureTextField}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Field
          key={id}
          name={name}
          label={label}
          type={type}
          parse={(value, name) => (value ? label : '')}
          component={SymptomsField}
        />
      );
    });
  }

  renderSymptomsList2() {
    return _.map(symptomsFormFields2, ({ label, name, type, id }) => {
      return (
        <Field
          key={id}
          name={name}
          label={label}
          type={type}
          parse={(value, name) => (value ? label : '')}
          component={SymptomsField}
        />
      );
    });
  }

  renderExtraSymptomsFields(name) {
    switch (name) {
      case 'heart_rate':
        return <Field name={name} component={HeartRateField} />;
      case 'blood_pressure':
        return <Field name={name} component={BloodPressureField} />;
    }
  }

  renderOhipSymptoms = () => (
    <Field name="ohip_symptoms" component={ShortOhipSymptomsTextField} />
  );

  renderOhipNumber = () => (
    <Field name="ohipNumber" component={OhipNumberField} />
  );

  renderOhipExpirationDate = () => {
    return (
      <Field
        name="ohipExpiration"
        id="ohipExpiration"
        component={OhipExpirationDate}
      />
    );
  };

  renderOhipCardError = () => {
    return (
      <Field
        name="ohip_card_error"
        id="ohip_card_error"
        component={OhipCardError}
      />
    );
  };

  handleOhipPayment(values, history) {
    values.patientId = this.props.patientId;
    this.props.enqueueOhipPatient(values, history);
    this.setState({ loading: true, disableButton: true });
  }

  // handlePictureLoaded(value) {
  //   this.setState({ disableButton: value });
  // }

  handleButtonError() {
    if (this.props.syncErrors && this.props.syncErrors.ohipNumber) {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
    if (this.props.syncErrors && this.props.syncErrors.ohipExpiration) {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
    if (this.props.syncErrors && this.props.syncErrors.short_reason) {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
    if (this.props.syncErrors && this.props.syncErrors.ohip_card_error) {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
  }

  render() {
    const { handleSubmit, classes, history, selectedUserMedical, auth } =
      this.props;

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {!this.props.appointmentParams.appointmentId && (
            <Grid container direction="row" spacing={1}>
              <form
                className={classes.form}
                onSubmit={handleSubmit((values) => {
                  this.handleOhipPayment(values, history);
                })}
              >
                <Grid item xs={12}>
                  <Typography align="center" variant="h4" color="primary">
                    OHIP Appointment
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Typography align="center" variant="h6" color="primary">
                    Please Select Your Symptoms
                  </Typography>
                  <Typography color="primary">
                    Patient: {this.props.selectedUser.fullName}
                  </Typography>
                </Grid>
                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={12} sm={6}>
                    {this.renderSymptomsList1()}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.renderSymptomsList2()}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      style={{ display: 'flex' }}
                      control={<Checkbox color="primary" />}
                      label="Other"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    spacing={1}
                    justify="center"
                  >
                    <Grid item xs={6}>
                      {this.renderExtraSymptomsFields('heart_rate')}
                    </Grid>
                    <Grid item xs={6}>
                      {this.renderExtraSymptomsFields('blood_pressure')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={1} justify="center">
                    <Grid item xs={12} sm={6}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <Typography align="center" variant="h6" color="primary">
                          OHIP Card Information
                        </Typography>
                      </Grid>
                      {!this.props.selectedUserMedical.ohipCardImage && (
                        <Grid item xs={12} style={{ marginLeft: '20px' }}>
                          {this.renderOhipCardError()}
                        </Grid>
                      )}
                      {this.props.selectedUserMedical.ohipCardImage ? (
                        <img
                          src={`${this.props.selectedUserMedical.ohipCardImage}`}
                          width="100%"
                        />
                      ) : (
                        <Button
                          onClick={() =>
                            this.props.history.push(
                              '/dashboard/AboutMeMedical/editOhipCard'
                            )
                          }
                        >
                          <img src={cardPlaceholder} width="100%" />
                        </Button>
                      )}
                      <Grid container spacing={1} style={{ marginTop: '5px' }}>
                        <Grid item xs={6} style={{ marginBottom: '10px' }}>
                          {this.renderOhipNumber(
                            selectedUserMedical.ohipNumber
                          )}
                        </Grid>
                        <Grid item xs={6} style={{ marginBottom: '10px' }}>
                          {this.renderOhipExpirationDate(isDateSupported())}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <Typography align="center" variant="h6" color="primary">
                          Pharmacy Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          Default Pharmacy
                        </Typography>
                      </Grid>
                      {this.props.auth.patient.pharmacy.faxNumber ? (
                        <Grid item>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: 'aliceBlue',
                              marginBottom: '10px',
                            }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="h6" color="primary">
                                {this.props.auth.patient.pharmacy.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {
                                  this.props.auth.patient.pharmacy.address
                                    .street
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {`${this.props.auth.patient.pharmacy.address.city}, `}
                                {
                                  this.props.auth.patient.pharmacy.address
                                    .province
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {
                                  this.props.auth.patient.pharmacy.address
                                    .postalCode
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color="primary">Fax Number</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: 'aliceBlue',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography color="primary">
                              {this.props.auth.patient.pharmacy.faxNumber}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color="primary">
                              Phone Number
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: 'aliceBlue',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography color="primary">
                              {this.props.auth.patient.pharmacy.phoneNumber}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : this.props.auth.patient.pharmacy.premiumPharmacy ? (
                        <Grid item>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: 'aliceBlue',
                              marginBottom: '10px',
                            }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="h6" color="primary">
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.name
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.street
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {`${this.props.auth.patient.pharmacy.premiumPharmacy.address.city}, `}
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.province
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.postalCode
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color="primary">Fax Number</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: 'aliceBlue',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography color="primary">
                              {
                                this.props.auth.patient.pharmacy.premiumPharmacy
                                  .faxNumber
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color="primary">
                              Phone Number
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: 'aliceBlue',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography color="primary">
                              {
                                this.props.auth.patient.pharmacy.premiumPharmacy
                                  .phoneNumber
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          style={{
                            backgroundColor: 'aliceBlue',
                            marginBottom: '10px',
                            height: '100px',
                          }}
                          container
                          alignContent="center"
                          justify="center"
                        >
                          <Typography
                            variant="button"
                            align="center"
                            color="error"
                          >
                            No pharmacy information
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} container justify="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.props.history.push(
                              '/dashboard/PharmacyInformation/main'
                            )
                          }
                        >
                          {this.props.auth.patient.pharmacy.faxNumber ||
                          this.props.auth.patient.pharmacy.premiumPharmacy
                            ? 'Change Pharmacy'
                            : 'Add Pharmacy'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{ margin: '10px 0px' }}
                  >
                    <strong>Short reason for your visit:</strong>
                  </Typography>
                  <div>{this.renderShortReasonField()}</div>
                </Grid>
                {/* <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item xs={11} sm={5}>
                  <Typography variant="body1" color="primary">
                    <strong>Do you require a work or school sick note?</strong>
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={7}>
                  <Field
                    key="require_sick_note"
                    name="require_sick_note"
                    type="input"
                    label="testing"
                    component={RequireNote}
                  />
                </Grid>
              </Grid> */}
                <Grid
                  item
                  container
                  direction="row"
                  style={{ marginTop: '15px' }}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
                      onClick={() => {
                        this.props.dispatch({
                          type: RESET_APPT_PARAMS,
                          payload: {},
                        });
                        this.props.history.push('/dashboard');
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} container justify="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={this.state.disableButton}
                      onClick={() => {
                        this.handleButtonError();
                      }}
                    >
                      next
                      {this.state.loading && (
                        <CircularProgress
                          size={15}
                          color="white"
                          style={{
                            marginLeft: 5,
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={this.state.error}>
                      <Typography color="error" align="right">
                        Please review form.
                      </Typography>
                    </Collapse>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}

          {this.props.appointmentParams.appointmentId && (
            <Grid
              container
              direction="row"
              spacing={2}
              style={{ marginTop: '20px' }}
            >
              <Grid item xs={12}>
                <Typography variant="h5" color="primary" align="center">
                  You're now in queue for your appointment.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" align="center">
                  You may upload files that might be useful for appointment
                  purposes.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DropzoneInput
                  patientId={this.props.selectedUser._id}
                  appointmentId={this.props.appointmentParams.appointmentId}
                  closeComponent={() => history.push('/dashboard')}
                  disableFileFecth={true}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </main>
    );
  }
}

function validate(values) {
  const errors = [];
  const today = new Date();
  if (!values.short_reason) {
    errors.short_reason = 'Please enter a short reason';
  }
  // if (!props.selectedUserMedical.ohipCardImage) {
  //   errors.ohipNumber = 'Please enter a valid ohip code';
  // }
  if (values.ohip_card_error === true) {
    errors.ohip_card_error = 'Something';
  }
  if (
    values.ohipNumber &&
    values.ohipNumber.replace(/[^a-zA-Z0-9]/g, '').length !== 12
  ) {
    errors.ohipNumber = 'Please enter a valid ohip code';
  }
  if (!values.ohipNumber) {
    const ohipNumberError = () => {
      return (
        <Typography variant="caption" color="error">
          Please update your OHIP information in{' '}
          <Link to="/dashboard/AboutMeMedical/editOhipCard">
            Medical Information
          </Link>
        </Typography>
      );
    };
    errors.ohipNumber = ohipNumberError();
  }
  if (
    values.ohipExpiration &&
    values.ohipExpiration.replace(/\D+/g, '').length !== 8
  ) {
    const expirationError = () => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              Please enter a valid date.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">YYYY-MM-DD</Typography>
          </Grid>
        </Grid>
      );
    };
    errors.ohipExpiration = expirationError();
  }
  if (values.ohipExpiration) {
    const expirationDate = new Date(values.ohipExpiration.replace(/-/g, '/'));
    const day = today.getDate();
    const month =
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1;
    const year = today.getFullYear();
    const dateString = `${year}-${month}-${day}`;

    if (expirationDate < today && values.ohipExpiration !== dateString) {
      const ohipExpirationError = () => {
        return (
          <Typography variant="caption" color="error">
            Card expired. Update{' '}
            <Link to="/dashboard/AboutMeMedical/editOhipCard">here.</Link>
          </Typography>
        );
      };
      errors.ohipExpiration = ohipExpirationError();
    }
  } else {
    const ohipExpirationError = () => {
      return (
        <Typography variant="caption" color="error">
          Please update your OHIP information in{' '}
          <Link to="/dashboard/AboutMeMedical/editOhipCard">
            Medical Information
          </Link>
        </Typography>
      );
    };
    errors.ohipExpiration = ohipExpirationError();
  }
  return errors;
}

const selector = formValueSelector('symptomsForm');

function mapStateToProps(state) {
  return {
    wizard: state.wizard,
    //files: selector(state, 'files'),
    auth: state.auth,
    ohipAppointment: state.ohipAppointment,
    appointmentParams: state.appointmentParams,
    selectedUserMedical: state.selectedUserMedical,
    selectedUser: state.selectedUser,
    syncErrors: state.form.symptomsForm.syncErrors,
    timerValues: state.timerValues,
  };
}

SymptomsFormOhip = connect(
  mapStateToProps,
  actions
)(withRouter(SymptomsFormOhip));

SymptomsFormOhip = reduxForm({
  validate,
  form: 'symptomsForm',
  destroyOnUnmount: true,
})(SymptomsFormOhip);

export default withStyles(styles)(SymptomsFormOhip);
