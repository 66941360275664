import React, { Component, Fragment, useState } from 'react';
import { _, deburr } from 'lodash';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import {
  reduxForm,
  formValueSelector,
  clearFields,
  Field,
  FormSection,
  change,
  clearSubmitErrors,
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  IconButton,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  OutlinedInput,
  Slide,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
} from '@material-ui/core';
import { Add, Delete, Clear, ExpandMore } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../../../../../actions';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

const MedicationAutocompleteField = ({ name, input, label, options }) => {
  const { onChange, ...rest } = input;
  return (
    <FormControl
      size="small"
      style={{
        width: '100%',
        zIndex: 0,
      }}
    >
      <Autocomplete
        // freeSolo
        // autoSelect
        value={input.value || []}
        options={options}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.name || ''}
        getOptionSelected={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField
            {...params}
            id={input.name}
            size="small"
            label={label}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              style: {
                fontSize: '0.9rem',
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};

const SelectField = ({
  name,
  input,
  label,
  options,
  meta: { touched, error },
}) => (
  <FormControl
    variant="outlined"
    size="small"
    style={{
      width: '100%',
      zIndex: 0,
    }}
    //error={touched && error}
  >
    <InputLabel style={{ fontSize: '0.9rem' }}>{label}</InputLabel>
    <Select
      {...input}
      id={name}
      input={<OutlinedInput id={name} label={label} />}
    >
      {options.map((option) => (
        <MenuItem value={option}>{option}</MenuItem>
      ))}
    </Select>
    {/*<FormHelperText>{touched && error}</FormHelperText>*/}
  </FormControl>
);

const duration_unit_array = ['Day (s)', 'Week (s)', 'Month (s)', 'Year (s)'];

const frequency_unit_array = [
  'OD',
  'BID',
  'TID',
  'QID',
  'QD',
  'q4h',
  'q6h',
  'q8h',
  'qam',
  'qhs',
  'Once a week',
];

const refills_array = ['0', '1', '2', '3'];

const DurationUnit = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl
    variant="outlined"
    size="small"
    error={touched && error}
    style={{ width: '100%', zIndex: 0 }}
  >
    <InputLabel style={{ fontSize: '0.9rem' }}>{label}</InputLabel>
    <Select
      {...input}
      id={name}
      input={<OutlinedInput id={name} label={label} />}
    >
      {duration_unit_array.map((amount) => (
        <MenuItem value={amount}>{amount}</MenuItem>
      ))}
    </Select>
  </FormControl>
);
const FrequencyUnit = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl
    variant="outlined"
    size="small"
    error={touched && error}
    style={{ width: '100%', zIndex: 0 }}
  >
    <InputLabel style={{ fontSize: '0.9rem' }}>{label}</InputLabel>
    <Select
      {...input}
      id={name}
      input={<OutlinedInput id={name} label={label} />}
    >
      {frequency_unit_array.map((freq) => (
        <MenuItem value={freq}>{freq}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

const RefillsField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl
    variant="outlined"
    size="small"
    error={touched && error}
    style={{ width: '100%', zIndex: 0 }}
  >
    <InputLabel style={{ fontSize: '0.9rem' }}>{label}</InputLabel>
    <Select
      {...input}
      id={name}
      input={<OutlinedInput id={name} label={label} />}
    >
      {refills_array.map((freq) => (
        <MenuItem value={freq}>{freq}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

const GeneralTextField = ({
  input,
  name,
  label,
  type,
  meta: { touched, error },
}) => {
  if (label.includes('Medication')) {
    return (
      <TextField
        {...input}
        fullWidth
        disabled
        size="small"
        variant="outlined"
        type={type}
        label={label}
        id={name}
        InputLabelProps={{
          style: {
            fontSize: '0.9rem',
          },
        }}
        InputProps={{
          style: { color: 'black' },
        }}
      />
    );
  } else {
    return (
      <TextField
        fullWidth
        {...input}
        multiline
        variant="outlined"
        size="small"
        //required
        //error={name != touched && error}
        //helperText={touched && error}
        type={type}
        label={label}
        id={name}
        InputLabelProps={{
          style: {
            fontSize: '0.9rem',
          },
        }}
      />
    );
  }
};

const GeneralNumberField = ({ input, name, label, type }) => (
  <TextField
    {...input}
    fullWidth
    size="small"
    variant="outlined"
    type="number"
    label={label}
    id={name}
    InputLabelProps={{
      style: {
        fontSize: '0.9rem',
      },
    }}
  />
);
const GeneralCheckbox = ({ name, input, label, type }) => (
  <div>
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            id={name}
            checked={input.value ? true : false}
            color="primary"
          />
        }
        label={<Typography style={{ fontSize: '0.8rem' }}>{label}</Typography>}
        labelPlacement="end"
      />
    </FormControl>
  </div>
);

const DrugInteractionCheckbox = ({ name, input, label, type }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              id={name}
              checked={input.value ? true : false}
              onClick={dispatch(
                actions.drugInteractionCheckboxAction(input.value)
              )}
              color="primary"
            />
          }
        />
      </FormControl>
    </div>
  );
};

const ControlledSubstancesCheckbox = ({ name, input, label, type }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              id={name}
              checked={input.value ? true : false}
              onClick={dispatch(
                actions.controlledSubstanceCheckboxAction(input.value)
              )}
              color="primary"
            />
          }
        />
      </FormControl>
    </div>
  );
};

const PhotoIdValidationCheckbox = ({ name, input, label, type }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              id={name}
              checked={input.value ? true : false}
              onClick={dispatch(
                actions.photoIdValidationCheckboxAction(input.value)
              )}
              color="primary"
            />
          }
        />
      </FormControl>
    </div>
  );
};

const FaxPrescriptionToPharmacyCheckbox = ({ name, input, label, type }) => {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            id={name}
            checked={input.value ? true : false}
            color="primary"
          />
        }
      />
    </FormControl>
  );
};

let FinalCheck = ({ name, input, label, type, disableFinalizeCheckbox }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              name={name}
              disabled={disableFinalizeCheckbox}
              checked={input.value ? true : false}
              onClick={dispatch(
                actions.finalizePrescriptionAction(input.value)
              )}
              color="primary"
            />
          }
        />
      </FormControl>
    </div>
  );
};

const renderFields = (
  name,
  type,
  label,
  component,
  disableFinalizeCheckbox,
  options
) => {
  return (
    <Field
      name={name}
      type={type}
      label={label}
      disableFinalizeCheckbox={disableFinalizeCheckbox}
      options={options}
      component={
        component === 'dosage'
          ? SelectField
          : component === 'autocomplete'
            ? MedicationAutocompleteField
            : component === 'general-text'
              ? GeneralTextField
              : component === 'general-checkbox'
                ? GeneralCheckbox
                : component === 'drug-interaction-checkbox'
                  ? DrugInteractionCheckbox
                  : component === 'controlled-substance-checkbox'
                    ? ControlledSubstancesCheckbox
                    : component === 'photo-id-validation-checkbox'
                      ? PhotoIdValidationCheckbox
                      : component === 'fax-prescription-to-pharmacy-check'
                        ? FaxPrescriptionToPharmacyCheckbox
                        : component === 'final-check'
                          ? FinalCheck
                          : component === 'duration-unit'
                            ? DurationUnit
                            : component === 'frequency-unit'
                              ? FrequencyUnit
                              : component === 'general-text-number'
                                ? GeneralNumberField
                                : component === 'refills'
                                  ? RefillsField
                                  : null
      }
    />
  );
};

class PrescriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLuCodesDialog: false,
      openConsentDialog: false,
      consentForm: '',
      medicationLimit: 6,
      luCodes: [],
      selectedLuCodes: [],
      expandedPanel: '',
      medWithConsent: false,
    };
  }

  componentDidMount() {
    const { medicationSelection } = this.props;
    if (medicationSelection && medicationSelection.length > 0) {
      let hiddenFieldArray = medicationSelection;
      hiddenFieldArray.forEach((med) => {
        if (med.consentId) this.setState({ medWithConsent: true });
      });
    }
  }

  clearMedicationForm = () => {
    const { selectedLuCodes } = this.state;
    selectedLuCodes.forEach((code) =>
      this.props.change(`luCode-${code}`, false)
    );
    this.setState({ selectedLuCodes: [] });
    this.props.change('medication', null);
    this.props.change('dosage', null);
    this.props.change('dosageUnit', null);
    this.props.change('frequency', null);
    this.props.change('duration', null);
    this.props.change('durationUnit', null);
    this.props.change('rxRefills', null);
    this.props.change('discontinue', null);
    this.props.change('rxPrn', null);
    this.props.change('bottleLabel', null);
  };

  addMedication = (values) => {
    const { selectedMedication } = this.props;
    const { selectedLuCodes } = this.state;
    const consentId = selectedMedication.consentId || null;
    let hiddenFieldArray = this.props.medicationSelection || [];
    const newData = {
      medicationId: values.medication._id,
      name: values.medication.name,
      dosage: values.dosage,
      dosageUnit: values.dosageUnit,
      frequency: values.frequency,
      duration: values.duration,
      durationUnit: values.durationUnit,
      rxRefills: values.rxRefills || '0',
      discontinue: values.discontinue || false,
      rxPrn: values.rxPrn || false,
      bottleLabel: values.bottleLabel || '',
      consentId: consentId,
    };
    if (selectedLuCodes.length > 0) {
      newData.bottleLabel =
        newData.bottleLabel + ` [LU Code] ${selectedLuCodes.toString()}`;
    }
    hiddenFieldArray.push(newData);
    hiddenFieldArray.forEach((med) => {
      if (med.consentId) this.setState({ medWithConsent: true });
    });
    this.props.change('medications', hiddenFieldArray);
    this.clearMedicationForm();
  };

  removeMedication = (index) => {
    // reset the state so that the fax checkbox is not disabled; this covers the scenario for empty array
    this.setState({ medWithConsent: false });
    // update medication selection checking for consent form presence
    let hiddenFieldArray = this.props.medicationSelection;
    hiddenFieldArray.splice(index, 1);
    hiddenFieldArray.forEach((med) => {
      if (med.consentId) this.setState({ medWithConsent: true });
    });
    this.props.change('medications', hiddenFieldArray);
  };

  handleConsentForm = async () => {
    const { selectedMedication } = this.props;
    this.setState({ openConsentDialog: true });
    await axios
      .get(`/api/consent-form/${selectedMedication.consentId}`)
      .then((result) => this.setState({ consentForm: result.data }));
  };

  handleLuCodes = async () => {
    const { selectedMedication } = this.props;
    this.setState({ openLuCodesDialog: true });
    await axios
      .post('/api/lu-codes', { codes: selectedMedication.LUCodes })
      .then((result) => this.setState({ luCodes: result.data }));
  };

  handlePanel = (panel) => (event, isExpanded) => {
    this.setState({ expandedPanel: isExpanded ? panel : false });
  };

  renderLuCodeCheckbox = ({ input, label, meta: { error, touched } }) => {
    const handleCheckboxClick = (event) => {
      if (event.target.checked) {
        // If checkbox is checked, add the label to selectedCodes
        this.setState((prevState) => ({
          selectedLuCodes: [...prevState.selectedLuCodes, label],
        }));
      } else {
        // If checkbox is unchecked, remove the label from selectedCodes
        this.setState((prevState) => ({
          selectedLuCodes: prevState.selectedLuCodes.filter(
            (code) => code !== label
          ),
        }));
      }
      // Call the redux-form input onChange handler to update the store
      input.onChange(event);
    };

    return (
      <FormControl>
        <FormControlLabel
          aria-label="LUCodeLabel"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          checked={input.value ? true : false}
          onChange={handleCheckboxClick}
          label={<Typography variant="body2">{label}</Typography>}
          control={<Checkbox color="primary" />}
        />
        {touched && error && <FormHelperText error>*{error}</FormHelperText>}
      </FormControl>
    );
  };

  renderMedDetails() {
    const {
      medication,
      handleSubmit,
      selectedMedication,
      medicationSelection,
    } = this.props;
    const {
      openConsentDialog,
      consentForm,
      openLuCodesDialog,
      luCodes,
      expandedPanel,
      medicationLimit,
    } = this.state;
    return (
      <Fragment>
        {/* This form is meant to update the hidden 'medications' field */}
        <form id="rxForm" onSubmit={handleSubmit(this.addMedication)}>
          <Grid container spacing={1} alignItems="center" direction="row">
            <Grid item xs={4}>
              <Field
                name="medication"
                type="text"
                label="Medication Name"
                options={medication}
                component={MedicationAutocompleteField}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="dosage"
                type="input"
                label="Quantity/Dose"
                options={selectedMedication ? selectedMedication.dosages : []}
                component={SelectField}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="dosageUnit"
                type="input"
                label="Unit"
                options={selectedMedication ? selectedMedication.units : []}
                component={SelectField}
              />
            </Grid>
            {selectedMedication && selectedMedication.requiresConsent && (
              <Grid
                item
                xs={
                  selectedMedication &&
                  selectedMedication.LUCodes &&
                  selectedMedication.LUCodes.length > 0
                    ? 6
                    : 12
                }
              >
                <Button
                  size="small"
                  style={{ color: 'red' }}
                  onClick={this.handleConsentForm}
                >
                  Requires consent
                </Button>
              </Grid>
            )}

            {selectedMedication &&
              selectedMedication.LUCodes &&
              selectedMedication.LUCodes.length > 0 && (
                <Grid
                  item
                  container
                  xs={
                    selectedMedication && selectedMedication.requiresConsent
                      ? 6
                      : 12
                  }
                  justify="flex-end"
                >
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.handleLuCodes}
                  >
                    LU Codes
                  </Button>
                </Grid>
              )}
            <Grid item xs={4}>
              {/*name (id), type, label*/}
              {renderFields(
                'frequency',
                'input',
                'Frequency',
                'frequency-unit'
              )}
            </Grid>
            <Grid item xs={4}>
              {/*name (id), type, label*/}
              {renderFields(
                'duration',
                'text',
                'Duration',
                'general-text-number'
              )}
            </Grid>
            <Grid item xs={4}>
              {/*name (id), type, label*/}
              {renderFields('durationUnit', 'input', 'Unit', 'duration-unit')}
            </Grid>
            <Grid item xs={4}>
              {renderFields('rxRefills', 'text', 'Refills', 'refills')}
            </Grid>
            <Grid item xs={4}>
              {/*name (id), type, label*/}
              {renderFields(
                'discontinue',
                'input',
                'Auto Discontinue',
                'general-checkbox'
              )}
            </Grid>
            <Grid item xs={4}>
              {/*name (id), type, label*/}
              {renderFields('rxPrn', 'input', 'PRN', 'general-checkbox')}
            </Grid>
            <Grid item xs={12}>
              {renderFields(
                'bottleLabel',
                'text',
                'Bottle Label',
                'general-text'
              )}
            </Grid>
            <Grid item container xs={12} spacing={1} justify="flex-end">
              <Button
                color="error"
                startIcon={<Clear />}
                onClick={() => this.clearMedicationForm()}
              >
                Clear
              </Button>
              <Button
                disabled={
                  !selectedMedication ||
                  (selectedMedication &&
                    medicationSelection &&
                    Array.isArray(medicationSelection) &&
                    medicationSelection.length >= medicationLimit)
                }
                type="submit"
                variant="outlined"
                color="primary"
                startIcon={<Add />}
              >
                Add Medication
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          onClose={() => this.setState({ openLuCodesDialog: false })}
          aria-labelledby="lu-code-dialog"
          open={openLuCodesDialog}
          scroll="paper"
        >
          <DialogTitle id="lu-code-dialog-title">LU Codes</DialogTitle>
          <DialogContent>
            {luCodes.length > 0 ? (
              luCodes.map((code) => (
                <Accordion
                  expanded={expandedPanel === code._id}
                  onChange={this.handlePanel(code._id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-label="Expand"
                    aria-controls={`${code.luCode}-content`}
                    id={`code-${code.luCode}`}
                    key={`code-${code.lucode}`}
                    style={{ backgroundColor: '#f7f7f7' }}
                  >
                    <Field
                      name={`luCode-${code.luCode}`}
                      label={`${code.luCode}`}
                      component={this.renderLuCodeCheckbox}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{code.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <CircularProgress size={30} color="primary" />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openLuCodesDialog: false })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => this.setState({ openConsentDialog: false })}
          aria-labelledby="simple-dialog-title"
          open={openConsentDialog}
          scroll="paper"
        >
          <DialogTitle id="consent-dialog-title">Consent Review</DialogTitle>
          <DialogContent dividers>
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{ minWidth: '500px', backgroundColor: '#FFFFFF' }}
            >
              {consentForm && consentForm.htmlString ? (
                ReactHtmlParser(consentForm.htmlString)
              ) : (
                <CircularProgress size={30} color="primary" />
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openConsentDialog: false })}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  disableFinalizeCheckbox() {
    if (
      this.props.drugInteractionCheck &&
      this.props.controlledSubstanceCheck &&
      this.props.photoIdValidation &&
      this.props.medicationSelection &&
      this.props.medicationSelection.length > 0
    ) {
      return false;
    }
    return true;
  }

  renderFaxCheckbox() {
    const { patient } = this.props;
    const { medWithConsent } = this.state;
    if (patient.isFamilyMember) {
      return (
        <>
          <Grid item xs={11} style={{ textAlign: 'right' }}>
            {!medWithConsent &&
            (patient.parentAccountUserId.patient.pharmacy.faxNumber ||
              patient.parentAccountUserId.patient.pharmacy.premiumPharmacy) ? (
              <Typography variant="caption">
                The patient requested to have the prescription faxed to their
                preferred pharmacy.
              </Typography>
            ) : (
              <Typography variant="caption">
                {!medWithConsent
                  ? 'The patient did not provide a pharmacy fax number.'
                  : 'Info: Consent form needs to be signed before faxing Rx'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'center' }}>
            {!medWithConsent &&
            (patient.parentAccountUserId.patient.pharmacy.faxNumber ||
              (patient.parentAccountUserId.patient.pharmacy.premiumPharmacy &&
                patient.parentAccountUserId.patient.pharmacy.premiumPharmacy
                  .faxNumber)) ? (
              renderFields(
                'fax_prescription_to_pharmacy_check',
                'input',
                '',
                'fax-prescription-to-pharmacy-check'
              )
            ) : (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox disabled color="primary" />}
                />
              </FormControl>
            )}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={11} style={{ textAlign: 'right' }}>
            {!medWithConsent &&
            (patient.patient.pharmacy.faxNumber ||
              patient.patient.pharmacy.premiumPharmacy) ? (
              <Typography variant="caption">
                The patient requested to have the prescription faxed to their
                preferred pharmacy.
              </Typography>
            ) : (
              <Typography variant="caption">
                {!medWithConsent
                  ? 'The patient did not provide a pharmacy fax number.'
                  : 'Info: Consent form needs to be signed before faxing Rx'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'center' }}>
            {!medWithConsent &&
            (patient.patient.pharmacy.faxNumber ||
              (patient.patient.pharmacy.premiumPharmacy &&
                patient.patient.pharmacy.premiumPharmacy.faxNumber)) ? (
              renderFields(
                'fax_prescription_to_pharmacy_check',
                'input',
                '',
                'fax-prescription-to-pharmacy-check'
              )
            ) : (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox disabled color="primary" />}
                />
              </FormControl>
            )}
          </Grid>
        </>
      );
    }
  }

  render() {
    //does a child compoent of a form use the same form?

    const { form, classes, medicationSelection } = this.props;
    return (
      <Slide direction="right" in={true}>
        <main className={classes.layout} id="prescription main">
          <Grid
            container
            direction="row"
            spacing={1}
            justify="center"
            alignItems="center"
            alignContent="flex-start"
            style={{
              padding: '1rem',
              borderBottom: '1px solid grey',
              overflowY: 'auto',
            }}
          >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h5" color="primary">
                Prescription Form
              </Typography>
            </Grid>

            {/* This is a hidden field registered to the Redux Form noteForm; it keeps track of the submitted medications */}
            <Field name="medications" component="input" type="hidden" />

            {this.renderMedDetails()}
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={12}
              style={{
                marginTop: '10px',
                height: '20rem',
                overflowY: 'auto',
              }}
            >
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Typography color="primary">Medications:</Typography>
                {medicationSelection &&
                  medicationSelection.map((item, index) => (
                    <Fragment key={item.medicationId}>
                      <Grid
                        item
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        xs={12}
                        style={{
                          border: '1px solid grey',
                          borderRadius: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <Grid item>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => this.removeMedication(index)}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            {index + 1}.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            {item.dosage} {item.dosageUnit}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            <b>Freq.:</b> {item.frequency}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            <b>Duration:</b> {item.duration} {item.durationUnit}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            <b>Refills:</b> {item.rxRefills}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            <b>Discontinue:</b>{' '}
                            {item.discontinue ? 'Yes' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            <b>PRN:</b> {item.rxPrn ? 'Yes' : 'No'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="primary">
                            <b>Directions:</b> {item.bottleLabel}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Fragment>
                  ))}
              </Grid>
              <Grid item xs={11} style={{ textAlign: 'right' }}>
                <Typography variant="caption">
                  I confirm that the prescribing medication does not have any
                  drug to drug interactions with their current medications. I
                  have reviewed the patient's history of allergies.
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                {renderFields(
                  'drug_interaction_existing_med_check',
                  'input',
                  '',
                  'drug-interaction-checkbox'
                )}
              </Grid>
              <Grid item xs={11} style={{ textAlign: 'right' }}>
                <Typography variant="caption">
                  Prescription does not contain any narcotic substance.
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                {renderFields(
                  'no_controlled_substance_check',
                  'input',
                  '',
                  'controlled-substance-checkbox'
                )}
              </Grid>
              <Grid item xs={11} style={{ textAlign: 'right' }}>
                <Typography variant="caption">
                  I have verified a government issued photo ID: I confirm that
                  the patient on the prescription is the same person as on the
                  photo ID as well as the same on the video call.
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                {renderFields(
                  'photo_id_validation_check',
                  'input',
                  '',
                  'photo-id-validation-checkbox'
                )}
              </Grid>
              {this.renderFaxCheckbox()}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              padding: '1rem',
            }}
          >
            <Grid item xs={11}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: 'right',
                }}
              >
                Finalize Prescription
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {renderFields(
                'finalize_prescription',
                'input',
                '',
                'final-check',
                this.disableFinalizeCheckbox()
              )}
            </Grid>
          </Grid>
        </main>
      </Slide>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
  };
}

const validate = (values) => {
  var errors = {};

  if (values.refills !== undefined) {
    const refills = Number(values.refills);
    if (isNaN(refills) || refills < 0 || refills > 3) {
      errors.refills = 'Value must be a number between 0 & 3';
    }
  }

  if (values.duration !== undefined) {
    const duration = Number(values.duration);
    if (isNaN(duration) || duration <= 0) {
      errors.duration = 'Value must be a positive number';
    }
  }

  if (!values.duration) {
    errors.duration = 'Required';
  }

  if (!values.frequency) {
    errors.frequency = 'Required';
  }

  if (values.duration && !values.durationUnit) {
    errors.durationUnit = 'Required';
  }

  return errors;
};

const selector = formValueSelector('noteForm'); // <-- same as form name

PrescriptionForm = connect((state) => {
  const medicationSelection = selector(state, 'medications');
  const selectedMedication = selector(state, 'medication');

  return {
    mapStateToProps,
    medicationSelection,
    selectedMedication,
    drugInteractionCheck: state.drugInteractionCheck,
    controlledSubstanceCheck: state.controlledSubstanceCheck,
    photoIdValidation: state.photoIdValidation,
    patient: state.appointmentParams.patient,
    medication: state.medicalData.medicationArray,
  };
})(PrescriptionForm);

PrescriptionForm = reduxForm({
  form: 'noteForm',
  destroyOnUnmount: false,
  validate,
})(PrescriptionForm);

PrescriptionForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

PrescriptionForm = withStyles(styles, { withTheme: true })(PrescriptionForm);

export default PrescriptionForm;
