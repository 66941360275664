import React, { Component, Fragment } from 'react';
import SessionNotification from './SessionNotification';
import socketIOClient from 'socket.io-client';
import ParallelLoginNotification from './ParallelLoginNotification';
import { sessionSocket } from '../../api/session-ws';
const constants = require('../../constants');

export default class NotificationProvider extends Component {
  socket = sessionSocket;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <SessionNotification socket={this.socket} />
        <ParallelLoginNotification socket={this.socket} />
      </Fragment>
    );
  }
}
