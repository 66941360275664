import React, { useEffect, useState } from 'react';
import { useQueue } from '../../../contexts/QueueContext';
import { useDispatch, useSelector } from 'react-redux';
import { takePatient } from '../../../actions';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import ConfirmPatientRequest from '../../dialogs/ConfirmPatientRequest';

const RequestPatientButton = ({ className, activeSessions = 0 }) => {
  const { queue } = useQueue();
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const [enabled, setEnabled] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEnabled(
      !!(
        queue > 0 &&
        user.signatureConfirmed &&
        user.CPSO &&
        activeSessions === 0
      )
    );
  }, [user, queue]);

  function handleClick() {
    try {
      dispatch(takePatient(history));
      setEnabled(false);
      setOpenDialog(false);
      setLoading(true);
    } catch (e) {
      console.error(e);
      setEnabled(true);
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        disabled={!enabled}
        className={className}
        variant="contained"
        onClick={() => setOpenDialog(true)}
      >
        {loading ? 'Requesting' : 'Request Patient'}
        {loading && (
          <CircularProgress
            size={20}
            color="primary"
            style={{
              marginLeft: 7,
            }}
          />
        )}
      </Button>
      {openDialog && (
        <ConfirmPatientRequest
          open={openDialog}
          close={() => setOpenDialog(false)}
          requestPatient={() => handleClick()}
        />
      )}
    </>
  );
};

export default RequestPatientButton;
