import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  CircularProgress,
  CssBaseline,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Tooltip,
  Typography,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { InsertDriveFile } from '@material-ui/icons';
import DoctorNotesIcon from '@material-ui/icons/Assignment';
import Chat from '@material-ui/icons/Chat';
import Notes from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PharmacyIcon from '@material-ui/icons/LocalPharmacy';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import AppointmentFiles from '../../dialogs/AppointmentFiles';
import PdfRendering from '../pdfRendering/PdfRendering';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  layoutPdf: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  resize: {
    fontSize: 50,
  },
  textBottomSpacing: {
    marginBottom: 20,
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: (props) => ({
    backgroundColor:
      props.hasConsentForms && props.unsigned
        ? theme.palette.error[500]
        : props.hasConsentForms && !props.unsigned
          ? 'mediumseagreen'
          : 'slategray',
    color: 'white',
  }),
}))(Badge);

const iconColours = {
  doctorNotes: '#317178',
  textChat: '#3688c7',
  prescription: '#504cc4',
  schoolSickNote: 'primary',
  generalSickNote: 'primary',
  files: '#8243bd ',
};

class AppointmentHistory extends Component {
  constructor(props) {
    super(props);

    this.perPage = 8;

    this.state = {
      page: 0,
      descendingOrder: true,
      openPdf: false,
      pdfData: {},
      rxData: {},
      expandedPanel: false,
      openFilesDialog: false,
      selectedFiles: [],
    };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }

  async componentDidMount() {
    if (this.props.selectedUser && this.props.selectedUser._id) {
      this.props.getAppointmentHistory(this.props.selectedUser._id);
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedUser !== this.props.selectedUser) {
      this.props.getAppointmentHistory(this.props.selectedUser._id);
    }
  }

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
    });
  }
  lastPage() {
    this.setState({
      page: this.numPages,
    });
  }
  firstPage() {
    this.setState({
      page: 0,
    });
  }

  downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  downloadChatPDF = async (chatId) => {
    const res = await axios.get(`/api/chat-history/${chatId}`);
    if (res.data) {
      this.downloadPDF(res.data.documentSrc);
    }
  };

  handleOpenFilesDialog = (files) => {
    this.setState({
      openFilesDialog: true,
      selectedFiles: files,
    });
  };

  renderRxButton = (rxArray) => {
    const hasConsentForms = rxArray.some((rx) => rx.consentForms.length > 0);
    const hasUnsignedConsent = rxArray.some(
      (rx) => rx.consentForms.length > 0 && !rx.isConsentSigned
    );
    return (
      <Tooltip title="Prescriptions">
        <IconButton
          onClick={() =>
            this.setState({
              openPdf: true,
              rxData: rxArray,
            })
          }
        >
          <StyledBadge
            badgeContent={rxArray.length}
            invisible={false}
            hasConsentForms={hasConsentForms}
            unsigned={hasUnsignedConsent}
          >
            <PharmacyIcon style={{ color: iconColours.prescription }} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
    );
  };

  returnFields = () => {
    const { appointments, classes } = this.props;
    const { expandedPanel } = this.state;

    if (appointments.appointmentHistory.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No appointment records.
          </Typography>
        </Grid>
      );
    }
    const { page, descendingOrder } = this.state;
    var history = [];
    var stepper = page * this.perPage;
    var top = stepper + this.perPage;
    var order = descendingOrder
      ? appointments.appointmentHistory.sort(function (a, b) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateB - dateA;
        })
      : appointments.appointmentHistory.sort(function (a, b) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateA - dateB;
        });
    for (let i = stepper; i < top; i++) {
      const appt = appointments.appointmentHistory[i];
      let rxConsent = false;
      let consentSigned = true;
      if (appt && Array.isArray(appt.prescriptions)) {
        appt.prescriptions.forEach((rx) => {
          if (rx.consentForms.length > 0) {
            rxConsent = true;
            if (!rx.isConsentSigned) {
              consentSigned = false;
            }
          }
        });
      }
      if (appt === undefined) {
        break;
      }

      history.push(
        <Grow in={true} timeout={500}>
          <Accordion
            expanded={expandedPanel === appt._id}
            onChange={(event, isExpanded) =>
              this.setState({ expandedPanel: isExpanded ? appt._id : false })
            }
          >
            <AccordionSummary
              style={{ backgroundColor: '#ebebeb' }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Grid container direction="row" spacing={1}>
                <Grid item container xs={12} sm={6} alignItems="center">
                  {(appt.patientLeftWaitingRoom || !appt.patientConnected) &&
                  this.props.appointmentInQueueId !== appt._id ? (
                    <Typography variant="button" color="error">
                      MISSED CALL - DATE:{' '}
                      {new Date(appt.date).toLocaleDateString('en-CA')}
                    </Typography>
                  ) : (
                    <Typography variant="button" color="primary">
                      Appointment Date:{' '}
                      {new Date(appt.date).toLocaleDateString('en-CA')}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  direction="row"
                  spacing={1}
                  justify="flex-end"
                >
                  <Grid item>
                    {this.props.appointmentInQueueId === appt._id && (
                      <Typography variant="caption" color="error">
                        CURRENT APPOINTMENT
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <StyledBadge
                      badgeContent=""
                      variant="dot"
                      invisible={!rxConsent}
                      hasConsentForms={rxConsent}
                      unsigned={!consentSigned}
                    >
                      <Typography variant="button" color="primary">
                        ID: {appt._id.substring(19, 24)}
                      </Typography>
                    </StyledBadge>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
              <Grid container>
                <Grid item xs={12} style={{ marginLeft: '15px' }}>
                  {appt.ohipAppointment && (
                    <Typography variant="h6" color="primary">
                      OHIP Appointment
                    </Typography>
                  )}

                  {appt.doctor && (
                    <Typography variant="body2">
                      <strong> Doctor:</strong> Dr. {appt.doctor.name.first}{' '}
                      {appt.doctor.name.last}
                    </Typography>
                  )}

                  {appt.ohipAppointment && (
                    <Typography variant="body2">
                      <strong>OHIP Number:</strong> {appt.ohipNumber}
                    </Typography>
                  )}

                  <Typography variant="body2">
                    <strong>Reason for Visit:</strong> {appt.shortReason}
                  </Typography>
                  {appt.symptoms && appt.symptoms.length > 0 && (
                    <Typography variant="body2">
                      <strong> Symptoms:</strong> {appt.symptoms.join(', ')}
                    </Typography>
                  )}
                  {appt.temperature && (
                    <Typography variant="body2">
                      <strong> Temperature:</strong> {appt.temperature}&#176;C
                    </Typography>
                  )}
                  {appt.heartRate && (
                    <Typography variant="body2">
                      <strong> Heart Rate:</strong> {appt.heartRate}
                    </Typography>
                  )}
                  {appt.bloodPressure && (
                    <Typography variant="body2">
                      <strong> Blood Pressure:</strong> {appt.bloodPressure}
                    </Typography>
                  )}
                </Grid>
                {appt.doctor &&
                  appt.doctorNotesFinalized &&
                  appt.doctorNotesDocumentSrc && (
                    <Tooltip title="Doctor Notes">
                      <IconButton
                        onClick={() =>
                          this.downloadPDF(appt.doctorNotesDocumentSrc)
                        }
                      >
                        <DoctorNotesIcon
                          style={{ color: iconColours.doctorNotes }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                {appt.savedTextChat &&
                  typeof appt.savedTextChat === 'string' && (
                    <Tooltip title="Text Chat">
                      <IconButton>
                        <Chat
                          style={{ color: iconColours.textChat }}
                          onClick={() => {
                            this.downloadChatPDF(appt.savedTextChat);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                {appt.prescriptions &&
                  appt.prescriptions.length > 0 &&
                  this.renderRxButton(appt.prescriptions)}
                {appt.fileList && appt.fileList.length > 0 && (
                  <Tooltip title="Files">
                    <IconButton
                      onClick={() => this.handleOpenFilesDialog(appt.fileList)}
                    >
                      <InsertDriveFile style={{ color: iconColours.files }} />
                    </IconButton>
                  </Tooltip>
                )}
                {appt.schoolSicknote != undefined && (
                  // <PdfDialog
                  //   data={appt.schoolSicknote.schoolSickNoteValues}
                  //   type={'Sick Note'}
                  // />
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.schoolSicknote.schoolSickNoteValues,
                      })
                    }
                  >
                    <Notes color="primary" />
                  </IconButton>
                )}
                {appt.generalSicknote != undefined && (
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.generalSicknote.generalSickNoteValues,
                      })
                    }
                  >
                    <Notes color="primary" />
                  </IconButton>
                )}
                {appt.patientLeftWaitingRoom && appt.patientWasPicked ? (
                  <Typography variant="caption" color="error">
                    NOTE: Patient left appointment.
                  </Typography>
                ) : appt.patientLeftWaitingRoom && !appt.patientWasPicked ? (
                  <Typography variant="caption" color="error">
                    NOTE: Patient left waiting room.
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grow>
      );
    }
    return history;
  };

  render() {
    const { classes, auth, appointments, selectedUser } = this.props;

    var numberAppts = appointments.appointmentHistory
      ? appointments.appointmentHistory.length
      : 0;
    this.numPages = Math.floor(numberAppts / this.perPage);
    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;

    return !this.state.openPdf ? (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 0,
                width: '100%',
              }}
              direction="row"
            >
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  variant="h5"
                  className={classes.spacing}
                  color="primary"
                >
                  Your Appointment History
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  className={classes.spacing}
                  color="primary"
                >
                  Patient:{' '}
                  <span style={{ fontSize: '22px' }}>
                    {selectedUser.fullName}
                  </span>
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12} className={classes.textBottomSpacing}>
                <Typography variant="body1">
                  You can see all information from your previous appointments
                  below, including your prescriptions and the doctor's notes.
                  Just click to expand the tab.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor={'order'}>Order by</InputLabel>
                      <Select
                        id="order"
                        input={<OutlinedInput label="Order by" />}
                        InputProps={{ classes: { input: classes.resize } }}
                        value={this.state.descendingOrder ? 'Latest' : 'Oldest'}
                        onChange={(event) => {
                          event.target.value == 'Oldest'
                            ? this.setState({
                                descendingOrder: false,
                              })
                            : this.setState({
                                descendingOrder: true,
                              });
                        }}
                      >
                        {['Oldest', 'Latest'].map((order) => (
                          <MenuItem value={order}>{order}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="caption" className={classes.center}>
                      {' '}
                      {this.bottomPerPage}{' '}
                      {this.state.page === this.numPages
                        ? '- ' + numberAppts
                        : '- ' + this.topPerPage}{' '}
                      of {numberAppts}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.firstPage}
                      disabled={this.state.page == 0 ? true : false}
                    >
                      <FirstPageIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.previousPage}
                      disabled={this.state.page == 0 ? true : false}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.nextPage}
                      disabled={this.state.page == this.numPages ? true : false}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.lastPage}
                      disabled={this.state.page == this.numPages ? true : false}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!appointments.appointmentHistory ? (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    style={{
                      height: '200px',
                    }}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                ) : (
                  this.returnFields()
                )}
              </Grid>
            </Grid>
          </main>
        </Slide>
        {this.state.openFilesDialog && (
          <AppointmentFiles
            open={this.state.openFilesDialog}
            onClose={() =>
              this.setState({ openFilesDialog: false, selectedFiles: [] })
            }
            files={this.state.selectedFiles}
          />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid item>
              <PdfRendering
                rxData={this.state.rxData}
                closeDocument={() => this.setState({ openPdf: false })}
                addPharmacy={this.props.pharmacyPage}
              />
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
    appointments: state.appointments,
    selectedUser: state.selectedUser,
  };
}

AppointmentHistory = connect(mapStateToProps, actions)(AppointmentHistory);

export default withStyles(styles)(AppointmentHistory);
