import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../actions';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Grid,
  Badge,
  Popover,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import {
  AccountCircle,
  Notifications,
  LocalPharmacy,
  Close,
  ExitToApp,
} from '@material-ui/icons';
import logo from './SnapMedLogo.png';
import logoRetina from './SnapMedHeaderRetina.png';
import logoIcon from './SnapmedIcon96x96.png';
import { Button } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

const styles = (theme) => ({
  root: {
    width: '100%',
    flex: '0 0 auto',
    marginBottom: 20,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: theme.palette.primary.main,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  showIcon: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  notificationContainer: {
    width: '100%',
    maxWidth: 380,
    minWidth: 280,
  },
  notificationItemIcon: {
    fontSize: '20px',
    color: '#959595',
  },
});

const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 50,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorNotification: false,
      anchorAccountMenu: false,
      notificationCount: 0,
    };
  }

  componentDidMount() {
    if (this.props.auth.role !== 'pharmacy') {
      this.props.fetchNotifications(this.props.auth.role);
    }
  }

  handleClose(origin) {
    switch (origin) {
      case 'account':
        this.setState({ anchorAccountMenu: null });
        break;
      case 'notifications':
        this.setState({ anchorNotification: null });
        break;
      default:
        break;
    }
  }

  handleDismissNotification = async (id) => {
    try {
      const res = await axios.delete(
        `/api/notifications/${this.props.auth.role}/${id}`
      );
      if (res.status === 200) {
        this.props.fetchNotifications(this.props.auth.role);
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderNotifications() {
    const { notifications, classes, auth } = this.props;
    let notificationArray = [];
    if (notifications.length > 0) {
      notificationArray = notifications.map((notification) => (
        <ListItem key={notification._id} button onClick={() => {}}>
          <ListItemIcon>
            <Notifications className={classes.notificationItemIcon} />
          </ListItemIcon>
          <ListItemText
            primary={notification.title}
            secondary={notification.message}
          />
          <ListItemSecondaryAction>
            <IconButton
              // size="small" // default "medium"
              edge="end"
              onClick={() => this.handleDismissNotification(notification._id)}
            >
              <Close color="primary" fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ));
    }
    this.props.auth.role === 'patient' &&
      !auth.patient.pharmacy.premiumPharmacy &&
      !auth.patient.pharmacy.faxNumber &&
      notificationArray.push(
        <ListItem key="pharmacy-notification" dense button onClick={() => {}}>
          <ListItemIcon>
            <LocalPharmacy className={classes.notificationItemIcon} />
          </ListItemIcon>
          <ListItemText primary="Please complete your pharmacy information." />
        </ListItem>
      );

    if (this.state.notificationCount !== notificationArray.length) {
      this.setState({ notificationCount: notificationArray.length });
    }

    if (notificationArray.length === 0) {
      notificationArray.push(
        <ListItem key="no-notifications" dense button onClick={() => {}}>
          <ListItemIcon>
            <Notifications className={classes.notificationItemIcon} />
          </ListItemIcon>
          <ListItemText primary="No notifications." />
        </ListItem>
      );
    }

    return notificationArray;
  }

  render() {
    const { classes } = this.props;
    const { anchorNotification, anchorAccountMenu, notificationCount } =
      this.state;

    return (
      <div className={classes.root}>
        <HideOnScroll {...this.props}>
          <AppBar
            className={classes.appBar}
            style={{
              backgroundColor: '#Efefef',
            }}
          >
            <Toolbar>
              <img
                className={classes.sectionDesktop}
                src={logo}
                srcSet={`
                  ${logoRetina} 2x,
               `}
                alt="SnapMed Logo"
              />
              <img
                className={classes.showIcon}
                src={logo}
                srcSet={`
                  ${logoIcon} 2x,
               `}
                alt="SnapMed Icon"
              />
              <div className={classes.grow} />
              {/* The following sections are individualized so that they can be developed independently with different features. */}
              {this.props.auth.role === 'doctor' && (
                <Fragment>
                  <Grid className={classes.sectionDesktop}>
                    <Grid item style={{ margin: 'auto 15px' }}>
                      <Typography variant="subtitle2" color="primary">
                        Hi, Dr. {this.props.auth.name.last}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Notifications">
                        <IconButton
                          // size="small" // default "medium"
                          onClick={(event) =>
                            this.setState({
                              anchorNotification: event.currentTarget,
                            })
                          }
                        >
                          <Badge
                            color="error"
                            // variant="dot"
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            max={9}
                            // overlap="circle"
                            invisible={notificationCount === 0}
                            badgeContent={notificationCount}
                          >
                            <Notifications color="primary" fontSize="small" />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Popover
                        open={Boolean(anchorNotification)}
                        onClose={() => this.handleClose('notifications')}
                        anchorEl={anchorNotification}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <List className={classes.notificationContainer}>
                          {this.renderNotifications()}
                        </List>
                      </Popover>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Account">
                      <IconButton
                        // size="small" // default "medium"
                        onClick={(event) =>
                          this.setState({
                            anchorAccountMenu: event.currentTarget,
                          })
                        }
                      >
                        <Badge
                          color="error"
                          // variant="dot"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          max={9}
                          // overlap="circle"
                          invisible={true}
                        >
                          <AccountCircle color="primary" fontSize="small" />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={Boolean(anchorAccountMenu)}
                      onClose={() => this.handleClose('account')}
                      anchorEl={anchorAccountMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <List>
                        <ListItem
                          key="logout-list-item"
                          dense
                          button
                          onClick={async () => {
                            const appointment = this.props.appointmentParams
                              ? this.props.appointmentParams.appointmentId
                                ? true
                                : false
                              : false;

                            await this.props.logout(
                              this.props.auth.role,
                              appointment,
                              this.props.history,
                              this.props.appointmentParams,
                              false
                            );
                          }}
                        >
                          <ListItemIcon>
                            <ExitToApp />
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </ListItem>
                      </List>
                    </Popover>
                  </Grid>
                </Fragment>
              )}
              {this.props.auth.role === 'patient' && (
                <Fragment>
                  <Grid
                    item
                    style={{ margin: 'auto 15px' }}
                    className={classes.sectionDesktop}
                  >
                    <Typography variant="subtitle2" color="primary">
                      Hi{' '}
                      {this.props.auth.name ? this.props.auth.name.first : ''}.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Notifications">
                      <IconButton
                        // size="small" // default "medium"
                        onClick={(event) =>
                          this.setState({
                            anchorNotification: event.currentTarget,
                          })
                        }
                      >
                        <Badge
                          color="error"
                          // variant="dot"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          max={9}
                          // overlap="circle"
                          invisible={notificationCount === 0}
                          badgeContent={notificationCount}
                        >
                          <Notifications color="primary" fontSize="small" />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={Boolean(anchorNotification)}
                      onClose={() => this.handleClose('notifications')}
                      anchorEl={anchorNotification}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <List className={classes.notificationContainer}>
                        {this.renderNotifications()}
                      </List>
                    </Popover>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Account">
                      <IconButton
                        // size="small" // default "medium"
                        onClick={(event) =>
                          this.setState({
                            anchorAccountMenu: event.currentTarget,
                          })
                        }
                      >
                        <Badge
                          color="error"
                          // variant="dot"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          max={9}
                          // overlap="circle"
                          invisible={true}
                        >
                          <AccountCircle color="primary" fontSize="small" />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={Boolean(anchorAccountMenu)}
                      onClose={() => this.handleClose('account')}
                      anchorEl={anchorAccountMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <List>
                        <ListItem
                          key="logout-list-item"
                          dense
                          button
                          onClick={async () => {
                            const appointment = this.props.appointmentParams
                              ? this.props.appointmentParams.appointmentId
                                ? true
                                : false
                              : false;

                            await this.props.logout(
                              this.props.auth.role,
                              appointment,
                              this.props.history,
                              this.props.appointmentParams,
                              false
                            );
                          }}
                        >
                          <ListItemIcon>
                            <ExitToApp />
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </ListItem>
                      </List>
                    </Popover>
                  </Grid>
                </Fragment>
              )}
              {this.props.auth.role === 'pharmacy' && (
                <Fragment>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 10, textTransform: 'none' }}
                    onClick={async () => {
                      const appointment = this.props.appointmentParams
                        ? this.props.appointmentParams.appointmentId
                          ? true
                          : false
                        : false;

                      await this.props.logout(
                        this.props.auth.role,
                        appointment,
                        this.props.history,
                        this.props.appointmentParams,
                        false
                      );
                    }}
                  >
                    Log out
                  </Button>
                </Fragment>
              )}
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
    notifications: state.notifications,
  };
}

const Header2 = connect(mapStateToProps, actions)(Header);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Header2));
