const torontoTimeZone = 'America/Toronto';

function convertTo24HourFormat(timeStr) {
  const [time, period] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}

function getTorontoTimeOffset(userTimeZone) {
  // Get the current date-time in Toronto and in the user's timezone
  const now = new Date();
  const torontoTime = now.toLocaleString('en-US', {
    timeZone: torontoTimeZone,
  });
  const userTime = now.toLocaleString('en-US', { timeZone: userTimeZone });

  // Convert these strings back to Date objects to calculate the time difference
  const torontoDate = new Date(torontoTime);
  const userDate = new Date(userTime);

  // Calculate the difference in hours
  const timeDifference = userDate - torontoDate;
  return timeDifference;
}

function convertTorontoTimeToUserTimeZone(torontoTimeStr) {
  const date = convertTo24HourFormat(torontoTimeStr);
  // detect user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get the time difference
  const offset = getTorontoTimeOffset(userTimeZone);

  // Return the time in the user's timezone
  const userDate = new Date(date.getTime() + offset);
  return userDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

export function getConvertedWorkingHours(workingHours) {
  const orderCriteria = ['Opening time', 'Closing time'];

  // Sort the array based on the order criteria
  const sortedArray = workingHours.sort((a, b) => {
    return orderCriteria.indexOf(a.name) - orderCriteria.indexOf(b.name);
  });

  return {
    start: convertTorontoTimeToUserTimeZone(sortedArray[0].time),
    end: convertTorontoTimeToUserTimeZone(sortedArray[1].time),
  };
}

export function isAppOpenForBusiness(workingHours) {
  const orderCriteria = ['Opening time', 'Closing time'];

  // Sort the array based on the order criteria
  const sortedArray = workingHours.sort((a, b) => {
    return orderCriteria.indexOf(a.name) - orderCriteria.indexOf(b.name);
  });
  // Convert start and end times to 24-hour format Date objects
  const startTime = convertTo24HourFormat(sortedArray[0].time);
  const endTime = convertTo24HourFormat(sortedArray[1].time);

  // Get the current time in Toronto
  const now = new Date();
  const torontoTime = new Date(
    now.toLocaleString('en-US', { timeZone: torontoTimeZone })
  );
  // Create date objects for start and end times in Toronto for today
  const startDate = new Date(torontoTime);
  startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
  const endDate = new Date(torontoTime);
  endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
  // Check if current Toronto time is within working hours
  return torontoTime >= startDate && torontoTime < endDate;
}
