import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';

import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Badge,
} from '@material-ui/core';
import { ArrowBackIos, ExpandMore } from '@material-ui/icons';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: (props) => ({
    backgroundColor: props.signed ? 'mediumseagreen' : theme.palette.error[500],
  }),
}))(Badge);
class DoctorPdfRendering extends Component {
  constructor(props) {
    super(props);
  }

  downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  renderAccordion = (rxData) => {
    return rxData.map((rx) => {
      const hasConsent = rx.consentForms && rx.consentForms.length > 0;
      const hasSignedConsent =
        rx.consentForms && rx.consentForms.length > 0 && rx.isConsentSigned;
      return (
        <Accordion key={rx._id} style={{ width: '100%' }}>
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <StyledBadge
              badgeContent=""
              variant="dot"
              invisible={!hasConsent}
              signed={hasSignedConsent}
            >
              <Typography variant="button">
                Rx: {rx._id.substring(19, 24)}
              </Typography>
            </StyledBadge>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                {rx.medications &&
                  rx.medications.length > 0 &&
                  rx.medications.map((m) => (
                    <>
                      <Typography variant="body2">
                        <strong> Medication:</strong> {m.name} {m.dosage}{' '}
                        {m.dosageUnit}
                      </Typography>
                      <Typography variant="body2">
                        <strong> Directions:</strong> {m.bottleLabel}
                      </Typography>
                    </>
                  ))}
              </Grid>

              {/*rx.destinationPharmacy &&
                (rx.prescriptionWasFaxed ||
                  rx.prescriptionWasFaxedDashboard) && (
                  <Grid item container xs={12}>
                    <Grid item>
                      <Typography variant="body2">
                        Prescription faxed to: {rx.destinationPharmacy.name}
                      </Typography>
                      <Typography variant="body2">
                        at: {rx.destinationPharmacy.faxNumber}
                      </Typography>
                      <Typography paragraph variant="body2">
                        on:{' '}
                        {new Date(rx.faxDate).toLocaleString('en-CA', {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                      )*/}
            </Grid>
          </AccordionDetails>
          <AccordionActions style={{ backgroundColor: '#fcfcfc' }}>
            {/*(this.props.auth.patient.pharmacy.faxNumber ||
              (this.props.auth.patient.pharmacy.premiumPharmacy &&
                this.props.auth.patient.pharmacy.premiumPharmacy
                  .faxNumber)) && (
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={
                    rx.prescriptionWasFaxedDashboard || hasUnsignedConsent
                  }
                  onClick={() => {
                    this.handleFax(rx);
                  }}
                >
                  {rx.prescriptionWasFaxedDashboard
                    ? 'prescription faxed'
                    : 'fax prescription'}
                </Button>
              </Grid>
            )*/}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.downloadPDF(rx.documentSrc)}
              >
                Download PDF
              </Button>
            </Grid>
          </AccordionActions>
        </Accordion>
      );
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <main className={classes.layout}>
          <Grid
            container
            style={{
              margin: 0,
              width: '100%',
              marginTop: '5%',
            }}
            direction="row"
          >
            <Grid item xs={9} sm={10}>
              <Typography
                variant="h6"
                className={classes.spacing}
                color="primary"
              >
                Rxs for appointment{' '}
                {this.props.rxData[0].appointment
                  .substring(19, 24)
                  .toUpperCase()}
              </Typography>
            </Grid>
            <Grid item container xs={3} sm={2} justify="flex-end">
              <Button
                startIcon={<ArrowBackIos />}
                variant="outlined"
                size="small"
                onClick={this.props.closeDocument}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              {this.renderAccordion(this.props.rxData)}
            </Grid>
          </Grid>
        </main>
      </Fragment>
    );
  }
}
export default withStyles(styles)(DoctorPdfRendering);
