import { FETCH_USER } from '../actions/types';
import { FETCH_MEDICAL_PROFILE_COMPLETION } from '../actions/types';

const initialState = {
  user: {},
};

export default function (state = false, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case 'logout':
      return action.payload || false;
    case 'firstCredit':
      return { ...state, ...action.payload } || false;
    case 'textNotification':
      return { ...state, ...action.payload } || false;
    case 'signature':
      return { ...state, ...action.payload } || false;
    default:
      return state;
  }
}
