const snapmedLogo = `data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAbAAAAB6CAYAAADeSgZMAAAABmJLR0QA/wD/AP+gvaeTAAAACXBI
WXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4woZCy0e7dVLVQAAIABJREFUeNrtvXl4HFeZ7/851S3H
dmxiu2WrZLccxyp1yTFhEsIybCGJSlkY4A7rAEmH7V4YuL+BYYch/IbLlmGZhIELw7AM4M7Cvg0h
i0rOQgIMELI4jlWtkuNYbatkq+Usjjep69w/qkpqy1J3S+qWWvb5PE8/3VJ3V586dep8z3vOe94X
FFPSkuoEQDetb+imZQLoxkVw/vmqchQKhUJRn+imJQCaTety3bSkblr3qlpRKBSK+iGmqmByDuZ3
sra9o0EifgucDrQsa9x44GB+5383pTp4Ov+oqiSFQqGYRzRVBVNbX74UHwVWF711zar1F2iD2W50
01IVpVAoFErA6ki8Uh14ji1103oG8CnAD9/ygaWLliy6TtWSQqFQKAGrK9aanXjZ7ujPr01RV+/R
TSvlObaqMIVCoVACVh8McziwwkzrWcCVJeromvBzsbXnv0JVnEKhUCgBm19WsDhyavm/JT42Crxa
Nztf6Dl2wT94iKZNl6jKUygUijlGqCogsrrwHBvdtC4GuoECpb00/+A59gtUzSkUCoWywOaVojWt
r4fP5bYY/LVuWq8HaDI71UBAoVAolIDNPU1mpxZaYW8FzGl89asAMeELPaXc6hUKhWIuOeU3Muup
i9FETLBmiVwkl90LNFT4VQksW9a48diA0/3bg/mdqjUpFArFHKKmvqQEIdBN69PA1TM4wlNA0nPs
J1cZlzPs3qJalUKhUMwBp/QUYnOqIxCvdmsd8PGZyB+wHPgiwGnxY2KtoaYSFQqFQglYjfE1LbBA
fT47Q2tUhCL2Dt20zhro6ZZ+XDUqhUKhmAtO2SnEIrf5zcDDVTjkzzzHfo1qUgqFQqEEbIy1KUv4
gpj29KLRvbnfVFvIuoBqzfs913PsP+vnXIC37e7qlC/ViRRSgC8Hna2qxSoUCkXIgphCHF5/DM+x
x8RLb7dYf87fzMr6Cp47XlhF8YJwD5m37W70MBnmzPgkzZsuBMDLdjHo2HLQ2aoi4CsUCkURC8KN
fvWithcvS2zMLGvcuO1gfufAwaGdLFl5ZuxgfqfU2y0ODk3PhT1yeV/W2Horx6dLmS3rljVu7DmY
37l9Nm71uhmPez1b/SazU1veuPGlyxo3XnB641m9g073iGqyCoVCsUAsMP1sSwz02PcAFwB/1k3r
Vt3sbPUcuwCA0NBTHRUfrzl1cWSFXQGcXYMi/wvAmtRFYvXmSysX6XPGrSvPsUd107pEIB8G7gC+
MOh0H1LNVaFQKBaQgDGKDF/9Iny+FKSrm9Z/6Ka10ttxOxJR8XmMNCyJXl5bk9LCBt20/nFf9g4Z
PzYq1oaCWdrissT+bXb0+lzdtG4HbgM2hR/5z/A91WIVCoVioQiY1CL94s6ifxeAdwCP6qb1kcGs
7ReJQUmhGNp+M7ppvQ9YEx6nmkRO9FfrprV0oLdb7s1uLVme0OKSze2dZ+im9Z/A/UBnWLZoyvCn
4edUi1UoFIqQBeNGr5vWecAfi0RCFpW/H3iP59i/KBaxUQ4z5Nx7/HFSnSsRsh84vcZF/qLn2B/W
Ux3Cy3aPqXCj2UlcAL4vvGy3bGztbIjH5UcJsj+foN/A055jL9dNS3iOLVWTVSgUigVigW3YcCGh
9XE/MDiJ+BaAFuDnumnZutlxlufYeI5NXC4VRQIYnKuQV8+BeBWAD+mpjpVetltGHom6adGAFF5P
F162W+qm9ap4XOZC8ZLhY+IA47bISlPNVaFQKBaYBVa06fj7wFUVfOU6jjV8xHv0lpEJx2kG9lI+
19dsiazDH3mO/XeTnM/zga8B51dwrHd4jv0tLnsW3PqQarEKhUKxUCyw0PqIdOHGCr/yPhaNDDUF
6VGK+ckcnXc0MHi9blpj0TmaUh3rdNP6AfCHULzKWVWHgbsAJV4KhUKxEC2wiGbTWi7hyWl+bTuB
A8hLgGfNU9H/EzgNeNM067zPc2xDNVOFQqFYoBZYxIBjPzVmkVSGBDYD/zsUr/laR3obcMUMBgy/
AmhS7vMKhUKxsAUs5J5ZWJgLxeKUYWF/BLC80KBaqkKhUCxUAVu7acwK6T4FrosAnvKDPWG4Kkmm
QqFQlLVQ6p+UgS42HAUWneTX5mbPsV+umqhCoVAscAsMwg3KWRfg7lPg2nQDJFovUq1UoVAoFrqA
FYVS+snJfmFkKGD5vjtUK1UoFIqFLmBFdJ3k12X/oGOrjV8KhUJx8gmY2As8chJfl98ArDUvVS1U
oVAoTiYB85yuI8C9J/F1uRFgr3ObaqEKhUJxsghYcxgYlzDILfO3OblWHCR0n1coFArF1IiFWOjE
2ZeLxSNHlha02EHAZ+Gu5U3GVs+xO1TTVCgUipPMAgNoKIzE9/Te8TTwl5NMvAC2gsq+rFAoFCel
gDGeSfnnJ9G1iLJK3woq+7JCoVCUY86nEJvMS8ZeDzq3z+pYummdD/z5JLoeT3qOfYbebjV4PfbI
bA60OhV4MO7PKkcQhUKhLLBZsXazhZ6yxKBzO4PO7Qj8GYvnCiNyL/f7gP6T6HrcDIAcs8ZmKuxi
f/Y29mdvQzctoZq5QqE4GYnP1Q8VRmAwa8swG/Fuz7EHdNMSnmNP24vwcTewKjxn6+O6ad0HtJwk
1+OHAL4YmyKdrnCBQHg9ttTbrc1ScsBz7L2qmSsUCmWBzZDVZ1sMZm100zqNYI3nd7ppne45tpyp
hbB205g7fRSdfqG70x8FtgHs65nx+lcgXqa1HslDIgy5tda0NJVTTKFQKAGbrlWQsoiPEgv//AWw
AtgAPDwmYu3TF7G9O7oi1YriIhYW+LW433PsnTP5YnPKoim0ZptMqx3oCa/tC3TT+tRex/YHHZvG
TSqyh0KhUAJWMVJIBrJ2QTet9wKXEXjb+aGI3advspaFVsO0RUw3rdigY3tAjjmcDq12FYXP9wTn
9NJpH8DXEIOBNWsIeBBYUnTcT+gp6wKAuF9Q62EKhUIJWDmSmy9FNy0x6HSjp6znAF8u+k0t7GBN
fB7WTWvZDKcTI2eHny7gaxCdw4+Dp9OmI+A0m5YYDAYAzwJ2MJ4nbbwuBbc0mdbysI6rfgJrX/U/
x1+rqUqFQjFHHGe1rGm7RGiaHwcKQuIPZG02bLiQXbvunPaBc9tvA5DNbdYiKbi9yNoQRR2sBM4E
tjeZ1jM9x36qyewUg05XRetZRQ4gvwHeu4AHEYc9x/6jblpxz7l9dDrfHXBsv9m0ni3hvknqOPp7
qYBfARcVpC+Y5XphONDQAOE59ujen3977L3RKlTIkGnAISmQQoiJYjxN61aOj5b8RM5Vd/xJgJFK
42YzGKm0kOXbhuzLZmq+Pt6aSotK2mkM/Gw2c+L329L09WZoTaXndW+uQPhudsuMz6+y3wABUiIo
+L581L1+5sdqSllJKVkqBKPxuL9nzyNbj072wZZnX0H/X26orIM7+yLk4SUMPvobdNP6IfD6Ug0s
PKfeUXj2kGMfbG6/WAz0XCDhkxV2qJ3rQD4ErFqg9+RPPMd+nW5amufYfmXnHKx56aZ1LkFEElFB
Hb/fc+zr1qQuFvuyWyu+qZtSgRPOFOU4C3gBcAHwdW8GaWAONJ/FyoFHa17J+aQhEjn3ZIudeaqK
mHCnIUyR6NVCTIFpHXuqstSqjNM/p6soFrHp1vVsaUul6a2wHoRuWp8CPlH0v57QorkDeOSoxq4D
O8Y7Vd20YoCUUviD2a7JOjSQQnjZLqmb1hsJI6uXGyWFHWxO+rHNg723PdlsXixiiwoyt+2uSq2C
3wIvXqD345WeY9/Q3N7JQE9XJeeqeY7t66b1AuB3U1heU3Gu59gPljx+yhIIhBBSDPR0jznH6Jus
5ficBZwHvIxgTfMZ0fvH0BoWU/D3Ot0VifBQs4mIFWKJnFsAyK9rjSPEmcA6YKOEDQLWhL9xGsH0
qDaFBTlS9FwIn4+Er0eB6xI5d5fq+k8K8dLcbMY3Uum3SdhUdO0nskiA62Yz3wAwN74RZ+dNtSrT
KyVYBMG4J6NBQF7Adb3ZzNESFti7CfwDRuehapcLxD+72S3Dk1hgbcA7wnLNVMwE4IvA4/oIMAzs
AfYDQ8BeN5s5BtBmvkFI2SDcbKZkXxInSA4ZCZgPtIeP9wPHTvNxdNN6AMmdCG72HHtw4kHWbLoI
bWQxnrsMoQ2LgR221E1rNfDt6ZwYkBRa4b6mjZeeP+Dc9mRTuyXgdXJseWjyzjwKu9QdCthCC+57
LLSgKhUvQvF6XpF4TWea7Yfh9R2z4pac8W4a199H/7b/BsDL2jJqpE2m9VwBncAF+BhA64TjRfX9
i2Hn9lHdtMrWfT5pkMi5NA44AIV80nge8PfAXwNnAYtnM284Bd9QXf9JQ9Q0/qcIrP9y4vIHN5t5
wOm9iTF/6Cqwsf0KdvbcwIbNb4gxws9F+X7nSQlfJ+jAjz8hESypCPj/CER5npBXT1HhbcAHa/jD
B4HdRirtADf7vvaDvt7vP13uS3Ekv0OwE9g4Sce/CDgHOAdBOuz09oUW2lbgPs+xH9m3Yzzt/cAO
ZFNbZwzkVmDpNE4gGlkboqHwSGNb5+bBnq4nmts7RHzkUtnfN3lIpChmoBDcJCX/HI64F5KA9XiO
vaNCKzOyvDoAe5qWVyQ2pm5aP/Mc+9XRGuLhJ75O/zbQz740TqHwrHAg8HLgYsrf8lFd31j0GyWJ
1qLySeNlwP8NRauWOJpPr+r3TzoOVvi5rwIvMc5Ox1wyVdlu09p+JVohLgAZH2n4bFH/VepefLIC
6+WpeazPv7jZzBNTvFdri3AZcHb4eJUQ/reNVPpG4EtuNnN/yrxSFED2Ocevl8W9wMV9ayhgpS5A
5P6+BnhL+Dimm1YW+BPBBuU9wfSSfF94vOlaQ5Elti6uyfua2jvPH+jpekI3OwXnny+5775Jv9Tc
3qEN9NiOblpPFk9p1TlRXd8DkDQ6ybldlVheFxSJ13QNlegme5VuWo8AX0KwE8kG4BIKheeH1634
mkcDglK/8wRhgtFSQYiL16DySeOnwKvDtwpUdWx8AntX7nVHUZyKFIAXG2b6pa6Tuata60x9PdcD
SCP15pXgv2sG92I9Ui9Z7iOteRPwJiOV/mrWybwntKaFr/lyZ88Nx42e/7UCldU4ca/VIuCZwFvD
qal7wtFOJIYzsYSiTrZVSLmjqb1jhed0yeaDq6ZsHFKKaFTzqwV2YwFcD3BssT+lcIWWF7ppXQ7c
NcvfjepxE/AdJHcA3wXeOEG8omsRq+DG/F0lIasSOVfuaWqL5ZPGA0XiRQ3FK2oXfwQYXmeo7vzU
Ixa2hC8DVXOSMMx0eE/4H1tAg+Zy3A9wZuvb5rscE7XmH4xUOttqXnmGm81IrTDeXWh6uyU8x+4B
XKBhFh1FtFjuV2E0EllizUKK+5tNa+WA0yV109I2bLjwxBYq41FHdccCaiwaUFi0SP6hKWWJfQ93
T/6huC9Cy+sSgqnbaovoaPg8k4XZ0fDqfxfgzOdMvQdsOGnEAZY0yJ8Af8XchP6K2uKfAVbtUW70
pzDnGqn0a6NR/CzFC9fJSKM9vQT4EAs/ClDENoDH+v6z3srlA21Cigdbz3qncHu3YKSuEkEn6o9Z
L9+apeDEQtWs1vpTZIltkPBIs2mt8hzbP3xaXBjG5cd9cE/21ujlvcDhBSRgN+/e1i2FmNzyam63
tL3bt0rdtF4F1CIvSnTNKrGyphThww3DP2lOXSIe+/Pk04f5pMGqnDuaTxp/I+FvwwY5F9MtMQAh
gmlaxSnP14LxltAi9/cZcWysW/1SFQbrdYSo19RUkRacKRoO3R5Y0lskQNwbd4X/GfApphMKYg5q
NCy4LuER3bTO8xx7QASebifMuXmO7eimtYt59eIZs0gradQ3B1dHnmiNCKEN9HT5umm9lnE3TFln
N4sGfOuJ7X+RS9o7xFTiVbSB+ONF35tqpKURuNjuBfqAxwgWto+UsdqKB1CLCDwZFwGnrep3vaEW
Q2vsd30UpyoSWGOk0u93s1uunc2B3EczGKn0BgK3cphbp7EngV1Ud9pdA/a52S0HZnmcvtCAEGXu
04aie3Ql49OFo0wdEjA6pmWk0m93s5nvUPxhz7Fd3bQeBJ5Xb8OCsPE1Afc3p6yzBxx7WG/vEIVY
Qe7ffmdgsaQ68LLdEHhHbqqwIbwn7Byr1QCPAB8ALqzgs6PAfwPsdcanD9e0WcQWxxnYdqs/yT66
ehzp/QzA6+meVFwi8RpKGhsp7/KsAd/G9z+b2LtzV7UK+CfDoNFV4nWKE907H99oXPWNne6WQxs3
XsXOnVumdZBoDxrB1OF8xF/9LzebubIWB25tS2vxuPCdHVum+9XICetiN5vZXemXNqbSyzVIAecT
OGy8tMKB+heA7xhmWosD6O0deD3dEMQrvLFOG58EmqRgh95+0Wavp3tINy2xLvUyuSf7m0i8AG4A
/ncFxzzsOfb3q11Q3bReUaGA7SreUKwbl+O5t6DFiA1su7Wgm1Ya2FKnllfEY55j31rqA5HnoYDn
VHC86xI59/1TWG8z5rlubda+Hlu/HoAzd+9GMTnDGwO/oFU7d9ZDcSSwStPk/w98NHaanFZYtfaz
0/Q8kvFbU1fqwLupvffsZCwOhTTmZjNVXXvr683MdJAX9U0JoOTNYBivx3V/BMDObOYpghB49wHf
NFLpTuDnwOllBv6rjFT6StfJXK+Fo+fo6v6C+s2rFTW2NcjYA7ppNXqOLQtiRDSaLxpvoULcR2WL
qppuWitqUM5FFX7u7sByDBwfPPeW4LnHLuim9aYi8apXy4uwsbGuzaqkcf9V2Qss+GeA3StfxIEq
idfsersL2b9h6i1qZ+7eXVK89jZvRG7efNKL1FAJ785VO3eWFK980pjrPgTgw0Z7emXvjoyczlpY
zyOZ8CDimqIZA8U0iMRrIm2pq4SbzXRV0E9EVu/bmWgCDzr2Yd20bgSuqHMRWwf06GbHJs/p2q+b
nWJde6fc09PFYE/XMd20biMIdVTPfB9AxPzIcot5jl3QTet/Ad+sc8srKt2XAfasG6XENuGo/OWy
Zveu6nefAlh/4F44MH+nNZRsRSBiIndngV3R/4wVYVirM8IR4qLw/ok8cI8CTwMH8LX9ib3ZJ9cO
7IQByCcNLVbQ/HdpPjdNwxOyGhZoPrlJwEiwZqwhE7urNyjIJw0tkXP9xvCchtYZy4SgKayj5WEd
NTC+r+cwcIggbNBgIuceSeRc9p21gdhIg5bI9fpz1o/4/Avwzum61RuptEmwBxZOGueN+adAQYbT
s31GKv2/KO9U+FdGKr12TMCaNlkM7rAhWNO4oo7PNRKxBIj7dbPjXM/pGmoyO4qnA35b5wImZbD5
m4EdWwPLKxCvt4XiFQlXPd8g93tZ+zEA7ryzklFvuagsT8z3CY2FuMr1QRDi6tnhvfAioJlAwBaX
OcxBND+fTxqPEew/+3Yi5zozEaVEziUfbD+4GGgMp6sWTRDP2ISB6GgopHkQu+GYk8j1HRexYjjZ
io9GY276wUnyLQYcgkTeJZFz/aGW1qSQ4irgEiAJ6GWmgKJrvT+fNHqB34hj4rur9vQ+DZBfZ4jE
njkJuPwOw7zyk65z/UAlm5uNVDruZjOjwNVlh3VK2KbNzuwNEDnmydj1iMIngPUlvrISaBtr+Fq4
hdlz7J/ppvU4QebkehexdSB6dLPzbM/p2teU6hCD2W5J4MhRz9w26NiHAZpMSxsM9nn9A/CVBTSy
m+6O0LqfbikKcfVa4EsEqX6my7LwcSZBhP4P5pPGduADiZx7WyLnsn9dK6v39FV6vLXMeAtF0Jfm
k8Z+gpintwC/WpVznxxcv2EG4tUGR0dJ5B8lFPdPI2c0UDwjfBjA5VLIr+aTxtfR5D8ldrtPVDFr
wFRiEvxfin8H/jZMzTKliIXvjbaaaQPJlZwYYSj6nf4KZhoUJUi1XkW293tHjFT6ZuBdZQYE541d
hIFem6b2zujPHy2Acy2yxOQDze2dTaF44Tn2Hwm8DOuVu6MXoXj9fSheCybVh0CEbeSdlX6lXCin
pnm0vETR65sJti2cyewib0cd2yiwGbg1nzS+A7B6Tx/Dla/9RErjT/NRYDwq/2oCL68M4OaTxuea
du864dxLMZxsI9HfS2Lfo+STxocJFt5fFh5/NnUUlfXd+GJ3Pmk8O5FzZb6lKmtjXaGoTDUr8D+M
VPp8KB2hI3pPSD49xWBMAA8TOJApZkG2b8xv7bai9jGl3h13IZYcGctK8JUF048GN0+zlLKn+Wyr
uBPsruNyj+341U3rQ8C/LyDLC+DOAadrz+qzLxHwH5V04lA+SGnLgXWtejTNNseWVxSf8c8cP/Uc
Z/YRZYqn994WxoBkVc4lX1loqwuKOs3pPGKM77kpZjXwsXzSeCy/ztiYyLkynzTE8PqNJQuxKpxu
zCeNfwE+X/RWwyzrKCorBCGZ7ssnjRcm+l3y64zZ3g+HwlF8Ka4NrSxhmm+b1PoKnzcDbyhxnKuB
vJKgavXs4ncT+o/JMI8TsCjzsgxS0z+wwERshSzwQJNp6eH/f1qn5X3Cc+w/heL1PoI9DQttj9J1
APsfub2SkXf0mbKxEn0hvhsKytj/9i3fUNMTySeNWPj8ZYL9KLW0gn3g1fmk8fbgHp264x9qaYte
vqgG5SgA6xFsH24xVidyrpS+VqKOUlFdvRb4yBy01yBkWkyT+WTrbI5zupvN3AxMlf9uBLjASKUv
cLMZWZDHRAlr7ZoSv7PbzWZ+SbBOqZglRiodc53M/goG9atPaLXPXv8iBoOswD9cSHoddjy6gB26
2dEopPx5nZb15lC8ro5Gfywsd9x9TGONUY4LwoMVfPyyfNLYnk8aVwwnA2tszVO7pvywp2+YlZt6
fu1ZJHJuIZ801gLvpfYL8NF1/vzBdUbDqhLrPI39Yw4Wf12DckQWz2IpA7GYyrFkeO1G4JjIt3wE
xrd21LK9SuCMfNL4amJ3lkSurxrn+d4i4WaC9QhBSh/c7PVyovXlZjPSSKXPA15R4nfmMhq9H5Q1
U/X4i7MKr1W7vqbUPrs1J+wk718aOYtp3wb/GhaOV82YJQbCkRrnI/lDjTqA2dyfmXDa8NMLdID0
I8+xK83DRON4J12JgEmCfEDXS4SfTxr7CCJk/ymcEXgYOLAIhpfnXF/3doE3Zkk1IPD9Ua2weiBb
UdkSex9lQgdXKpWQRuDd54bnsif8GwKnjRbgWWH5Y2Xum8RRwRuBLU8mz+QZucemsg5bmb0zVbnN
ts/JJ43XJXLuj4eShmicIKp+bBGN/T0yn/zpx4AlFRzvLwSplR4C8STIpwm8Jpcy7tjyN+GxJquj
6O+35pPGZxM515ulGOJmM3cZqfTtBJ6Sk3GOkUqn3Wwm07r+rfTt/i7h96L3P1fiN+51s5nfzOH9
d7qRSq8gcIIpVKnfFCCH3WzmYL10ktFEBIHn71QsO0HA9vd0oZtWg+fcPqSb1p1UFlWi3kRsFZL7
69OyEV/jxLQlC4Gos7kRoKm9k8EKMkiP9dg5tzefNO4Hzitz/YqtFR24PHyMza4dg7580nBDMbkf
5J8TOXfPcZ1/y0YKR5awZv/2Sor3wgospx8B/5jIuQMlrbqk0U4QNPbiMiL2UmDLSIkEEBr+QR/t
qnCqK3J2iJ7lhL8jYWkg2IOVDOutY8L1m4wPAz8WwXrdyPGWYE/08o0TrJrJuCKRc8tF8vnq/qTR
qAXThM8tUa7TgYuAm4bXGWLV7F3r/6mEgAF8EciIJSNam5n2fQl92QxtqfQzJVxWYlDzz3N8H14O
7K/yMeMgLOrPb+Bomfcb4lN0I5HH2L8sMAGbYImVvXHnQwQWonhF9brHc+zf62ZHg9fTNVLpF4fX
GVEqk38lyH82mxA8jeHj+cVFC621/wK2HNKeviexe6cfrm8VyghOAmgv85t3J3Lu340paItBY797
3EXNJ9tozPWSyLk9QEc+afSHIjIV55ess2SrWJnrG2T62xWK+dd80thEkKVhZYk2+Zz8WmMpsckz
OeSTxjNDy7KUdfqBSLyeSBqcMcWU5NB6UzTudoYOJFMv9vEPUjoTwuuBm6QIsh7MeGrs7DTuI5n7
jFT6plCIJ/YJBaDJSKU/6DqZL53VmubRvkxUOV8rMai5281mutvMdEOvkxmZw/uwFjEY6zHXUDkB
WzSpheL1BKnmfZ/fhVMlC7Gzpcy00HyXayHyH8FJiGl1Jqv2uOSTbbFEzr0h7ExjVM9ZQjKeKfzt
wF1L/dO355PGFYmcWwg74FL1fjrlF9+/AHAguSG0StwTLmq0KTjfYkT3VDlP3mQpb8tVub5Z1c9Q
S2sUh3IHcGXZNqlxXuKx462cJ84cK9+mEgMOCbD08cHrhta3acCU4gXQuNuR+aTBylz2GEG0hVKz
JBcWicXMGQ3OUQr5viLRLSY6tw8bqfTpkXi1ptIvYtwLdDI+BFAYHRlZ4Pf1TmC4DstV1lmoZMPY
12s/RRht/CREopim+oofAAwETj7TQiscKQTPfgfBHiJB9ebwJ7bjduD6fNK4Y2ht2+mJnCsPhJ50
k43iyjcUcT/Aytyu8o1q1I/q5oEyN+YZzDIQbH69QX5dG/mkwdC6Ng4kDYbbzglFtg/8oI0ncu5v
CBbES3HCpu2R0bEB17klvhcD7lxy8Ckp/MpuqSKHka1l6mjFUNJYHpswrTkjKyyVFn3O9YPhLEBs
iv5gNUWRNkQwAzUVv3CzmT8C7Oz7wUK/tXvcILDugmNKAWu6LAjQKmTg2szCc/UuxyLmPpL0QuZP
A05XL0AidcG0v1xoWEq+pVWsHNh5NJFzn0MQC7KW9e8DFwpNPvJkclNsZS47lSVWdnQvkEcr/dHG
gbHAtQfLDJ4q3mP26IYNPD7uVj8uBLtdEnt6g/BXe3pZmXNZ1btt/P29LkNnjgl3OXe+E8I/Fbn5
t5X5brjQKCtSsCLX+P4Sg5gw4y6pFVUI6ux1j+SUAAAWC0lEQVRmM1HZrmHypLdRFviPtqTScSOV
fi7wYk4Uz0JgzYmPAKTqz3NvJmQXasGnnEsdvNVGN62GAafrft20jlBfiS6rwenMTz6fhWipCsKk
mmvPvoC9j9w97YM07naQXCiHWhCN/X0ykXPfkk8aXwQ+QbB5eHkNBmcSWD/CyG2ANUV4IlFhHVR9
+qN8R28QbxrkjPt2Ff8vRRCdoxlYRbD5N854DMRhAt/M3Ymc+3DjY2N90/4Z9AVR3awr890w2oWo
qJ7GXePlfhCHSl17GZxnVTBSaeFmM3kjlf4kwWbsiWthgqCj+ypghv9rmMTi3NLnbMkaqXQsWwN3
9vkYnAK0tl5FX9+WBVXwKTvwppQFYuzGPRmTAUrUNOJ0BOxOAH90kZhpvQnuJO8bUdSLWCLnbgfe
kE8aqwk27L6GYN2juUrWWdQ5deSTxssTOffX+ZZWEv19dV/pY/EAc5BvaV2HFB8h8IZbR/nAyABH
Q8eWe4APMrtgyavKvH+oQmN24tU5jCwbYixRrTp1sxl5eirNafClo8GG7FVTtJe/L3Ooz4THK5wk
d/j9wIITr0kFTDctPMdmMGsDjOqm9YbwhvFRUZZPRaJe6Ue6edG5nmM/EbSTDjxn+l63iTD1RrCB
uJVEro9Ezt1PkIvuFwAHksZyP3AcOJsg4GsqfDYr7Lwn42rg10hR1jOxDsSrOLzVvyPLdqiTcRrB
3rQ3ho/ZxAYtF4H/CDPqHMRIBddiSTXrtlnC9t6Mb6TSH2U8bdF0BnLfdLOZ3g995Aq++Pl5CX34
WwLxXV4Fw0ICYvjgcI/Z/hbh9Hyv3gb0DWXeH40fL16dAvxYOB2BblofY3wTn0redmpbYRsgltNN
63LPse/xnG5004p7jj0604NOFmVB/lU74sGepwhSkfxxQsd+uoA1MnAquJBgr9Vmxtcvpmqjo8Dz
h5KtZyzhtCfqvbITOZehpBET8GfGHShmux3kGbP4bjlHl2MAo8capnvcYxUI2KJq1q3Qxqyxbxmp
9MeAsyr8qg/EfPz3AfzsZ/PWHe5xs5nfV/ugwwN1eSuUG7wc1SKrC8BzuqTndI/qpvVm3bQepfQO
dMWpQ9RxLgN+q5vWD5pNa12xeK0+++Lq/NCDYxtn2be+fWLH/vSqnPtoIuf+PJFz35vIuecA54SW
m1ZiRKoFJyFesjS3vW4reWh9W3GF/yQULznhGtQtvpjeknJMVjQVXVWl6HUyGKkroxp9z3SKC1yz
M3vDISN1pejrzcxXNTdAEC/wFOh3ykWhOaTppqV5jh1aYNZzddN6GPheMOKetzWi4qgDtXhQw2kk
WXT8Wj/m+vpEv/d3EnK6aX1HN60zAeKjmqabllhjXly1H1uzu2fK9/Y1b45EbXsi576KILKDVkaA
n1nPd+vS/KHI0rwM+NsSwiWn0X79uWsnC2NJ2c1eHzw7mV9TlNqoDAdAXBN+X62dz82AeS2lp0n3
a55j+2tMa6VuWj8Ip2w2z/OoTzKeYkGr0SMyT2sxD7C0aMRW64eY415DTLhObwN26ab1mYGs7XuO
LQVCbN78upoXZM3AdvLGcRuBv1RBufW6FrCnx2IGvLnM/SHC6/97gg3THwPeD7wP+ADwceDLBIkw
tXq13vx5dA1ra7sqqsyry6hv9P9r3eyWp1pTaeUHUOv7IFYobGy7anmZWRWA/XHdtN4JfKNERzUf
6vst4GYp5fIa/YYGjID2eA2OfS3wa8J1gRpymCASwEvmeZQE8HHdtK4EXuE59rbBOSpAwj1uf9BO
Ak+7M0p85Rn1fOMONLZx2hKpSTlp7L3ien8Q/IsSuZ0HKjlumIPs1TU/AW16YykRFzHpy3J9TU2i
XBxdPEoYAuq3Rip9K4GH5+gkfV8MyLvZzGeCP4SyvmrMQztuxEilX1CBFmXjwD/W4Tn8znPsX87F
DzWlrMjjclas3dTJaMEXYTboP85F2XXTes08CthEi+BMgjiP2+apHE9TPnZaXe/7ax7qZWidsVwI
VoQd91ReEa9J5HYeyCeNYK+bFNIXEmI+FGLEJEhNCmSQrDORc1+TTxpPEaxh1mpAyKL49MZs/igN
aGW3S9RkILh7242sX39FJI4fDAVsqvbxqbEeM7vwXM0XEmEKGxgPQq2VFDDPsTfppvWZcNqhuEOa
TxYB6MbleO4tNf2haogXwN4dXaWmIaorXKkOvGx3NDqsB/E6ClzkOfbvm0xLNMZXyu3bfzynBdGC
Galy9V/3+3aEJpOUNkr+ksi5fQCJnOuXuTbj+8kCj8YLa1TsJQCyME2HVE2eVkEbfrJWdb179w0Y
5ptxne9vL9fnvXTNR9izYm9xihVFDSiq35dXYIHdH9fNizTPsa/WTesWAm8ulVVUUalwCWC7BGvQ
sb3QIcgfnIcC+UFklXIu14frXsCktrSMc97T0zleUfSRoRoWOwEgY9p012RXUH6PWU2DzLrO92lt
f7PQClKbYnJQoFG4K/t5WTaapGJWtJlprdfJ+EYq/XKmzn5QPLBx4z6LfQDPse8FVuum9Uvglao6
FaX62fD5855jfxRAb+8AX8xnxJYNlE4ZIhhLf1nXaGVGnjOt41pan8F+BzntCYg1JQQsvGZyV60r
vK/n+5IFYJ2fnHySzZscjqHRuyPjbzTSixnP/F2Kh9xs5jFtnxNM0emmJUIh+x8cvy6mFi0VE9vC
MeBSz7E/qpuXaE1mh/B6uvGyXXNamAnBed9VQbnd+q/gscDBU913i2d46FouC7ww/IGKvHrz68dS
4qXKfdbjSO9wkNdNcRKRSr2J1rargE+yfcdN9O64ASOVXqdp/CUciMoyA7Hri0d7CM2X4xua7X8L
TbjHUOGjFMd3gl0+2hrPsW9fZVyMEL4/6HSXHeSUyck1IxI5Vw61tC3LJ42vEIRLKpSyWoTgrrqv
YMlgGQHbnG8xxuID5luM+S7yKLAhnzSek8i5hXxLqyjTDsDXos+ky1iivZtze5CqDypmBGobh7F1
5hH2K57ezmZvpK83MLSMVPr5Rir9BSBHEEKulB9GDDi6Fr65IZUe3zo/sCNIzdNkWmggBhx7B7BB
N63vEOz3gfrKbqyYG4pDNL3fc+zrAou9U3hOV8XWeSLnynxL6yak+GvGPQaPIcWxsOc+Vsp3QUgE
QXy/pQTrtG3AC5HyhYw7Akw1Uo8Dfav63T35pBFP5NzReqzoA+vaKAi5XwR1PpVH3DIkXwGuAEj0
u9O5jrUgKucP82uNcxP97lOBsLY1nDClKKSf6HcLgMwnjasIAjiPljjXX4WjJl/dhmM820ilPxta
4tWul2USrunLZnbP0Lr/uJFKe5S2xGMETj+rCbyWDU7MRlBOYz56dzYj21LpExvOYBCVQ0bBWj3H
frtuWncS5G9S4nXqoQEDIC71nK4xF3m/0uyFQF7fRMLbAVK8F3jnRGWa5GUt+LcJUxB1x8o9vQCj
+aTx+7BznwwJvCmfNM4hSDZ7P7BfBAF1Jw24LeAJv3xE+dkggY1o9OSTxv+RPjcn+nv3TGGBPZNg
uvfdEwRwMn4dXjElYOP1nAL+qYa/8H9mODMjgatqfO4CeNjNZr4M0JvNTN14iiONe46d0c0OG4RN
MLXoo4L7nuw3StQwv1f4qP/W/W/dyhrzIhGnQe51bmdfT+WR6BPejujlfM139fli6GuRJbgA6v+G
EgIWdRZRHMjjLlipi1mG2ey3isq0FvgPoUE+aRwDDhAk9mwg2IO2YkK/UWpG5+FEzr0TYNWAq+7I
yiyT2bJbaGXzxs1H2Yq361wIYLSlhdubkaVF6PzzCaaLOoTndA94jr2ZINmbNr17Q7EAbxQBXOU5
9lv3v3UrertFXGpyr3P7jA44nDSWM0na+jkQYYDXru5/3B9Otorh5Ma6rfTh5iBTcf+S5/07wXrA
VPeYmORcyz3Ksb8Kbaa4PA1AE9AKrCewACe62U/W6UUW8ucADqxtU3fj3LHDdeoyx5kARpGcGyYk
xe0NMmyXFrD77ousMVnkpfgekK8AnmI8jYXi5CC6lg8gRZPn2BmAdealeD02e7PdMz6wDFym58oC
K4Rt8yDwokTOfWA4aYgn4gW5Krezbit/1UAf+aQhzu29ESnkxROsm0oGHKUepYVe454aDIAq/X9x
WWLALxM596Z80ogdO21U3ZVzx0N12BcB3C2h2e3N9Bipq0Tx7VDxNKDn2DKwxjo1z+n+tZC0EqSi
VtbYwkcWtYcve459npft2hfkh4MV8ZmHEDzQPKZZyTk8nxjwK5AbEjn3d/kWQ6zKufKsXbvq/kIk
cq7cv34jjf19vQSBtYdqPD0jgF8kdrvD+aQRn2VHU42y/AGOvCqsi0LTo4+qu3PueASgtfXKeiiL
BuwBrnSzmZf2ZTNDrW1p4Wa3yCibAMwgNpzGqN9kdooBp2s/8DzdtD5HEA1bOXgsXPESoUX9Ks+x
u4OBiiW0QuBGNpuwUIXYWETpC+bgXHqALuBriZzrAORb2qDgz3ZwJar8nZL7mjQ/Rj7ZKhI59xFg
dT5pvJ/AE3hzDeqsSyDfEArG6Aw7moeAZ1WhLF9J5Nz3wnEhsJhpPTL3oda0KpRnPve8PQDQ13d9
te6BmeAC9wI/dLOZsTiCRirN6LHYCe1h2gK217kDQOqbLLQRtL2O/U96qvN2hLyFmW+yVMyfcAmg
24e/2+fY+SbTig869mhkcc9+SB05x8smEB7B9F4DQdinOMdPK4kpLMNiDgOPA4NAP7CDwBNvewxy
K3Lu0UC4WuOJ/r7RRH9vuSIeC62cxyf5vSitz0zWBQ4D+whC3kw8blQHU1hhvRC4mguQWiLnXptv
af0KvjgLwfOB8wi2EawmCKFV6X3nE0yrPh7W2a8TOfeusL6QUqMx1zuTy/xp4FGCCApnT/O7IwSb
Uj+XyLnu48lWsSLXJyt0ttkdPiYL4nxa+N5csg8YCOt4svI8VsJijc53F0ForpE5LLcAjhQWBRbY
FBwM6/MYs5ttK4T3xqGwHe4Oz/lhwPHB25nNPB2KVlxIUejt3SKFhF27vlddVdVNi7FkmClrGYIf
Ai+rQoW+03Psb85FMN+FSBTMVzetDDBbe//DnmN/MThuZ02iaVQ4mq4aj2nncaZ/fyXlIpFzKz2H
6Xy2que7r6WVNf19NauvfEsrhSOLWbN/+8TziCVybiGfNPYQeBhOxVsSOff74XfOAS4KLTKdIL1N
Q9ghHwk7LY8g/c0fEzn33uN/s5VErvy5Gqm0cLMZWa3PzZbp/E5RxPWK/j+XGGYa18nMSx0W05a6
gt7sDWU/N6v0EuPi1al52a6DwN/opvVB4IsTRvmK+mMQ6PQce5uesmJe1i7URrxag03MydY4iEkt
LRntAJNSHufBL8Y+H35hbKtzsWedL4SQq/p75YFkGytzvRWJVzhlFkUIKXcfjFQqXuFxZaXHjcpR
ijX9fQy0ttDc189QiyGEHEtSOdN7K6g/IQqJ/l58bdEJ4jXDgUpDIuduoyilzv51hhACbQX48UlE
fTjZFpNIEU1fViheuNmMNFLpeJk6kG42MzoXwhCWJ0rCW/KalxKvCs6pZhxewoj7YGaqc6ukPU+3
DYa3f5ASyM1uGWsflYgX1aqoDRsu5PDiBgZ7uiLLbDNwC9CiLLC6tMC+v3gk9rZdO2/zAZpNiwHH
VhWrmJUF5jU9C33wIYbWbqRxb2lvz/2tz6Sx72E1ulXMiqoo6q5ddwLQ1HYJWqwgBnrs7cB63bS+
C7xlBtaYABDxEa253ard2fv4A9nqdtzN7dZsRsbTK33lv1MgWMs5DLzdc+ybwoGG8BxbKvFSVJNy
4gWwuu9hVVGK+hCwiMHe2wFks9nJgNOF59hv1U1rK8Hi7nQ69cMAAz12zfeYFa/jzeo4bRbEEAM9
dqUbR6tBuYXeaNAQI/AUe5nn2HuaTEsIkNVy1FAoFIoFL2ARA04XzZssBnbYeI6daTKtOwX8ksB7
KrIGSvHG5nbrLMJMrzU5b8njSP51wLGrkuTQ641iSFqvE4KXEHjZ1JJjUvLcCsQL4FrPsT8A0GRa
Ii6k3NPTrVq/QqFQAjapiO0IrJom0xKDjt0PPFs3rX8D3jNJBzux471MSi6r8bkfRfA1qp+l97VS
8vo5vIZT1aMgcHl9mefY3clNl4ljoyNyUFldCoXiJKHmAXkbnn5a6u2dgZXi2O8FOgnSaUwVImeu
1nWfpDZTfYfm+BpOtXfqVhmTjdHG5BF/VO7rVVaXQqFQAlYxudzvx/pU3bRinmPbEs4CflvU+Sqr
oDqWWCRoH/Ec+/LBR7qfamrvEDCWJkehUCiUgE0Hr8eOLLCCHkwp7vcc+wLgE0WdrhKx2VtiOeAc
z7G/EA4YGOzpVvWqqLaVP933FYqFK2DHiVm4BtNkdmqeY38GxPlAXt0Es7K6AK73F8U3eI79sG5a
MWXWKqrIUwTT/k9N8oAwl5iQqsUp5pb4fP2wQPqBoHX9pXlTZ7P05U+BV6hLMi3xEuHw98oBx74h
sro8xy6AmjZUzI5Ezi0cbN7EstwOs6zCrWll2b5tqtIUp4aAeY5NcvOljI4WxMCOrhHglbpp/QPw
FXVZyhJlxH4IycsGsvYegNUbXonn/ErVjqJqHIofrehzh5cIlqvqUpwqAgaQ234bgNRTFjImNW+H
/VXdtAaAH6tLUxINuMNz7IsBmto60GIaAz1KvBTVZU1/ZQlA1zzmqspSzEtHOO94WZvBHd3+Gd8B
z7F/AnxdXZqypAH0dksM9nYz0NOlakShUCgBmy9in4+yqFd9c/HJyBIAr0dtTFYoFErA5p3h7FZ0
01rO7HNcnQq8H0A3rbiqCoVCoQRsHolcv4F3A03q0pTlXWtNa43n2KO6aanaUCgUSsDmg6ZUZ7DJ
OdV5OnCNuiyV4cO1QFWi6SsUCoUSsBkwGGUCFvJq1Ibm6XBFU5A8lCazU9WGQqFQAjaXNIfTX7rZ
sRr4sLok00PAtwAGnS70lJpKVCgUSsDmDH/M4hJXU2dOJQuEF+im1Qkwslg5JCoUCiVgc4JuWgw6
ttRNS2c8T9hJYBTNOdcCLD4oSG6+VLVqhUKhBKzWFDkffHIWh5GBIYdPkO15NHyMhP+bikSNxGZR
eaOTkfAxGpY5Kr+c4fk/UzetK/bstBkZGVWtWqFQnBLM2x6ipnaLwR6bJtNKAO+cpcUjSojFXmAI
2EcQ9X4AOAD0AQdrcGpfBLYCpxNsB1gNrAXWAHr4vKiKvxed+6eAGwazKmmlQqE4NZh3jz/dtK4H
rqjgo4cIUjocDB+HgP2AF4rUYPh6UKLtLcTk3qFHuo5W8PtVcUPXUxZoiEoiY6xpvTgmGrTVQrJO
QrOAdQSPpvB5BbAsfJwOLAeWVnC9Xuk59n+pZq1QKJSA1V68NgF3AcPAnlCQosdAaDE9TpB36Mnw
8YTn2IfKHbvF6BAjMXFcokwZPQl44sklHNlbu76+ua0DqWkIISemSRJCCDnQ01VW6PRN1mIkz0By
RihiZwDPCK26yJprDl+bwA89x/4H3bRElHdNoVAoTlb+H4CsCwfyfQbxAAAAAElFTkSuQmCC`;

export default snapmedLogo;
