import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../actions';
import {
  Typography,
  Grid,
  IconButton,
  Fab,
  Button,
  CircularProgress,
  Slide,
  Zoom,
} from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons';
import EditMedicalAllergies from './EditMedicalAllergies';
import EditOtherAllergies from './EditOtherAllergies';
import EditMedicalConditions from './EditMedicalConditions';
import EditMedicalProcedures from './EditMedicalProcedures';
import EditCurrentMedication from './EditCurrentMedication';
import EditSubstances from './EditSubstances';
import EditOhipCard from './EditOhipCard';
import cardPlaceholder from '../../images/ohip_card_placeholder.png';
import axios from 'axios';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px 0 0 5px',
  },
  editButtonField: {
    backgroundColor: 'aliceblue',
    borderRadius: '0 5px 5px 0',
  },
  spacing: {
    marginBottom: 15,
  },
  editFields: {
    border: '1px solid grey',
    borderStyle: 'dotted',
    borderRadius: '5px',
    backgroundColor: 'aliceblue',
    marginBottom: 15,
  },
});

class MedicalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableEdit: false,
      editMedicalAllergies: false,
      editOtherAllergies: false,
      editMedicalConditions: false,
      editMedicalProcedures: false,
      editCurrentMedication: false,
      editSubstanceBox: false,
      editOhipCard: false,
      ohipProvince: 'Ontario',
    };
  }

  async componentDidMount() {
    const { editPage } = this.props.match.params;
    if (editPage === 'editOhipCard') {
      this.setState({ editOhipCard: true });
      this.props.disableToolbar(true);
      this.props.history.push('/dashboard');
    }
  }

  render() {
    const { classes, selectedUser, selectedUserMedical } = this.props;
    const { enableEdit, ohipProvince } = this.state;

    if (this.state.editMedicalAllergies) {
      return (
        <EditMedicalAllergies
          closeEditPage={() => {
            this.setState({ editMedicalAllergies: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else if (this.state.editOtherAllergies) {
      return (
        <EditOtherAllergies
          closeEditPage={() => {
            this.setState({ editOtherAllergies: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else if (this.state.editMedicalConditions) {
      return (
        <EditMedicalConditions
          closeEditPage={() => {
            this.setState({ editMedicalConditions: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else if (this.state.editMedicalProcedures) {
      return (
        <EditMedicalProcedures
          closeEditPage={() => {
            this.setState({ editMedicalProcedures: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else if (this.state.editCurrentMedication) {
      return (
        <EditCurrentMedication
          closeEditPage={() => {
            this.setState({ editCurrentMedication: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else if (this.state.editSubstanceBox) {
      return (
        <EditSubstances
          closeEditPage={() => {
            this.setState({ editSubstanceBox: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else if (this.state.editOhipCard) {
      return (
        <EditOhipCard
          initialValues={{
            medicalProfile: {
              ohipNumber: this.props.selectedUserMedical.ohipNumber,
              ohipExpiration: this.props.selectedUserMedical.ohipExpiration,
            },
          }}
          closeEditPage={() => {
            this.setState({ editOhipCard: false });
            this.props.disableToolbar(false);
          }}
        />
      );
    } else {
      return (
        <Fragment>
          <Slide in={true} direction="right">
            <main className={classes.layout}>
              <Grid container direction="row">
                <Grid
                  item
                  container
                  style={{ margin: '50px 0', width: '100%' }}
                  alignItems="center"
                >
                  <Grid item xs={9} sm={10}>
                    <Typography variant="h5" color="primary">
                      Your Medical Information
                    </Typography>
                  </Grid>
                  <Grid item container xs={3} sm={2} justify="flex-end">
                    {enableEdit ? (
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: '#ff4a4a',
                          color: '#ffffff',
                          width: '70px',
                        }}
                        onClick={() => {
                          this.props.disableToolbar(false);
                          this.setState({ enableEdit: false });
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ width: '70px' }}
                        disabled={Object.keys(selectedUserMedical).length === 0}
                        onClick={() => {
                          this.props.disableToolbar(true);
                          this.setState({ enableEdit: true });
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {Object.keys(selectedUserMedical).length === 0 ? (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    style={{
                      height: '200px',
                    }}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="primary">
                        Patient:{' '}
                        <span style={{ fontSize: '22px' }}>
                          {selectedUser.fullName}
                        </span>
                      </Typography>
                    </Grid>
                    {this.props.auth.province === ohipProvince && (
                      <Grid
                        item
                        container
                        spacing={1}
                        className={
                          enableEdit ? classes.editFields : classes.spacing
                        }
                      >
                        <Grid item container xs={12} justify="flex-end">
                          <Zoom in={this.state.enableEdit}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  enableEdit: false,
                                  editOhipCard: true,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>

                        <Grid item xs={8} sm={6}>
                          <img
                            src={
                              this.props.selectedUserMedical.ohipCardImage
                                ? `${this.props.selectedUserMedical.ohipCardImage}`
                                : cardPlaceholder
                            }
                            width="100%"
                          />
                        </Grid>
                        <Grid item container xs={12} sm={6}>
                          <Grid item xs={12}>
                            <Typography variant="body1" color="primary">
                              OHIP Number
                            </Typography>
                            <Typography
                              variant="h6"
                              color="primary"
                              className={classes.textField}
                            >
                              {this.props.selectedUserMedical.ohipNumber
                                ? this.props.selectedUserMedical.ohipNumber
                                : 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" color="primary">
                              Card Expiration
                            </Typography>
                            <Typography
                              variant="h6"
                              color="primary"
                              className={classes.textField}
                            >
                              {this.props.selectedUserMedical.ohipExpiration
                                ? this.props.selectedUserMedical.ohipExpiration
                                : 'N/A'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container spacing={2}>
                      <Grid item container>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="primary">
                            Medical Allergies
                          </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.textField}>
                          <Typography variant="h6" color="primary">
                            {selectedUserMedical.medicalAllergies
                              .concat(
                                selectedUserMedical.medicalAllergiesOtherField !==
                                  ''
                                  ? selectedUserMedical.medicalAllergiesOtherField
                                  : []
                              )
                              .join(', ')}
                          </Typography>
                          {selectedUserMedical.medicalAllergies.length === 0 &&
                            selectedUserMedical.medicalAllergiesOtherField ===
                              '' && (
                              <Typography variant="h6" color="primary">
                                None
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          justify="flex-end"
                          className={classes.editButtonField}
                        >
                          <Zoom in={this.state.enableEdit}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  editMedicalAllergies: true,
                                  enableEdit: false,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="primary">
                            Other Allergies
                          </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.textField}>
                          <Typography variant="h6" color="primary">
                            {selectedUserMedical.otherAllergies
                              .concat(
                                selectedUserMedical.otherAllergiesOtherField !==
                                  ''
                                  ? selectedUserMedical.otherAllergiesOtherField
                                  : []
                              )
                              .join(', ')}
                          </Typography>
                          {selectedUserMedical.otherAllergies.length === 0 &&
                            selectedUserMedical.otherAllergiesOtherField ===
                              '' && (
                              <Typography variant="h6" color="primary">
                                None
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          justify="flex-end"
                          className={classes.editButtonField}
                        >
                          <Zoom
                            in={this.state.enableEdit}
                            style={{
                              transitionDelay: '50ms',
                            }}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  editOtherAllergies: true,
                                  enableEdit: false,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="primary">
                            Medical Conditions
                          </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.textField}>
                          <Typography variant="h6" color="primary">
                            {selectedUserMedical.currentMedicalCondition
                              .concat(
                                selectedUserMedical.currentMedicalConditionOtherField !==
                                  ''
                                  ? selectedUserMedical.currentMedicalConditionOtherField
                                  : []
                              )
                              .join(', ')}
                          </Typography>
                          {selectedUserMedical.currentMedicalCondition
                            .length === 0 &&
                            selectedUserMedical.currentMedicalConditionOtherField ===
                              '' && (
                              <Typography variant="h6" color="primary">
                                None
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          justify="flex-end"
                          className={classes.editButtonField}
                        >
                          <Zoom
                            in={this.state.enableEdit}
                            style={{
                              transitionDelay: '100ms',
                            }}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  editMedicalConditions: true,
                                  enableEdit: false,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="primary">
                            Past Medical Procedures
                          </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.textField}>
                          <Typography variant="h6" color="primary">
                            {selectedUserMedical.medicalProcedures
                              .concat(
                                selectedUserMedical.medicalProceduresOtherField !==
                                  ''
                                  ? selectedUserMedical.medicalProceduresOtherField
                                  : []
                              )
                              .join(', ')}
                          </Typography>
                          {selectedUserMedical.medicalProcedures.length === 0 &&
                            selectedUserMedical.medicalProceduresOtherField ===
                              '' && (
                              <Typography variant="h6" color="primary">
                                None
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          justify="flex-end"
                          className={classes.editButtonField}
                        >
                          <Zoom
                            in={this.state.enableEdit}
                            style={{
                              transitionDelay: '150ms',
                            }}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  editMedicalProcedures: true,
                                  enableEdit: false,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="primary">
                            Current Medication
                          </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.textField}>
                          <Typography variant="h6" color="primary">
                            {selectedUserMedical.currentMedication
                              .concat(
                                selectedUserMedical.currentMedicationOtherField !==
                                  ''
                                  ? selectedUserMedical.currentMedicationOtherField
                                  : []
                              )
                              .join(', ')}
                          </Typography>
                          {selectedUserMedical.currentMedication.length === 0 &&
                            selectedUserMedical.currentMedicationOtherField ===
                              '' && (
                              <Typography variant="h6" color="primary">
                                None
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          justify="flex-end"
                          className={classes.editButtonField}
                        >
                          <Zoom
                            in={this.state.enableEdit}
                            style={{
                              transitionDelay: '200ms',
                            }}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  editCurrentMedication: true,
                                  enableEdit: false,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        className={enableEdit ? classes.editFields : ''}
                      >
                        <Grid item container xs={11} spacing={1}>
                          <Grid item container xs={6}>
                            <Grid item xs={12}>
                              <Typography variant="body1" color="primary">
                                Tobacco smoker?
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.textField}>
                              {selectedUserMedical.isTobaccoSmoker ? (
                                <Typography variant="h6" color="primary">
                                  Yes
                                </Typography>
                              ) : (
                                <Typography variant="h6" color="primary">
                                  No
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item container xs={6}>
                            <Grid item xs={12}>
                              <Typography variant="body1" color="primary">
                                Alcohol consumer?
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.textField}>
                              {selectedUserMedical.isAlcoholConsumer ? (
                                <Typography variant="h6" color="primary">
                                  Yes
                                </Typography>
                              ) : (
                                <Typography variant="h6" color="primary">
                                  No
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item container xs={6}>
                            <Grid item xs={12}>
                              <Typography variant="body1" color="primary">
                                Substance user?
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.textField}>
                              {selectedUserMedical.isSubstanceUser ? (
                                <Typography variant="h6" color="primary">
                                  Yes
                                </Typography>
                              ) : (
                                <Typography variant="h6" color="primary">
                                  No
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          {selectedUserMedical.isSubstanceUser && (
                            <Grid item container xs={6}>
                              <Grid item xs={12}>
                                <Typography variant="body1" color="primary">
                                  Substances Used
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.textField}>
                                {this.props.selectedUserMedical.substance ? (
                                  <Typography variant="h6" color="primary">
                                    {this.props.selectedUserMedical.substance}
                                  </Typography>
                                ) : (
                                  <Typography variant="h6" color="primary">
                                    Not provided.
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          justify="flex-end"
                          alignContent="flex-start"
                        >
                          <Zoom
                            in={this.state.enableEdit}
                            style={{
                              transitionDelay: '250ms',
                            }}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({
                                  editSubstanceBox: true,
                                  enableEdit: false,
                                })
                              }
                            >
                              <Edit />
                            </IconButton>
                          </Zoom>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </main>
          </Slide>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
  };
}

MedicalInformation = connect(
  mapStateToProps,
  actions
)(withRouter(MedicalInformation));

export default withStyles(styles)(MedicalInformation);
