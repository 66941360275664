import React, { Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Button, Typography, Grid } from '@material-ui/core';

const FamilyMedicalFormCompletionPrompts = (props) => {
  var medicalPromptsArray = _.map(props.medicalProfileCompletion, (member) => {
    var url = member.id;

    if (!member.completed && member.isActive) {
      url = '/medicalprofileform/' + url;
      return (
        <Grid item>
          <Fragment key={member}>
            <Typography variant="body1">
              {member.name.first} needs to complete their Medical Profile in
              order to see a doctor:
            </Typography>
            <Button
              disabled={props.disabled}
              variant="contained"
              color="primary"
              style={{
                maxWidth: 220,
              }}
              onClick={(e) => {
                props.selectedUserChange({ id: member.id }).then(() => {
                  props.history.push(url);
                });
              }}
            >
              Complete Medical Profile - {member.name.first}
            </Button>
          </Fragment>
        </Grid>
      );
    }
  });

  return <Fragment>{medicalPromptsArray}</Fragment>;
};

export default connect(null, actions)(FamilyMedicalFormCompletionPrompts);
