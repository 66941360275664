import React, { Component, Fragment, useState } from 'react';
import { reduxForm, Field, FormSection } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import '../../styles/patientSettings.css';
import { Elements, CardElement } from 'react-stripe-elements';
import axios from 'axios';
import ReplaceCreditCardForm from '../payment/ReplaceCreditCardForm';
import AddCreditCardForm from '../payment/AddCreditCardForm';
import PatientPasswordEditForm from './PatientPasswordEditForm';
import {
  Grid,
  Typography,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Slide,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Divider,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Info, ExpandMore } from '@material-ui/icons';
import visaLogo from '../../images/visa_logo.png';
import mastercardLogo from '../../images/mc_logo.png';
import amexLogo from '../../images/amex_logo.png';
import discoverLogo from '../../images/discover_logo.png';
import noCardLogo from '../../images/cvc-card.png';
import { formValues } from 'redux-form';
import blue from '@material-ui/core/colors/blue';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { FETCH_USER } from '../../actions/types';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 15,
  },
  spacing: {
    marginBottom: 20,
  },
  disclaimer: {
    fontSize: 10,
  },
  card: {
    borderRadius: '5px',
    boxShadow: '0px 0px 4px -2px grey',
  },
  accordionHeading: {
    flexBasis: '50%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    color: theme.palette.text.secondary,
  },
  cardElement: {
    width: '100%',
  },
});
// in case an iOS style switch is preferred
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#192b4a',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#192b4a',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CustomSwitch = withStyles({
  switchBase: {
    color: blue[100],
    '&$checked': {
      color: '#192b4a',
    },
    '&$checked + $track': {
      backgroundColor: '#192b4a',
    },
  },
  checked: {},
  track: {},
})(Switch);

const passwordField = ({ name, placeholder, input }) => {
  return (
    <TextField
      {...input}
      size="small"
      variant="outlined"
      name={name}
      type="password"
      placeholder={placeholder}
    />
  );
};

class PatientSettings extends Component {
  constructor() {
    super();
    this.state = {
      oldPassword: false,
      password: false,
      confirmPassword: false,
      update: false,
      creditCardList: false,
      cardToBeRemoved: {},
      disableRemoveButton: false,
      error: false,
      planUpgradeErrorMEssage: '',
      passwordMismatch: false,
      creditPurchaseWarning: false,
      removeCreditCardWarning: false,
      replaceCreditCardWarning: false,
      addCreditCardWarning: false,
      expandedPanel: false,
      fiveCreditPackagePrice: '',
    };
  }

  async componentDidMount() {
    const { editPage } = this.props.match.params;
    // if 'editPage' variable is present in URL parameters and if it points to 'payments', open 'Payments' panel
    if (editPage === 'payments') {
      this.setState({ expandedPanel: 'panel4' });
      // scroll to bottom of open tab
      setTimeout(
        () =>
          document.querySelector('#agreement-info').scrollIntoView({
            behavior: 'smooth',
          }),
        300
      );
      // reset URL to dashboard path
      this.props.history.push('/dashboard');
    }
    if (this.props.auth.customerId) {
      await this.props.checkCreditCardList({
        customerId: this.props.auth.customerId,
      });
      this.setState({ creditCardList: this.props.creditCardList });
    }

    this.setState({ maxNumberOfFamilyMembers: this.props.memberLimit });

    const activeMembers = this.props.auth.familyMembers.filter(
      (member) => member.isActive
    ).length;

    this.setState((state, props) => ({
      numberOfAllowedAdditionalMembers:
        state.maxNumberOfFamilyMembers - activeMembers,
    }));

    await axios
      .get('/api/billing/stripe/FiveCreditPackageData')
      .then((fiveCreditPackagePrice) =>
        this.setState({
          fiveCreditPackagePrice: fiveCreditPackagePrice.data.price,
        })
      );
  }

  sendPassword = async (props) => {
    try {
      let res = await axios.post('/api/auth/update-password-via-old-password', {
        oldPassword: props.oldPassword,
        username: this.props.auth.email,
        password: props.password,
      });
      this.setState((state) => ({
        oldPassword: false,
        confirmPassword: false,
        password: false,
      }));
      this.props.change('oldPassword', '');
      this.props.change('password', '');
      this.props.change('confirmPassword', '');
    } catch (err) {
      if (err.response) {
        alert(
          `There was an error updating your password: ${err.response.data.message}`
        );
      }
    }
  };

  isResetFormComplete() {
    return (
      this.state.oldPassword &&
      this.state.password &&
      this.state.confirmPassword === this.state.password
    );
  }

  textingOptCheck = ({ input, name, meta: { touched, error } }) => (
    <FormControl>
      <FormControlLabel
        control={
          <CustomSwitch
            {...input}
            id={name}
            checked={input.value ? input.value : false}
            onChange={input.onChange}
            onClick={(e) => {
              this.props.textNotification({ textOptIn: e.target.checked });
            }}
          />
        }
      />
    </FormControl>
  );

  handleClose = (event, reason) => {
    this.setState({ passwordMismatch: false, error: false, updated: false });
  };

  purchaseCreditsDialog = () => {
    const { fiveCreditPackagePrice, creditPurchaseWarning } = this.state;
    return (
      <Dialog
        open={creditPurchaseWarning}
        onClose={() => {
          this.setState(() => ({ creditPurchaseWarning: false }));
        }}
        fullWidth
      >
        <DialogTitle>
          Are you sure you want to purchase the <b>5 Visits</b> package?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Our <b>5 Visits package</b> option will give you 5 appointments with
            a Canadian doctor for only <b>${fiveCreditPackagePrice} CAD</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              this.setState(() => ({ creditPurchaseWarning: false }));
            }}
          >
            Dismiss
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() =>
              this.props.history.push(
                '/PaymentPlanMembership/FiveCreditPackage/dashboard'
              )
            }
          >
            Buy now
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  removeCreditCardDialog = () => {
    const { cardToBeRemoved, disableRemoveButton } = this.state;
    const { auth } = this.props;
    return (
      <Dialog
        open={this.state.removeCreditCardWarning}
        onClose={() => {
          this.setState(() => ({ removeCreditCardWarning: false }));
        }}
        fullWidth
      >
        <DialogTitle>
          Are you sure you want to remove this credit card?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container xs={12}>
              <Grid item container alignItems="center" xs={2}>
                {cardToBeRemoved.cardBrand === 'discover' && (
                  <img src={discoverLogo} alt="Amex Logo" width="30px" />
                )}
                {cardToBeRemoved.cardBrand === 'amex' && (
                  <img src={amexLogo} alt="Amex Logo" width="30px" />
                )}
                {cardToBeRemoved.cardBrand === 'visa' && (
                  <img src={visaLogo} alt="Visa Logo" width="30px" />
                )}
                {cardToBeRemoved.cardBrand === 'mastercard' && (
                  <img
                    src={mastercardLogo}
                    alt="Mastercard Logo"
                    width="30px"
                  />
                )}
              </Grid>
              <Grid item container alignItems="center" xs={10}>
                <Typography>
                  Card ending in •••• {cardToBeRemoved.last4}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              this.setState(() => ({ removeCreditCardWarning: false }));
            }}
          >
            dismiss
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={disableRemoveButton}
            onClick={() => {
              this.setState({ disableRemoveButton: true });
              this.removeCreditCard(cardToBeRemoved.cardId, auth.customerId);
            }}
          >
            remove card
            {this.state.disableRemoveButton && (
              <CircularProgress
                size={15}
                color="white"
                style={{
                  marginLeft: 5,
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  replaceCreditCardDialog = () => {
    const { cardToBeRemoved } = this.state;
    return (
      <Dialog
        open={this.state.replaceCreditCardWarning}
        onClose={() => {
          this.setState(() => ({ replaceCreditCardWarning: false }));
        }}
        fullWidth
      >
        <DialogTitle>Replace credit card</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container xs={12} spacing={1}>
              <Grid item container alignItems="center" xs={2}>
                {cardToBeRemoved.cardBrand === 'discover' && (
                  <img src={discoverLogo} alt="Amex Logo" width="30px" />
                )}
                {cardToBeRemoved.cardBrand === 'amex' && (
                  <img src={amexLogo} alt="Amex Logo" width="30px" />
                )}
                {cardToBeRemoved.cardBrand === 'visa' && (
                  <img src={visaLogo} alt="Visa Logo" width="30px" />
                )}
                {cardToBeRemoved.cardBrand === 'mastercard' && (
                  <img
                    src={mastercardLogo}
                    alt="Mastercard Logo"
                    width="30px"
                  />
                )}
              </Grid>
              <Grid item container alignItems="center" xs={10}>
                <Typography>
                  Card ending in •••• {cardToBeRemoved.last4}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '15px' }}>
                <Typography>New card:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Elements>
                  <ReplaceCreditCardForm
                    oldCardId={cardToBeRemoved.cardId}
                    openModal={(value) =>
                      this.setState({ replaceCreditCardWarning: value })
                    }
                    getCardList={() =>
                      this.getCardList(this.props.auth.customerId)
                    }
                  />
                </Elements>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  addCreditCardDialog = () => {
    return (
      <Dialog
        open={this.state.addCreditCardWarning}
        onClose={() => {
          this.setState(() => ({ addCreditCardWarning: false }));
        }}
        fullWidth
      >
        <DialogTitle>Add a credit card</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12} style={{ marginTop: '15px' }}>
                <Typography>New card:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Elements>
                  <AddCreditCardForm
                    openModal={(value) =>
                      this.setState({ addCreditCardWarning: value })
                    }
                    getCardList={() =>
                      this.getCardList(this.props.auth.customerId)
                    }
                  />
                </Elements>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  renderAddMemberSection() {
    const { classes } = this.props;
    const { numberOfAllowedAdditionalMembers } = this.state;
    if (numberOfAllowedAdditionalMembers !== 0) {
      return (
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography color="primary">
              Would you like to add more family members?
            </Typography>
            <Typography color="primary" variant="caption">
              You can add {numberOfAllowedAdditionalMembers} more{' '}
              {numberOfAllowedAdditionalMembers > 1 ? 'members' : 'member'}.
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="flex-end"
            alignItems="flex-end"
            className={classes.alignButton}
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              style={{
                width: '115px',
              }}
              onClick={() => {
                this.props.history.push('/AdditionalFamilyMembers');
              }}
            >
              Add Member
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography color="primary">
              You have reached the limit of members for your account.
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }

  renderVisitsSection() {
    const { classes } = this.props;
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography color="primary" className={classes.spacing}>
            Would you like to purchase medical visits?
          </Typography>
          <Typography color="primary" variant="caption">
            You can purchase our <b>5 Visits</b> package or you can choose to
            see a doctor with the <b>Pay Per Visit</b> option, by clicking
            directly on <b>I Will Pay Privately</b> in the dashboard.
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="flex-end"
          alignItems="center"
          className={classes.alignButton}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            style={{
              width: '115px',
            }}
            onClick={() => {
              this.setState({ creditPurchaseWarning: true });
            }}
          >
            Get 5 Visits
          </Button>
        </Grid>
      </Grid>
    );
  }

  async getCardList(customerId) {
    await this.props.checkCreditCardList({ customerId: customerId });
    this.setState({
      creditCardList: this.props.creditCardList,
      removeCreditCardWarning: false,
    });
  }

  async removeCreditCard(id, customerId) {
    await axios.post('/api/billing/remove_credit_card', { cardId: id });
    await this.props.checkCreditCardList({ customerId: customerId });
    this.setState({
      creditCardList: this.props.creditCardList,
      removeCreditCardWarning: false,
      disableRemoveButton: false,
    });
  }

  buildBrandLogo(brand) {
    switch (brand) {
      case 'amex':
        return <img src={amexLogo} alt="Amex Logo" width="30px" />;
      case 'visa':
        return <img src={visaLogo} alt="Visa Logo" width="30px" />;
      case 'mastercard':
        return <img src={mastercardLogo} alt="Mastercard Logo" width="30px" />;
      case 'discover':
        return <img src={discoverLogo} alt="Amex Logo" width="30px" />;
      default:
        return <img src={noCardLogo} alt="No Logo" width="30px" />;
    }
  }

  renderCreditCardData(item) {
    return (
      <Grid
        container
        xs={12}
        spacing={1}
        style={{
          margin: '5px 0px',
        }}
      >
        <Grid item container alignItems="center" xs={2} sm={1}>
          {this.buildBrandLogo(item.card.brand)}
        </Grid>
        <Grid item container alignItems="center" xs={10} sm={4}>
          <Typography>Card ending in •••• {item.card.last4}</Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justify="flex-end"
          xs={12}
          sm={4}
          spacing={1}
        >
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() =>
                this.setState({
                  removeCreditCardWarning: true,
                  cardToBeRemoved: {
                    cardId: item.id,
                    last4: item.card.last4,
                    cardBrand: item.card.brand,
                  },
                })
              }
            >
              remove
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() =>
                this.setState({
                  replaceCreditCardWarning: true,
                  cardToBeRemoved: {
                    cardId: item.id,
                    last4: item.card.last4,
                    cardBrand: item.card.brand,
                  },
                })
              }
            >
              replace
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderPaymentsSection() {
    const { classes, auth } = this.props;
    const { creditCardList } = this.state;
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography color="primary" className={classes.spacing}>
            Your payment methods:
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          {creditCardList && creditCardList.length !== 0 ? (
            creditCardList.map((item) => this.renderCreditCardData(item))
          ) : (
            <Grid container xs={12}>
              <Grid item container alignItems="center" xs={12} sm={10}>
                <Typography variant="body2" color="textSecondary">
                  No payment methods
                </Typography>
              </Grid>
              {auth.subscribedToPlan && (
                <Grid item container justify="flex-end" xs={12} sm={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      this.setState({ addCreditCardWarning: true })
                    }
                  >
                    Add card
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  handlePanelChange = (panel, isExpanded) => {
    this.setState({ extendedPanel: isExpanded ? panel : false });
  };

  render() {
    const { expandedPanel, numberOfAllowedAdditionalMembers, creditCardList } =
      this.state;

    const { classes, handleSubmit } = this.props;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container direction="row">
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  className={classes.spacing}
                  variant="h5"
                  color="primary"
                >
                  Settings
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                direction="row"
                className={classes.spacing}
              >
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                      Notifications
                    </Typography>
                  </Grid>
                  <Grid item container xs={9} sm={6}>
                    <Typography color="primary">
                      SMS notifications are{' '}
                      {this.props.auth.textOptIn ? 'ON' : 'OFF'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={6}>
                    <Field name="textOptIn" component={this.textingOptCheck} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.spacing}>
                <PatientPasswordEditForm />
              </Grid>
              <Grid item container className={classes.spacing} spacing={1}>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h6">
                    Account Settings
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Accordion
                    expanded={expandedPanel === 'panel1'}
                    onChange={(event, isExpanded) =>
                      this.setState({
                        expandedPanel: isExpanded ? 'panel1' : false,
                      })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      style={{ backgroundColor: '#EEEDEE' }}
                    >
                      <Typography
                        className={classes.accordionHeading}
                        color="primary"
                      >
                        Family Members
                      </Typography>
                      {numberOfAllowedAdditionalMembers !== undefined ? (
                        <Typography
                          className={classes.accordionSecondaryHeading}
                          variant="body2"
                        >
                          {this.props.auth.familyMembers.length} member
                          {this.props.auth.familyMembers.length === 1
                            ? ''
                            : 's'}
                        </Typography>
                      ) : (
                        <CircularProgress
                          size="15px"
                          style={{
                            margin: '5px 25px',
                          }}
                        />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {this.renderAddMemberSection()}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedPanel === 'panel2'}
                    onChange={(event, isExpanded) =>
                      this.setState({
                        expandedPanel: isExpanded ? 'panel2' : false,
                      })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2bh-content"
                      style={{ backgroundColor: '#EEEDEE' }}
                    >
                      <Typography
                        className={classes.accordionHeading}
                        color="primary"
                      >
                        Paid Visits
                      </Typography>
                      <Typography
                        className={classes.accordionSecondaryHeading}
                        variant="body2"
                      >
                        {this.props.auth.credits} paid{' '}
                        {this.props.auth.credits != 1 ? 'visits' : 'visit'} left
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {this.renderVisitsSection()}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedPanel === 'panel3'}
                    onChange={(event, isExpanded) =>
                      this.setState({
                        expandedPanel: isExpanded ? 'panel3' : false,
                      })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel4bh-content"
                      style={{ backgroundColor: '#EEEDEE' }}
                    >
                      <Typography
                        className={classes.accordionHeading}
                        color="primary"
                      >
                        Payments
                      </Typography>
                      {creditCardList ? (
                        <Typography
                          className={classes.accordionSecondaryHeading}
                          variant="body2"
                        >
                          {creditCardList && creditCardList.length} credit card
                          {creditCardList && creditCardList.length === 1
                            ? ''
                            : 's'}
                        </Typography>
                      ) : !this.props.auth.customerId ? (
                        <Typography
                          className={classes.accordionSecondaryHeading}
                          variant="body2"
                        >
                          0 credit cards
                        </Typography>
                      ) : (
                        <CircularProgress
                          size="15px"
                          style={{
                            margin: '5px 25px',
                          }}
                        />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {this.renderPaymentsSection()}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{
                  // borderLeft: '10px solid #ffbf00',
                  padding: '10px',
                  // backgroundColor: '#fdf3b4'
                }}
              >
                <Grid item container xs={2} sm={1} alignItems="center">
                  <Info style={{ color: '#192b4a', fontSize: '25px' }} />
                </Grid>
                <Grid
                  item
                  container
                  xs={10}
                  sm={11}
                  alignItems="center"
                  id="agreement-info"
                >
                  <Typography variant="body2" color="primary">
                    You have agreed to SnapMED's terms and conditions on{' '}
                    {this.props.auth.registrationDate.substring(0, 10)}.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {this.purchaseCreditsDialog()}
            {this.removeCreditCardDialog()}
            {this.replaceCreditCardDialog()}
            {this.addCreditCardDialog()}
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  memberLimit: state.smartFamilyPlan.memberLimit,
  initialValues: { textOptIn: state.auth.textOptIn },
  creditCardList: state.creditCardList,
});

PatientSettings = reduxForm({
  form: 'dashboardUpdateForm',
  destroyOnUnmount: true,
})(PatientSettings);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, actions)(PatientSettings))
);
