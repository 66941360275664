import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const ProfileFormReview = ({
  onCancel,
  ohipInfo,
  formValues,
  submitProfile,
  history,
}) => {
  const reviewFields = _.map(formValues, (value, key) => {
    if (key.includes('family_member_') && value === true) {
      return (
        <Grid item xs={6}>
          <div style={{ textTransform: 'capitalize' }} key={key}>
            <Typography variant="subtitle2" color="primary">
              {key.split('_').join(' ')} :
            </Typography>

            <Typography
              variant="body1"
              color="default"
              style={{ backgroundColor: 'aliceblue' }}
            >
              Yes
            </Typography>
          </div>
        </Grid>
      );
    } else if (key.includes('family_member_')) {
      //the split join is so that no underscores are present in the front end
      return (
        <Grid item xs={6}>
          <div style={{ textTransform: 'capitalize' }} key={key}>
            <Typography variant="subtitle2" color="primary">
              {key.split('_').join(' ')} :
            </Typography>

            <Typography
              variant="body1"
              color="default"
              style={{ backgroundColor: 'aliceblue' }}
            >
              {value.toString()}
            </Typography>
          </div>
        </Grid>
      );
    }
  });

  return (
    <Fragment>
      {/* <Prompt
        when={true}
        message="This will bring you to the previous page and you will have to redo making this form. Are you sure you want to do this?"
      /> */}
      <CssBaseline />
      <main
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 600,
        }}
      >
        <Paper
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            borderRadius: '7px',
            alignItems: 'center',
            height: '100%',
            padding: '30px',
            position: 'relative',
          }}
        >
          <Grid container spacing={1} style={{ overflowY: 'auto' }}>
            <Grid item xs={12} container direction="row" justify="center">
              <Typography variant="h5" color="primary" align="center">
                Please confirm your profile.
              </Typography>
            </Grid>
            <Grid item xs={12} container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  First Name:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.first_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Last Name:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.last_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Date of Birth:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.date_of_birth}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Sex Assigned at Birth:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.sex}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Gender Identity:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.gender}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Spoken Languages:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.spoken_languages}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Mobile Phone Number:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.mobile_phone_number}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary">
                  Street Address:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.address}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  City:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.city}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Province/Region:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.province}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Postal Code:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.postal_zip_code}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Country:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.country}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  Use of Data Consent:
                </Typography>
                <Typography
                  variant="body1"
                  color="default"
                  style={{ backgroundColor: 'aliceblue' }}
                >
                  {formValues.data_use_consent === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              {formValues.ohipNumber && (
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="primary">
                    OHIP Number:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="default"
                    style={{ backgroundColor: 'aliceblue' }}
                  >
                    {formValues.ohipNumber}
                  </Typography>
                </Grid>
              )}
              {formValues.ohipExpiration && (
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="primary">
                    OHIP Exp. Date:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="default"
                    style={{ backgroundColor: 'aliceblue' }}
                  >
                    {formValues.ohipExpiration}
                  </Typography>
                </Grid>
              )}
              {formValues.files && formValues.files.length > 0 && (
                <Grid item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="primary">
                      OHIP Card:
                    </Typography>
                    <img
                      src={`data:image/png;base64,${formValues.files[0].base64File}`}
                      width="100%"
                    />
                  </Grid>
                  {formValues.ohipNumber && !ohipInfo.isOhipValid && (
                    <Typography variant="caption" color="error">
                      {ohipInfo.message} - You can still register.
                    </Typography>
                  )}
                </Grid>
              )}
              {formValues.is_this_family_account_checkbox && (
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="primary">
                    Family Account:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="default"
                    style={{ backgroundColor: 'aliceblue' }}
                  >
                    {formValues.is_this_family_account_checkbox === 'Yes'
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </Grid>
              )}
              {formValues.is_this_family_account_checkbox && (
                <Grid
                  item
                  container
                  spacing={1}
                  style={{
                    borderTop: '2px solid lightgray',
                    marginTop: '5px',
                  }}
                >
                  {reviewFields}
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              alignItems="center"
              style={{ marginTop: '10px' }}
            >
              <Grid item>
                <IconButton onClick={onCancel}>
                  <ArrowBack color="primary" />
                </IconButton>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'right' }}
                  onClick={() => submitProfile(formValues, history)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return { formValues: state.form.profileForm.values };
}

export default connect(mapStateToProps, actions)(withRouter(ProfileFormReview));
