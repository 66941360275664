import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  TextField,
  Grid,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiPhoneNumber from 'material-ui-phone-number';
import { createTextMask } from 'redux-form-input-masks';
import snapmedLogo from '../../SnapMedLogoRetina';

const styles = (theme) => ({
  layout: {
    //  width: 'auto',
    //  height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: 40,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  previous: {
    marginTop: 40,
    marginBottom: theme.spacing.unit * 3,
    float: 'left',
  },
  bottomSpacing: {
    marginBottom: 10,
  },
});

const PharmacyField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    fullWidth
    id={name}
    label={label}
    type={type}
    variant="outlined"
  />
);

const PhoneNumberField = ({ name, input, meta: { touched, error } }) => (
  <MuiPhoneNumber
    {...input}
    error={touched && error}
    helperText={touched && error}
    //countryCodeEditable={false}
    defaultCountry={'ca'}
    onlyCountries={['ca', 'gb', 'au', 'lk', 'in', 'fr']}
    //preferredCountries={['ca', 'us']}
    disableAreaCodes="true"
    fullWidth
    variant="outlined"
  />
);

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const PostalCodeField = ({
  input,
  name,
  type,
  meta: { touched, error },
  placeholder,
  disabled,
}) => (
  <TextField
    {...input}
    type={type}
    id={name}
    fullWidth
    placeholder={placeholder}
    disabled={disabled}
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
  />
);

class PreferredPharmacyForm extends Component {
  constructor(props) {
    super(props);
  }

  renderFields(name) {
    return <Field name={name} type="text" component={PharmacyField} />;
  }

  renderPhoneFields(name) {
    return <Field name={name} component={PhoneNumberField} />;
  }

  renderPostalCodeFields(name) {
    return (
      <Field name={name} component={PostalCodeField} {...postalCodeMask} />
    );
  }

  render() {
    const { handleSubmit, previousStep, onSubmit, classes } = this.props;
    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container spacing={1} direction="row">
              <Grid xs={12}>
                <img
                  src={snapmedLogo}
                  style={{ width: 100, marginBottom: 10 }}
                />
              </Grid>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      color="primary"
                      style={{
                        textAlign: 'center',
                        marginBottom: 20,
                      }}
                    >
                      Preferred Pharmacy (Optional)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.bottomSpacing}>
                    <Typography variant="body1">Pharmacy Name</Typography>
                    {this.renderFields('pharmacy_name')}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.bottomSpacing}>
                    <Typography variant="body1">Fax Number</Typography>
                    {this.renderPhoneFields('pharmacy_fax')}
                  </Grid>
                  <Grid item xs={12} className={classes.bottomSpacing}>
                    <Typography variant="body1">Street</Typography>
                    {this.renderFields('pharmacy_street')}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.bottomSpacing}>
                    <Typography variant="body1">City</Typography>
                    {this.renderFields('pharmacy_city')}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.bottomSpacing}>
                    <Typography variant="body1">Province</Typography>
                    {this.renderFields('pharmacy_province')}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.bottomSpacing}>
                    <Typography variant="body1">Postal Code</Typography>
                    {this.renderPostalCodeFields('pharmacy_postal_code')}
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.bottomSpacing}>
                    <Typography variant="body1">Country</Typography>
                    {this.renderFields('pharmacy_country')}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
                  className={classes.previous}
                  onClick={previousStep}
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                >
                  {' '}
                  next
                </Button>
              </form>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  if (values.pharmacy_fax && values.pharmacy_fax[1] !== '1') {
    errors.pharmacy_fax = 'Please enter a valid North-American phone number';
  }

  if (
    values.pharmacy_fax &&
    values.pharmacy_fax.replace(/\D+/g, '').length !== 11
  ) {
    errors.pharmacy_fax = 'Please enter a valid North-American phone number';
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    wizard: state.wizard,
  };
}

PreferredPharmacyForm = connect(
  mapStateToProps,
  actions
)(PreferredPharmacyForm);

PreferredPharmacyForm = reduxForm({
  validate,
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PreferredPharmacyForm);

export default withStyles(styles)(PreferredPharmacyForm);
