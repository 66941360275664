import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import * as actions from '../../../../../actions';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  CssBaseline,
  Typography,
  Grid,
  Slide,
  Chip,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
    padding: 30,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },

  button: {
    marginBottom: 10,
  },

  img: {
    marginBottom: '5%',
    width: 'auto',
    maxWidth: '100%',
    height: 'inherit',
  },
  spacing: {
    marginBottom: 15,
  },
  align: {
    marginRight: '37%',
  },
  imgL: {
    maxWidth: '100%',
    height: '100%',
  },
  mobileStepper: {
    width: '100%',
    height: '20%',
    overflowX: 'hidden',
    backgroundColor: '#Efefef',
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  resize: {
    fontSize: 50,
  },
});

class PatientInfoForm extends Component {
  constructor(props) {
    super(props);
    this.perPage = 8;
    this.state = {
      activeStep: 0,
      showWindowPortal: false,
      page: 0,
      descendingOrder: true,
      pics: [],
      documents: [],
      fileList: [],
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }

  async componentDidMount() {
    const pics = await axios.get('/api/get_appointment_pictures');
    this.setState({
      pics: pics.data.pics,
    });
    this.refreshFiles();
  }

  refreshFiles = () => {
    axios
      .get(
        `/api/get_appointment_files/${this.props.appointmentParams.appointment._id}`
      )
      .then((res) => this.setState({ fileList: res.data.files }));
  };

  openPatientDoc(data, filename) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  displayMedicalData = (medData) => {
    return medData;
  };

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
    });
  }
  lastPage() {
    this.setState({
      page: this.numPages,
    });
  }
  firstPage() {
    this.setState({
      page: 0,
    });
  }

  render() {
    const { appointmentParams } = this.props;
    const { pics, fileList } = this.state;

    const appointmentHistory = this.props.appointmentParams.patientHistory;

    const maxSteps = pics ? pics.length : 0;

    var numberAppts = appointmentHistory ? appointmentHistory.length : 0;
    //pages start at 0 therefore subtract one
    var pages = numberAppts / this.perPage - 1;
    var quotient = pages - Math.floor(pages);

    this.numPages =
      pages < 1 ? 0 : quotient == 0 ? pages : Math.floor(pages) + 1;
    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;

    return (
      <Fragment>
        <CssBaseline />
        <Slide direction="right" in={true}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{
              margin: 'auto',
              width: '90%',
              padding: '1rem',
            }}
          >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h5" color="primary">
                Appointment Information
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Patient Name:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.name.first +
                  '\xa0' +
                  appointmentParams.patient.name.last}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Phone Number:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.mobilePhone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Sex Assigned at Birth:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.sex}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Gender Identity:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.gender}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                DOB:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.dateOfBirth.substr(0, 10)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Location:
              </Typography>
              <Typography variant="body2">
                {appointmentParams.patient.city +
                  ',\xa0' +
                  appointmentParams.patient.province}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">
                Spoken Languages:
              </Typography>
              <Typography variant="body2">
                {appointmentParams.patient.spokenLanguages.join(', ')}
              </Typography>
            </Grid>
            {appointmentParams.appointment.ohipAppointment && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="error">
                    *THIS IS A OHIP APPOINTMENT
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" color="primary">
                      OHIP Card
                    </Typography>
                    <img
                      src={appointmentParams.patient.ohipCardImage}
                      width="100%"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {appointmentParams.appointment.patientRequireSickNote && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="error">
                  *This patient requires a work or school sick note
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Symptoms:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">
                Reason for Visit:
              </Typography>
              <Typography variant="body2">
                {appointmentParams.appointment.shortReason}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">
                Symptoms:
              </Typography>
              <Typography variant="body2">
                {appointmentParams.appointment.symptoms.join(', ')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">
                Temperature:
              </Typography>
              <Typography variant="body2">
                {appointmentParams.appointment.temperature}&#176;C
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Heart Rate:
              </Typography>
              <Typography variant="body1">
                {appointmentParams.appointment.heartRate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Blood Pressure:
              </Typography>
              <Typography variant="body1">
                {appointmentParams.appointment.bloodPressure}
              </Typography>
            </Grid>
            {appointmentParams.appointment.ohipNumber && (
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="primary">
                  OHIP Number:
                </Typography>
                <Typography variant="body1">
                  {appointmentParams.appointment.ohipNumber}
                </Typography>
              </Grid>
            )}
            {appointmentParams.appointment.ohipExpiration && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="primary">
                  OHIP Expiration:
                </Typography>
                <Typography variant="body1">
                  {appointmentParams.appointment.ohipExpiration}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Medical History:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Current Medications:
              </Typography>

              <Typography variant="body2">
                {this.displayMedicalData(
                  appointmentParams.patient.patient.currentMedication
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Other Medications:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.currentMedicationOtherField}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Medical Procedures:
              </Typography>

              <Typography variant="body2">
                {this.displayMedicalData(
                  appointmentParams.patient.patient.medicalProcedures
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Other Medical Procedures:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.medicalProceduresOtherField}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Current Medical Conditions:
              </Typography>

              <Typography variant="body2">
                {this.displayMedicalData(
                  appointmentParams.patient.patient.currentMedicalCondition
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Other Medical Conditions:
              </Typography>

              <Typography variant="body2">
                {
                  appointmentParams.patient.patient
                    .currentMedicalConditionOtherField
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Allergies:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Medical Allergies:
              </Typography>

              <Typography variant="body2">
                {this.displayMedicalData(
                  appointmentParams.patient.patient.medicalAllergies
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Other Medical Allergies:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.medicalAllergiesOtherField}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Other Allergies:
              </Typography>

              <Typography variant="body2">
                {this.displayMedicalData(
                  appointmentParams.patient.patient.otherAllergies
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                More Allergies:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.otherAllergiesOtherField}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Substances:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Tobacco smoker?
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.isTobaccoSmoker
                  ? 'Yes'
                  : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Alcohol consumer?
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.isAlcoholConsumer
                  ? 'Yes'
                  : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Substance user?
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.isSubstanceUser
                  ? 'Yes'
                  : 'No'}
              </Typography>
            </Grid>
            {appointmentParams.patient.patient.isSubstanceUser && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="primary">
                  Substances used:
                </Typography>

                <Typography variant="body2">
                  {appointmentParams.patient.patient.substance
                    ? appointmentParams.patient.patient.substance
                    : 'Not provided.'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Pharmacy:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Pharmacy Name:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                  ? appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      .name
                  : appointmentParams.patient.patient.pharmacy.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Pharmacy Fax Number:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                  ? appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      .faxNumber
                  : appointmentParams.patient.patient.pharmacy.faxNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Pharmacy Address:
              </Typography>

              <Typography variant="body2">
                {appointmentParams.patient.patient.pharmacy &&
                appointmentParams.patient.patient.pharmacy.premiumPharmacy
                  ? appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      .address.street
                  : appointmentParams.patient.patient.pharmacy.address.street}
              </Typography>
              <Typography variant="body2">
                {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                  ? appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      .address.city
                  : appointmentParams.patient.patient.pharmacy.address.city}
                ,{' '}
                {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                  ? appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      .address.province
                  : appointmentParams.patient.patient.pharmacy.address.province}
              </Typography>
              <Typography variant="body2">
                {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                  ? appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      .address.postalCode
                  : appointmentParams.patient.patient.pharmacy.address
                      .postalCode}
              </Typography>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">
                Uploaded files:
              </Typography>
              {fileList.map((path, index) => (
                <Grid
                  item
                  key={`file-${index}`}
                  style={{ marginBottom: '5px' }}
                >
                  <Chip
                    variant="outlined"
                    size="small"
                    label={path.split('/').pop()}
                    icon={<GetApp />}
                    onClick={async () => {
                      await axios
                        .get(path, { responseType: 'blob' })
                        .then((response) =>
                          this.openPatientDoc(
                            response.data,
                            path.split('/').pop()
                          )
                        );
                    }}
                  />
                </Grid>
              ))}

              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => this.refreshFiles()}
              >
                Refresh
              </Button>
            </Grid>
          </Grid>
        </Slide>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointmentParams: state.appointmentParams,
  };
}

PatientInfoForm = connect(mapStateToProps, actions)(PatientInfoForm);

PatientInfoForm = withStyles(styles, { withTheme: true })(PatientInfoForm);

export default PatientInfoForm;
