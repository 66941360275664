import React, { Component, Fragment } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ProfileForm from './ProfileForm';
import ProfileFormReview from './ProfileFormReview';
import * as actions from '../../actions';

class ProfileFormRoot extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1, ohipProvince: 'Ontario', ohipInfo: {} };
  }

  componentDidMount() {
    this.props.fetchData();
  }

  renderContent() {
    const { ohipProvince } = this.state;
    switch (this.state.step) {
      case 1:
        return (
          <ProfileForm
            onProfileSubmit={(values) => this.setState({ step: 2 })}
            initialValues={{
              country_code: '+1',
              first_name: this.props.auth.name.first,
              last_name: this.props.auth.name.last,
            }}
          />
        );

      case 2:
        return (
          <ProfileFormReview
            ohipInfo={this.state.ohipInfo}
            onCancel={() => this.setState({ step: 1 })}
          />
        );
    }
  }

  render() {
    return <Fragment>{this.renderContent()}</Fragment>;
  }
}
const selector = formValueSelector('profileForm');
function mapStateToProps(state) {
  return {
    auth: state.auth,
    province: selector(state, 'province'),
    ohipNumber: selector(state, 'ohipNumber'),
  };
}

ProfileFormRoot = connect(mapStateToProps, actions)(ProfileFormRoot);

export default reduxForm({
  form: 'profileForm',
})(ProfileFormRoot);
