import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';
import PathBuilder from '../../api/media-path-builder';
import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Button,
  CircularProgress,
  LinearProgress,
  Box,
  Fab,
} from '@material-ui/core';
import { Delete, Add, CheckCircle, Publish, Cancel } from '@material-ui/icons';
import toast from 'react-hot-toast';

const styles = (theme) => ({
  inputStyle: {
    cursor: 'pointer',
  },
  listItem: {
    padding: '5px',
    width: '300px',
    minWidth: '100%',
  },
  dropzone: {
    border: '1px dashed black',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
});

function FileInput(props) {
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [totalFileSize, setTotalFileSize] = useState(0);
  const MAX_SIZE = 30e6; // 30MB
  const allowedTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  useEffect(() => {
    if (!props.disableFileFecth) {
      async function fetchFiles() {
        const pathBuilder = PathBuilder;
        const reqPath = pathBuilder.appointmentDirPath(
          props.patientId,
          props.appointmentId
        );

        const fileList = await axios.get(reqPath);
        if (fileList.data) {
          setTotalFileSize(fileList.data.totalSize);
        }
      }
      fetchFiles();
    }
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);

    const filteredFiles = newFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );
    // No need to filter the rejected files here because we're only validating file types
    const rejectedFiles = newFiles.filter(
      (file) => !allowedTypes.includes(file.type)
    );

    addFiles(filteredFiles);
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (e) => {
    addFiles(e.target.files);
  };

  const addFiles = (newFiles) => {
    let newSize = totalFileSize;
    let updatedFiles = [...files];
    let rejectedFiles = [];

    for (let file of newFiles) {
      if (newSize + file.size <= MAX_SIZE) {
        updatedFiles.push(file);
        newSize += file.size;
      } else {
        rejectedFiles.push({ file: file, error: 'File size limit exceeded' });
      }
    }

    setFiles(updatedFiles);
    setRejectedFiles(rejectedFiles);
    setTotalFileSize(newSize);
  };

  const handleDelete = (name) => {
    const filteredFiles = files.filter((file) => file.name !== name);
    const totalSizeOfNewFiles = filteredFiles.reduce(
      (total, file) => total + file.size,
      0
    );
    setFiles(filteredFiles);
    setTotalFileSize(totalSizeOfNewFiles);
  };

  async function uploadFiles() {
    const pathBuilder = PathBuilder;
    let path;

    setLoading(true);

    const promises = files.map(async (file) => {
      const formData = new FormData();
      let newFileName = `${file.name.replace(/(_| )/g, '-')}`;

      // detect length of file name and truncate if necessary, but keep extension
      if (newFileName.length > 50) {
        newFileName = `${newFileName.slice(0, 50)}${newFileName.slice(
          newFileName.lastIndexOf('.')
        )}`;
      }

      const newFile = new File([file], newFileName, {
        type: file.type,
      });
      path = pathBuilder.appointmentFilePath(
        props.patientId,
        props.appointmentId,
        newFileName
      );

      formData.append('file', newFile);
      formData.append('role', props.auth.role);
      return axios
        .post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const status = res.response ? res.response.status : res.status;
          if (status >= 400) {
            setUploadStatus((prevState) => ({
              ...prevState,
              [file.name]: 'failed',
            }));
          } else {
            setUploadStatus((prevState) => ({
              ...prevState,
              [file.name]: 'uploaded',
            }));
          }
        })
        .catch((err) => {
          setUploadStatus((prevState) => ({
            ...prevState,
            [file.name]: 'failed',
          }));
        });
    });

    await Promise.all(promises);

    setLoading(false);
    const reqPath = pathBuilder.appointmentDirPath(
      props.patientId,
      props.appointmentId
    );
    const fileList = await axios.get(reqPath);
    if (fileList.data) {
      await axios.post('/api/update_appointment_files', {
        fileList: fileList.data.files,
        apptId: props.appointmentId,
      });
      props.closeComponent();
    }
  }

  function renderMedia() {
    const { classes } = props;
    return (
      <Grid item container>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                style={{ height: '8px', borderRadius: '5px' }}
                value={Math.round(
                  (Number(totalFileSize) / Number(MAX_SIZE)) * 100
                )}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {Math.round(Number(totalFileSize) / 1e6)}MB
              </Typography>
            </Box>
          </Box>
          {files.map((doc, index) => {
            return (
              <Paper
                elevation={3}
                key={`file-${index}`}
                style={{ marginBottom: '6px' }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  className={classes.listItem}
                >
                  <Grid item container xs={1} alignItems="center">
                    {uploadStatus[doc.name] === 'uploaded' ? (
                      <CheckCircle style={{ color: 'green' }} />
                    ) : uploadStatus[doc.name] === 'failed' ? (
                      <Cancel style={{ color: 'red' }} />
                    ) : loading ? (
                      <CircularProgress
                        size={15}
                        color="primary"
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    ) : (
                      <Publish color="primary" />
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2" noWrap>
                      {uploadStatus[doc.name] !== 'uploaded' &&
                      uploadStatus[doc.name] !== 'failed' &&
                      loading
                        ? 'Scanning file...'
                        : doc.name}
                    </Typography>
                  </Grid>
                  <Grid item container xs={1} justify="flex-end">
                    <IconButton
                      size="small"
                      disabled={loading}
                      onClick={() => handleDelete(doc.name)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align="center" color="primary">
            Rejected files:
          </Typography>
          <ul style={{ overflow: 'visible' }}>
            {rejectedFiles.map(({ file, error }, i) => (
              <li key={i}>
                {file.name}
                <ul>
                  <li key={`error-${i}`}>{error}</li>
                </ul>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <div
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className={props.classes.dropzone}
      >
        <input
          type="file"
          id="fileInput"
          accept="image/*, .pdf, .doc, .docx"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Grid item xs={12} container direction="row" justify="center">
          <Grid item style={{ margin: '10px 0px' }}>
            <Typography align="center">
              Drag files here or click to upload (30MB Max.)
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="center"
            style={{ marginBottom: '10px' }}
          >
            <Fab color="primary" aria-label="add">
              <Add />
            </Fab>
          </Grid>
        </Grid>
      </div>
      {renderMedia()}
      <Grid item container xs={12} justify="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={() => props.closeComponent()}
          >
            Skip
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={uploadFiles}
            disabled={files.length === 0 || loading}
          >
            Upload
            {loading && (
              <CircularProgress
                size={15}
                color="white"
                style={{
                  marginLeft: 5,
                }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
  };
}

FileInput = connect(mapStateToProps, actions)(FileInput);

export default withStyles(styles)(FileInput);
