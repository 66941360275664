import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { REACT_APP_JAAS_APP_ID } from '../../constants';
import {
  Button,
  CssBaseline,
  Typography,
  Grid,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  CircularProgress,
  TextField,
} from '@material-ui/core';

const logo = require('../../images/SnapMed-Logo-smaller.png');

const styles = (theme) => ({
  root: {
    flexGrow: 1, //specifies grow factor of a flex item
    padding: 5,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  submitAppointment: {
    marginTop: '1%',
    float: 'right',
  },
  submitButton: {
    margin: 'auto',
  },
  popupTitle: {
    margin: 'auto',
  },
  videoCall: {
    marginTop: '-120px',
  },
});

const GuestTextField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    //required
    {...input}
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
  />
);

class TestAppointmentRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      openDialog: false,
      loading: true,
      showForm: false,
      showNoCall: false,
      token: '',
      room: '',
      doctorId: '',
    };
  }

  async componentDidMount() {
    const { room } = this.props.match.params;
    await axios
      .get(`/api/existing-call/${room}`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          this.setState({
            showForm: true,
          });
        } else {
          this.setState({
            showNoCall: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          showNoCall: true,
        });
      });
  }

  handleGuestSubmit = async (values) => {
    const { room } = this.props.match.params;
    this.setState({ loading: true, showForm: false });
    await axios
      .post('/api/guest-jaas-params', {
        room: room,
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
      })
      .then((res) => {
        if (res.data) {
          this.setState({
            token: res.data.token,
            room: res.data.room,
            showForm: false,
            loading: false,
          });
        } else {
          this.setState({ loading: false, showNoCall: true });
        }
      });
  };

  handleCloseDialog() {
    this.setState({ openDialog: false });
    this.props.history.push('/');
  }

  getDoctorId = (rooms) => {
    const participants = rooms[0].participants;
    const doctor = participants.find(
      (participant) => participant.role === 'moderator'
    );
    if (doctor) {
      this.setState({ doctorId: doctor.id });
    }
  };

  renderGuestForm() {
    const { handleSubmit } = this.props;
    const { loading } = this.state;
    return (
      <form onSubmit={handleSubmit(this.handleGuestSubmit)}>
        <Grid item container xs={12} justify="center">
          <Typography color="primary" variant="h5" gutterBottom>
            Thank you for accepting our invitation!
          </Typography>
          <Typography color="primary" variant="subtitle1" gutterBottom>
            Please enter your information to connect to the meeting.
          </Typography>
          <Field
            name="name"
            type="text"
            label="Name"
            component={GuestTextField}
          />
          <Field
            name="email"
            type="email"
            label="Email"
            component={GuestTextField}
          />
          <Field
            name="phoneNumber"
            type="text"
            label="Phone Number"
            component={GuestTextField}
          />
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            color="primary"
          >
            Connect
          </Button>
        </Grid>
      </form>
    );
  }

  renderNoCallMessage() {
    const { classes } = this.props;
    return (
      <>
        <Typography
          variant="h5"
          color="primary"
          style={{ marginTop: 20 }}
          align="center"
        >
          Video call not available.
        </Typography>
        <Typography
          variant="h6"
          color="primary"
          style={{ marginTop: 20, textAlign: 'center' }}
        >
          Are you looking for convenient virtual healthcare?
        </Typography>
        <Button
          className={classes.button}
          href="https://snapmed.ca"
          variant="outlined"
          color="primary"
          style={{
            margin: '15px',
          }}
        >
          Visit Snapmed
        </Button>
      </>
    );
  }

  render() {
    const { openDialog, token, room, loading, showForm, showNoCall } =
      this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Grid item xs={12} className={classes.videoCall}>
          {token && !showForm ? (
            <JaaSMeeting
              appId={REACT_APP_JAAS_APP_ID}
              roomName={room}
              jwt={token}
              configOverwrite={{
                readOnlyName: false, // disable name editing; if disableProfile is true, this has no effect
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: true,
                backgroundAlpha: 0.5,
                disablePolls: true,
                disableModeratorIndicator: true,
                disableProfile: false,
                disableSelfViewSettings: true,
                enableSaveLogs: false,
                disableShowMoreStats: true,
                disableAddingBackgroundImages: true,
                toolbarButtons: [
                  'camera',
                  'microphone',
                  'chat',
                  'fullscreen',
                  'hangup',
                ],
                hiddenPremeetingButtons: ['select-background', 'settings'],
                remoteVideoMenu: {
                  disablePrivateChat: true,
                },
                recordings: {
                  //If true, shows a notification at the start of the meeting with a call to action button
                  //to start recording (for users who can do so, like moderators).
                  suggestRecording: false,
                  //If true, shows a warning label in the prejoin screen to point out the possibility that
                  //the call you're joining might be recorded.
                  showPrejoinWarning: false,
                },
              }}
              interfaceConfigOverwrite={{
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,
              }}
              onApiReady={(api) => {
                this.setState({ jaasApi: api });
                api.addListener('videoConferenceJoined', (obj) => {
                  api.getRoomsInfo().then((response) => {
                    this.getDoctorId(response.rooms);
                  });
                });
                api.addListener('participantLeft', (obj) => {
                  if (obj.id === this.state.doctorId) {
                    api.executeCommand('hangup');
                  }
                });
                api.addListener('notificationTriggered', (obj) => {
                  if (obj.title === 'lobby.joinRejectedTitle') {
                    api.executeCommand('hangup');
                  }
                });
              }}
              onReadyToClose={() => {
                this.setState({ openDialog: true });
              }}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = '100vh';
              }}
            />
          ) : (
            <Paper className={classes.paper}>
              <Grid item style={{ margin: '10px auto' }}>
                <img src={logo} alt="SnapMed Logo" height="60" width="204" />
              </Grid>
              {showForm && this.renderGuestForm()}
              {loading && (
                <>
                  <CircularProgress size={40} color="primary" />
                  <Typography
                    color="primary"
                    style={{ marginTop: 20 }}
                    align="center"
                  >
                    Please wait while we redirect you.
                  </Typography>
                </>
              )}
              {showNoCall && this.renderNoCallMessage()}
            </Paper>
          )}
        </Grid>
        <Dialog
          open={openDialog}
          onClose={() => this.handleCloseDialog()}
          fullWidth
        >
          <DialogTitle>Consultation Ended</DialogTitle>
          <DialogContent>
            <Typography paragraph>
              The virtual consultation has ended. You will now be redirected.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleCloseDialog()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

TestAppointmentRoom = reduxForm({
  form: 'guestJaas',
})(TestAppointmentRoom);

export default withStyles(styles)(TestAppointmentRoom);
