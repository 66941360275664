class PathBuilder {
  appointmentFilePath(patientId, appointmentId, filename) {
    return `/media/patients/${patientId}/appointments/${appointmentId}/${filename}`;
  }

  appointmentDirPath(patientId, appointmentId) {
    return `/media/patients/${patientId}/appointments/${appointmentId}`;
  }

  patientHealthCardFilePath(patientId, filename) {
    return `/media/patients/${patientId}/health-card/${filename}`;
  }

  patientFilePath(patientId, filename) {
    return `/media/patients/${patientId}/${filename}`;
  }

  doctorFilePath(doctorId, filename) {
    return `/media/doctors/${doctorId}/${filename}`;
  }

  doctorSignatureFilePath(doctorId, filename) {
    return `/media/doctors/${doctorId}/signature/${filename}`;
  }
}

export default new PathBuilder();
