import React, { Component, Fragment } from 'react';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

class StandardMembershipPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      option: undefined,
    };
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  handleContinue = () => {
    if (this.props.onContinue && typeof this.props.onContinue === 'function') {
      this.props.onContinue(this.state.option);
    }
  };

  render() {
    const { product } = this.props;
    const { option } = this.state;

    return (
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Purchase Standard Membership?
          </Typography>
        </DialogTitle>
        <DialogContent>
          {product && (
            <Fragment>
              <Typography variant="body1">
                Please select a membership:
              </Typography>
              <br />
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="membership-select-label">
                  Memberships
                </InputLabel>
                <Select
                  label="Memberships"
                  labelId="membership-select-id"
                  onChange={(event) =>
                    this.setState({ option: event.target.value })
                  }
                >
                  {product.prices.map((price, i) => {
                    return (
                      <MenuItem value={price} key={i}>
                        {product.name} - {price.nickname}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Fragment>
          )}
          {option && (
            <Fragment>
              <Collapse in={true} style={{ paddingTop: 16 }}>
                <Typography>
                  <b>Item:</b> {product.name} - {option.nickname}
                </Typography>
                <Typography>
                  <b>Cost:</b> ${option.unit_amount / 100}{' '}
                  {option.currency.toUpperCase()}
                </Typography>
              </Collapse>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Button variant="contained" onClick={this.handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleContinue}
            disabled={!option}
          >
            Purchase
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default StandardMembershipPurchase;
