import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, FieldArray } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import symptomsFormFields1 from './symptomsFormFields1';
import symptomsFormFields2 from './symptomsFormFields2';
import * as actions from '../../actions';
import '../../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../../node_modules/dropzone/dist/min/dropzone.min.css';
import { withRouter } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { createTextMask } from 'redux-form-input-masks';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    float: 'right',
  },
});

const SymptomsField = ({ name, input, label, meta: { touched, error } }) => (
  <FormControlLabel
    style={{ display: 'flex' }}
    control={
      <Checkbox
        {...input}
        id={name}
        checked={input.value ? label : false}
        onChange={input.onChange}
        //value="checkedB"
        color="primary"
      />
    }
    label={label}
  />
);

const RequireNote = ({ name, input, meta: { touched, error } }) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          id={name}
          checked={input.value ? true : false}
          //value="checkedB"
          color="primary"
        />
      }
    />
  </FormControl>
);

const ShortReasonTextField = ({
  input,
  name,
  type,
  characterCount,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    //required
    fullWidth
    autoComplete="off"
    type={type}
    id={name}
    label={`${characterCount} characters`}
    error={touched && error}
    helperText={touched && error}
    inputProps={{
      maxLength: 100,
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

const TemperatureTextField = ({ input, name, type }) => (
  <TextField
    {...input}
    //required
    //fullWidth
    type={type}
    id={name}
    variant="outlined"
    size="small"
    placeholder="Temp."
    InputProps={{
      style: {
        marginTop: '8px',
      },
      endAdornment: <InputAdornment position="end">&#176;C</InputAdornment>,
    }}
    inputProps={{
      style: {
        padding: '6px',
        fontSize: '0.85rem',
      },
    }}
  />
);

const HeartRateField = ({ input, name }) => (
  <TextField
    {...input}
    id={name}
    type="input"
    variant="outlined"
    size="small"
    label="Heart Rate"
    helperText="optional"
  />
);

const BloodPressureField = ({ input, name }) => (
  <TextField
    {...input}
    id={name}
    type="input"
    variant="outlined"
    size="small"
    label="Blood Pressure"
    helperText="optional"
  />
);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class SymptomsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      reasonString: '',
      characterMax: 100,
      characterCount: 100,
    };
  }

  componentDidMount() {
    if (!this.props.patientId) {
      this.props.history.push('/dashboard');
    }
  }

  renderShortReasonField() {
    return (
      <Field
        name="short_reason"
        component={ShortReasonTextField}
        characterCount={this.state.characterCount}
        onChange={(event) =>
          this.setState(
            {
              reasonString: event.target.value,
            },
            () =>
              this.setState({
                characterCount:
                  this.state.characterMax - this.state.reasonString.length,
              })
          )
        }
      />
    );
  }

  renderSymptomsList1() {
    return _.map(symptomsFormFields1, ({ label, name, type, id }) => {
      if (label === 'Fever') {
        return (
          <Grid container direction="row">
            <Grid item xs={3}>
              <Field
                key={id}
                name={name}
                label={label}
                type={type}
                parse={(value, name) => (value ? label : '')}
                component={SymptomsField}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="temperature"
                type="number"
                component={TemperatureTextField}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Field
          key={id}
          name={name}
          label={label}
          type={type}
          parse={(value, name) => (value ? label : '')}
          component={SymptomsField}
        />
      );
    });
  }

  renderSymptomsList2() {
    return _.map(symptomsFormFields2, ({ label, name, type, id }) => {
      return (
        <Field
          key={id}
          name={name}
          label={label}
          type={type}
          parse={(value, name) => (value ? label : '')}
          component={SymptomsField}
        />
      );
    });
  }

  renderExtraSymptomsFields(name) {
    switch (name) {
      case 'heart_rate':
        return <Field name={name} component={HeartRateField} />;
      case 'blood_pressure':
        return <Field name={name} component={BloodPressureField} />;
    }
  }

  handleButtonError() {
    if (this.props.syncErrors && this.props.syncErrors.short_reason) {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
  }

  render() {
    const { handleSubmit, classes, history } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container spacing={1} justify="center">
              <form
                className={classes.form}
                onSubmit={handleSubmit((values) => {
                  this.props.onSymptomsSubmit(values);
                  this.setState({ loading: true });
                })}
              >
                <Grid item xs={12}>
                  <Typography align="center" variant="h5" color="primary">
                    How can we help you today?
                  </Typography>
                  <br />
                </Grid>
                <Typography align="center" variant="h6" color="primary">
                  Please Select Your Symptoms
                </Typography>
                <Typography color="primary">
                  Patient: {this.props.selectedUser.fullName}
                </Typography>
                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={12} sm={6}>
                    {this.renderSymptomsList1()}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.renderSymptomsList2()}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      style={{ display: 'flex' }}
                      control={<Checkbox color="primary" />}
                      label="Other"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    spacing={1}
                    justify="center"
                  >
                    <Grid item xs={6}>
                      {this.renderExtraSymptomsFields('heart_rate')}
                    </Grid>
                    <Grid item xs={6}>
                      {this.renderExtraSymptomsFields('blood_pressure')}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Typography align="center" variant="h6" color="primary">
                    Pharmacy Information
                  </Typography>
                </Grid>
                {this.props.auth.patient.pharmacy.faxNumber ? (
                  <Grid item container>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          Default Pharmacy
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          {this.props.auth.patient.pharmacy.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {this.props.auth.patient.pharmacy.address.street}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {`${this.props.auth.patient.pharmacy.address.city}, `}
                          {this.props.auth.patient.pharmacy.address.province}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {this.props.auth.patient.pharmacy.address.postalCode}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Grid item xs={12}>
                        <Typography color="primary">Fax Number</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <Typography color="primary">
                          {this.props.auth.patient.pharmacy.faxNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Grid item xs={12}>
                        <Typography color="primary">Phone Number</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <Typography color="primary">
                          {this.props.auth.patient.pharmacy.phoneNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : this.props.auth.patient.pharmacy.premiumPharmacy ? (
                  <Grid item container>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          Default Pharmacy
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          {
                            this.props.auth.patient.pharmacy.premiumPharmacy
                              .name
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {
                            this.props.auth.patient.pharmacy.premiumPharmacy
                              .address.street
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {`${this.props.auth.patient.pharmacy.premiumPharmacy.address.city}, `}
                          {
                            this.props.auth.patient.pharmacy.premiumPharmacy
                              .address.province
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {
                            this.props.auth.patient.pharmacy.premiumPharmacy
                              .address.postalCode
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Grid item xs={12}>
                        <Typography color="primary">Fax Number</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {
                            this.props.auth.patient.pharmacy.premiumPharmacy
                              .faxNumber
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Grid item xs={12}>
                        <Typography color="primary">Phone Number</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <Typography color="primary">
                          {
                            this.props.auth.patient.pharmacy.premiumPharmacy
                              .phoneNumber
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: 'aliceBlue',
                      marginBottom: '10px',
                      height: '100px',
                    }}
                    container
                    alignContent="center"
                    justify="center"
                  >
                    <Typography variant="button" align="center" color="error">
                      No pharmacy information
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        '/dashboard/PharmacyInformation/main'
                      )
                    }
                  >
                    {this.props.auth.patient.pharmacy.faxNumber ||
                    this.props.auth.patient.pharmacy.premiumPharmacy
                      ? 'Change Pharmacy'
                      : 'Add Pharmacy'}
                  </Button>
                </Grid>
                <br />
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Typography variant="body1" style={{ margin: '10px 0px' }}>
                    <strong>Short reason for your visit:</strong>
                  </Typography>
                  <div>{this.renderShortReasonField()}</div>
                </Grid>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
                >
                  <Link
                    style={{ color: 'white', textDecoration: 'none' }}
                    to="/dashboard"
                  >
                    Cancel
                  </Link>
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.alignButton}
                  type="submit"
                  //disabled={this.state.loading}
                  onClick={() => {
                    this.setState({ loading: true });
                    this.handleButtonError();
                  }}
                >
                  next
                </Button>
                {this.state.error && (
                  <Grid item xs={12}>
                    <Typography color="error" align="right">
                      Please review form.
                    </Typography>
                  </Grid>
                )}
              </form>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = [];

  if (!values.short_reason) {
    errors.short_reason = 'Please enter a short reason';
  }

  return errors;
}

const selector = formValueSelector('symptomsForm');

function mapStateToProps(state) {
  if (!state.form || !state.form.symptomsForm)
    return {
      auth: state.auth,
      wizard: state.wizard,
      files: selector(state, 'files'),
      ohipAppointment: state.ohipAppointment,
      appointmentParams: state.appointmentParams,
      formValues: state.form.symptomsData,
      selectedUser: state.selectedUser,
      timerValues: state.timerValues,
    };

  return {
    auth: state.auth,
    wizard: state.wizard,
    files: selector(state, 'files'),
    ohipAppointment: state.ohipAppointment,
    appointmentParams: state.appointmentParams,
    formValues: state.form.symptomsData,
    selectedUser: state.selectedUser,
    syncErrors: state.form.symptomsForm.syncErrors,
    timerValues: state.timerValues,
  };
}

SymptomsForm = connect(mapStateToProps, actions)(withRouter(SymptomsForm));

SymptomsForm = reduxForm({
  validate,
  form: 'symptomsForm',
  destroyOnUnmount: true,
})(SymptomsForm);

export default withStyles(styles)(SymptomsForm);
