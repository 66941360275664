import React, { Component, Fragment } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  background: {
    backgroundColor: '#EEEDEE',
  },
  status: {
    marginTop: 3,
  },
  summary: {
    backgroundColor: '#EEEDEE',
  },
  sectionHeader: {
    borderBottom: '1px solid #192b4a',
  },
  purchaseButton: {
    marginTop: theme.spacing.unit * 2,
  },
  cancelButton: {
    marginTop: theme.spacing.unit * 2,
    backgroundColor: '#ff4a4a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e54242',
    },
  },
  resumeButton: {
    marginTop: theme.spacing.unit * 2,
  },
  subscribed: {
    color: 'green',
  },
});

class MemberSubscriptionAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: undefined,
      accountType: props.auth.familyMembers.length > 0 ? 'family' : 'personal',
    };

    props.member.standardMembershipExpire = new Date(
      props.member.standardMembershipExpire
    );

    if (
      props.member.standardMembershipStatus &&
      props.member.premiumSubscriptionStatus
    )
      this.state.plans = 'Standard & Premium';
    else if (props.member.standardMembershipStatus)
      this.state.plans = 'Standard';
    else if (props.member.premiumSubscriptionStatus)
      this.state.plans = 'Premium';
    else this.state.plans = 'None';
  }

  handleStandardOnClick = async () => {
    await this.props.selectedUserChange({
      id: this.props.member.id,
      fullName: this.props.member.name,
    });

    this.props.history.push({
      pathname: '/ohip_plan',
      state: {
        origin: '/dashboard/manageSubscriptions',
        redirect: '/dashboard/manageSubscriptions',
      },
    });
  };

  handlePremiumOnClick = async () => {
    if (this.state.accountType === 'family') {
      this.props.history.push(
        '/PaymentPlanMembership/YearlyFamilyPlan/dashboard'
      );
    } else {
      this.props.history.push('/PaymentPlanMembership/YearlyPlan/dashboard');
    }
  };

  renderStandardPurchaseBtn = (member) => {
    const { classes } = this.props;

    return member.ohipNumber ? (
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.purchaseButton}
        onClick={this.props.openStandardDialog}
      >
        Purchase
      </Button>
    ) : (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.purchaseButton}
        onClick={() => this.props.openOhipScreen(member.id)}
      >
        Upload OHIP Card
      </Button>
    );
  };

  render() {
    const { member, classes, standardDescription } = this.props;
    const { accountType } = this.state;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className={classes.summary}
        >
          <Grid container direction="row" alignContent="center">
            <Grid item xs={7}>
              <Typography color="primary">{member.name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                color="primary"
                variant="body2"
                align="left"
                className={classes.status}
              >
                Plan(s): {this.state.plans}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item>
              <Typography
                color="primary"
                variant="h6"
                fullWidth
                className={classes.sectionHeader}
              >
                Standard Subscription
              </Typography>
              <Typography variant="body2">{standardDescription}</Typography>
              <br />
              {accountType === 'family' && (
                <Fragment>
                  <Typography variant="body2">
                    <b>Note:</b> The Standard subscription applies to each
                    individual member and it cannot be shared between family
                    members.
                  </Typography>
                  <br />
                </Fragment>
              )}

              <Typography>
                <b>Status:</b>{' '}
                <span
                  className={
                    member.standardMembershipStatus ? classes.subscribed : ''
                  }
                >
                  {member.standardMembershipStatus
                    ? 'Subscribed'
                    : 'Not Subscribed'}
                </span>
              </Typography>

              {member.standardMembershipStatus ? (
                <Typography>
                  <b>Expires:</b>{' '}
                  {member.standardMembershipExpire.toLocaleString()}
                </Typography>
              ) : (
                this.renderStandardPurchaseBtn(member)
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

MemberSubscriptionAccordion = withRouter(MemberSubscriptionAccordion);

MemberSubscriptionAccordion = connect(
  mapStateToProps,
  actions
)(MemberSubscriptionAccordion);

export default withStyles(styles)(MemberSubscriptionAccordion);
