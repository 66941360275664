import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError } from 'redux-form';
import _ from 'lodash';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

// component imports
import {
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  withStyles,
  Button,
  Slide,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Fade,
  FormControlLabel,
  Checkbox,
  Divider,
  FormHelperText,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@material-ui/core';

import { ExpandMore, Add, Backspace } from '@material-ui/icons';

import { Autocomplete } from '@material-ui/lab';

const styles = (theme) => ({
  buttonSection: {
    marginTop: 8,
    marginBottom: 8,
  },
});

const duration_unit_array = ['Day (s)', 'Week (s)', 'Month (s)', 'Year (s)'];

const frequency_unit_array = [
  'OD',
  'BID',
  'TID',
  'QID',
  'QD',
  'q4h',
  'q6h',
  'q8h',
  'qam',
  'qhs',
  'Once a week',
];

const refills_array = ['0', '1', '2', '3'];

const SelectField = ({
  input: { name },
  input,
  label,
  options,
  children,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id={`${name}-label`} error={touched && error}>
        {label}
      </InputLabel>
      <Select
        {...input}
        label={label}
        labelId={`${name}-label`}
        id={`${name}-select`}
        error={touched && error}
        helperText={touched && error}
      >
        {options
          ? options.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))
          : children}
      </Select>
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

const CustomTextField = ({ input, label, type, meta: { error, touched } }) => {
  return (
    <FormControl fullWidth={true}>
      <TextField
        {...input}
        autoComplete="off"
        size="small"
        label={label}
        variant="outlined"
        error={touched && error}
        fullWidth={true}
        type={type}
        InputProps={{ inputProps: { min: 1 } }}
      />
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};
const LabelTextField = ({ input, label, type, meta: { error, touched } }) => {
  return (
    <FormControl fullWidth={true}>
      <TextField
        {...input}
        multiline
        autoComplete="off"
        size="small"
        label={label}
        variant="outlined"
        error={touched && error}
        fullWidth={true}
        type={type}
        InputProps={{ inputProps: { min: 1 } }}
      />
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

const MedicationAutocomplete = ({
  input,
  input: { onChange },
  label,
  options,
  selectMedication,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth>
      <Autocomplete
        // freeSolo
        // autoSelect
        size="small"
        id={input.name}
        value={input.value || []}
        options={options}
        onChange={(e, newValue) => {
          selectMedication(newValue);
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.name || ''}
        getOptionSelected={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField
            {...params}
            //{...input}
            label={label}
            error={touched && error}
            variant="outlined"
          />
        )}
      />
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

const CustomCheckbox = ({ input, label, meta: { error, touched } }) => {
  return (
    <FormControl fullWidth={true}>
      <FormControlLabel
        label={<Typography variant="body2">{label}</Typography>}
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            color="primary"
          />
        }
      />
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

function DashRxForm(props) {
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [openCodesDialog, setOpenCodesDialog] = useState(false);
  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [consentForm, setConsentForm] = useState({});
  const [luCodes, setLuCodes] = useState([]);
  const [selectedLuCodes, setSelectedLuCodes] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const medicationLimit = 6;

  const handlePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleConsentForm = async () => {
    setOpenConsentDialog(true);
    await axios
      .get(`/api/consent-form/${selectedMedication.consentId}`)
      .then((result) => {
        setConsentForm(result.data);
      });
  };

  const handleLuCodes = async () => {
    setOpenCodesDialog(true);
    await axios
      .post('/api/lu-codes', { codes: selectedMedication.LUCodes })
      .then((result) => setLuCodes(result.data));
  };

  const clearMedicationForm = () => {
    selectedLuCodes.forEach((code) => props.change(`luCode-${code}`, false));
    setSelectedMedication(null);
    setSelectedLuCodes([]);
    props.reset();
  };

  const addMedication = (values) => {
    const consentId = selectedMedication.consentId || null;
    let hiddenFieldArray = props.getMedicationList || [];
    const newData = {
      medicationId: values.medication._id,
      name: values.medication.name,
      dosage: values.doseQnty,
      dosageUnit: values.doseUnit,
      frequency: values.frequency,
      duration: values.duration,
      durationUnit: values.durationUnit,
      rxRefills: values.refills || '0',
      discontinue: values.discontinue || false,
      rxPrn: values.prn || false,
      bottleLabel: values.bottleLabel || '',
      consentId: consentId,
    };
    if (selectedLuCodes.length > 0) {
      newData.bottleLabel =
        newData.bottleLabel + ` [LU Code] ${selectedLuCodes.toString()}`;
    }
    hiddenFieldArray.push(newData);
    props.dispatch(
      change('finish-appointment-form', 'medications', hiddenFieldArray)
    );
    props.updateMedicationList(hiddenFieldArray);
    clearMedicationForm();
  };

  const LuCodeCheckbox = ({ input, label, meta: { error, touched } }) => {
    const handleCheckboxClick = (event) => {
      if (event.target.checked) {
        // If checkbox is checked, add the label to selectedCodes
        setSelectedLuCodes((prevCodes) => [...prevCodes, label]);
      } else {
        // If checkbox is unchecked, remove the label from selectedCodes
        setSelectedLuCodes((prevCodes) =>
          prevCodes.filter((code) => code !== label)
        );
      }

      // Call the redux-form input onChange handler to update the store
      input.onChange(event);
    };

    return (
      <FormControl>
        <FormControlLabel
          aria-label="LUCode"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          checked={input.value ? true : false}
          onChange={handleCheckboxClick}
          label={<Typography variant="body2">{label}</Typography>}
          control={<Checkbox color="primary" />}
        />
        {touched && error && <FormHelperText error>*{error}</FormHelperText>}
      </FormControl>
    );
  };

  return (
    <Fragment>
      <Fade in={true}>
        <form id="dash-rx-form" onSubmit={props.handleSubmit(addMedication)}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={4}>
              <Field
                name="medication"
                label="Medication Name"
                component={MedicationAutocomplete}
                options={props.medication}
                selectMedication={(value) => setSelectedMedication(value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="doseQnty"
                label="Quantity/Dose"
                options={selectedMedication ? selectedMedication.dosages : []}
                component={SelectField}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="doseUnit"
                label="Unit"
                options={selectedMedication ? selectedMedication.units : []}
                component={SelectField}
              />
            </Grid>
            {selectedMedication && selectedMedication.requiresConsent && (
              <Grid
                item
                xs={
                  selectedMedication &&
                  selectedMedication.LUCodes &&
                  selectedMedication.LUCodes.length > 0
                    ? 6
                    : 12
                }
              >
                <Button
                  size="small"
                  style={{ color: 'red' }}
                  onClick={handleConsentForm}
                >
                  Requires consent
                </Button>
              </Grid>
            )}

            {selectedMedication &&
              selectedMedication.LUCodes &&
              selectedMedication.LUCodes.length > 0 && (
                <Grid
                  item
                  container
                  xs={
                    selectedMedication && selectedMedication.requiresConsent
                      ? 6
                      : 12
                  }
                  justify="flex-end"
                >
                  <Button size="small" color="primary" onClick={handleLuCodes}>
                    LU Codes
                  </Button>
                </Grid>
              )}
            <Grid item xs={4}>
              <Field
                name="frequency"
                label="Frequency"
                component={SelectField}
                options={frequency_unit_array}
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="duration"
                label="Duration"
                type="number"
                component={CustomTextField}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="durationUnit"
                label="Unit"
                component={SelectField}
                options={duration_unit_array}
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="refills"
                label="Refills"
                type="number"
                component={SelectField}
                options={refills_array}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: 7 }}>
              <Field
                name="discontinue"
                label="Auto Discontinue"
                component={CustomCheckbox}
              />
            </Grid>
            <Grid item xs={4} style={{ marginTop: 7 }}>
              <Field name="prn" label="PRN" component={CustomCheckbox} />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="bottleLabel"
                label="Bottle Label"
                type="text"
                component={LabelTextField}
              />
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              xs={12}
              spacing={1}
              className={props.classes.buttonSection}
            >
              <Grid item>
                <Button
                  startIcon={<Backspace />}
                  size="small"
                  variant="outlined"
                  disabled={!selectedMedication}
                  onClick={() => clearMedicationForm()}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<Add />}
                  size="small"
                  variant="outlined"
                  type="submit"
                  disabled={
                    !selectedMedication ||
                    (selectedMedication &&
                      Array.isArray(props.getMedicationList) &&
                      props.getMedicationList.length >= medicationLimit)
                  }
                >
                  Add Medication
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Fade>

      <Dialog
        onClose={() => setOpenCodesDialog(false)}
        aria-labelledby="lu-code-dialog"
        open={openCodesDialog}
        scroll="paper"
      >
        <DialogTitle id="lu-code-dialog-title">LU Codes</DialogTitle>
        <DialogContent>
          {luCodes.length > 0 ? (
            luCodes.map((code, index) => (
              <Accordion
                key={code._id}
                expanded={expanded === code._id}
                onChange={handlePanel(code._id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls={`${code.luCode}-content`}
                  id={`code-${code.luCode}`}
                  key={`code-${code.lucode}`}
                  style={{ backgroundColor: '#f7f7f7' }}
                >
                  <Field
                    name={`luCode-${code.luCode}`}
                    label={`${code.luCode}`}
                    component={LuCodeCheckbox}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{code.description}</Typography>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <CircularProgress size={30} color="primary" />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCodesDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenConsentDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openConsentDialog}
        scroll="paper"
      >
        <DialogTitle id="consent-dialog-title">Consent Review</DialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ minWidth: '500px', backgroundColor: '#FFFFFF' }}
          >
            {consentForm.htmlString ? (
              ReactHtmlParser(consentForm.htmlString)
            ) : (
              <CircularProgress size={30} color="primary" />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConsentDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => {
  return {
    medication: state.medicalData.medicationArray,
  };
};

const validate = (values) => {
  const errors = {};

  if (values.refills !== undefined) {
    const refills = Number(values.refills);
    if (isNaN(refills) || refills < 0 || refills > 3) {
      errors.refills = 'Value must be a number between 0 & 3';
    }
  }

  if (values.duration !== undefined) {
    const duration = Number(values.duration);
    if (isNaN(duration) || duration <= 0) {
      errors.duration = 'Value must be a positive number';
    }
  }

  if (!values.duration) {
    errors.duration = 'Required';
  }

  if (!values.frequency) {
    errors.frequency = 'Required';
  }

  if (values.duration && !values.durationUnit) {
    errors.durationUnit = 'Required';
  }

  return errors;
};

DashRxForm = reduxForm({
  form: 'DashRxForm', // a unique identifier for this form
  validate: validate,
})(DashRxForm);

DashRxForm = connect(mapStateToProps)(DashRxForm);

export default withStyles(styles)(DashRxForm);
