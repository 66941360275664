import axios from 'axios';
import { FETCH_MEDICAL_PROFILE_COMPLETION } from './types';

export const submitWizard = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/profile/patient/medical-form', values);

  if (res.data) {
    dispatch({ type: FETCH_MEDICAL_PROFILE_COMPLETION, payload: true });
    history.push('/completed');
  } else {
    const url = '/medicalprofileform/123';
    //+ res.data.name.split(" ").join("_")
    history.push(url);
  }
};
