import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from '@material-ui/core';
import { ArrowBackIos, ExpandMore } from '@material-ui/icons';

import ConsentFormDialog from '../../dialogs/ConsentFormDialog';
import toast from 'react-hot-toast';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
});
class PdfRendering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConsentDialog: false,
      rxInfo: {},
    };
  }

  async handleFax(prescription) {
    try {
      await axios.get(`/api/prescriptions/${prescription._id}/resend`);
      this.props.getAppointmentHistory(this.props.selectedUser._id);
      this.props.closeDocument();
      toast.success('Prescription faxed');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Unknown error occured');
      }
      console.error(error);
    }
  }

  downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  renderAccordion = (rxData) => {
    return rxData.map((rx) => {
      const hasUnsignedConsent =
        rx.consentForms && rx.consentForms.length > 0 && !rx.isConsentSigned;
      return (
        <Accordion key={rx._id} style={{ width: '100%' }}>
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Typography variant="button">
              Rx: {rx._id.substring(19, 24)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                {rx.medications &&
                  rx.medications.length > 0 &&
                  rx.medications.map((m) => (
                    <>
                      <Typography variant="body2">
                        <strong> Medication:</strong> {m.name} {m.dosage}{' '}
                        {m.dosageUnit}
                      </Typography>
                      <Typography variant="body2">
                        <strong> Directions:</strong> {m.bottleLabel}
                      </Typography>
                    </>
                  ))}
              </Grid>
              {hasUnsignedConsent && (
                <Grid item>
                  <Typography variant="body2">
                    Please sign consent to fax prescription
                  </Typography>
                </Grid>
              )}

              {rx.destinationPharmacy &&
                (rx.prescriptionWasFaxed ||
                  rx.prescriptionWasFaxedDashboard) && (
                  <Grid item container xs={12}>
                    <Grid item>
                      <Typography variant="body2">
                        Prescription faxed to: {rx.destinationPharmacy.name}
                      </Typography>
                      <Typography variant="body2">
                        at: {rx.destinationPharmacy.faxNumber}
                      </Typography>
                      <Typography paragraph variant="body2">
                        on:{' '}
                        {new Date(rx.faxDate).toLocaleString('en-CA', {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </AccordionDetails>
          <AccordionActions style={{ backgroundColor: '#fcfcfc' }}>
            {(this.props.auth.patient.pharmacy.faxNumber ||
              (this.props.auth.patient.pharmacy.premiumPharmacy &&
                this.props.auth.patient.pharmacy.premiumPharmacy
                  .faxNumber)) && (
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={
                    rx.prescriptionWasFaxedDashboard || hasUnsignedConsent
                  }
                  onClick={() => {
                    this.handleFax(rx);
                  }}
                >
                  {rx.prescriptionWasFaxedDashboard
                    ? 'prescription faxed'
                    : 'fax prescription'}
                </Button>
              </Grid>
            )}
            {hasUnsignedConsent ? (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      rxInfo: { form: rx.consentForms[0], id: rx._id },
                      openConsentDialog: true,
                    });
                  }}
                >
                  Sign Consent
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadPDF(rx.documentSrc)}
                >
                  Download PDF
                </Button>
              </Grid>
            )}
          </AccordionActions>
          {this.state.openConsentDialog && (
            <ConsentFormDialog
              open={this.state.openConsentDialog}
              close={() => this.setState({ openConsentDialog: false })}
              rxInfo={this.state.rxInfo}
            />
          )}
        </Accordion>
      );
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <main className={classes.layout}>
          <Grid
            container
            style={{
              margin: 0,
              width: '100%',
              marginTop: '5%',
            }}
            direction="row"
          >
            <Grid item xs={9} sm={10}>
              <Typography
                variant="h6"
                className={classes.spacing}
                color="primary"
              >
                Rxs for appointment{' '}
                {this.props.rxData[0].appointment
                  .substring(19, 24)
                  .toUpperCase()}
              </Typography>
            </Grid>
            <Grid item container xs={3} sm={2} justify="flex-end">
              <Button
                startIcon={<ArrowBackIos />}
                variant="outlined"
                size="small"
                onClick={this.props.closeDocument}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              {this.renderAccordion(this.props.rxData)}
            </Grid>
            {!this.props.auth.patient.pharmacy.premiumPharmacy &&
              !this.props.auth.patient.pharmacy.faxNumber && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.props.addPharmacy()}
                >
                  Add Pharmacy
                </Button>
              )}
          </Grid>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
  };
}

PdfRendering = connect(mapStateToProps, actions)(PdfRendering);

export default withStyles(styles)(PdfRendering);
