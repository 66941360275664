import React, { Component } from 'react';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

class RegistrationPasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordButton: false,
    };
  }

  handlePasswordButton = () => {
    this.setState((prevState) => ({
      passwordButton: !prevState.passwordButton,
    }));
  };

  render() {
    const { input, name, label, touched, error } = this.props;
    return (
      <TextField
        {...input}
        error={touched && error}
        helperText={touched && error}
        fullWidth
        id={name}
        name={name}
        type={this.state.passwordButton ? 'text' : 'password'}
        label={label}
        variant="outlined"
        size="small"
        autoComplete="new-password"
        InputProps={{
          autoCapitalize: 'none',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle-password-visibility"
                onClick={this.handlePasswordButton}
                edge="end"
              >
                {this.state.passwordButton ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default RegistrationPasswordField;
