import React, { Component, Fragment } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions';
import logoMain from '../../SnapMedLogoMain.png';
import logoMainRetina from '../../SnapMedLogoMainRetina.png';
import axios from 'axios';
import InputMask from 'react-input-mask';
import LoginService from '../../login/LoginService';
import {
  Grid,
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Fade,
  Snackbar,
  Collapse,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import TextButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import PasswordField from '../../registration/RegistrationPasswordField';

const styles = (theme) => ({
  layout: {
    display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  passwordCard: {
    boxShadow: '0px 5px 5px -1px gray',
    backgroundColor: 'aliceblue',
    borderRadius: '4px',
  },
});

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    size="small"
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const passwordField = ({ input, label, name, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <PasswordField
      input={input}
      name={name}
      label={label}
      touched={touched}
      error={error}
    />
  </FormControl>
);

const authPinField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <InputMask mask="999999" {...input} onChange={input.onChange} maskChar="">
      {(inputProps) => (
        <TextField
          {...inputProps}
          autoFocus
          disableUnderline
          type="text"
          label={label}
          id={id}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </InputMask>
  </FormControl>
);

class PharmacyLoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.loginService = new LoginService();

    this.state = {
      passwordIsMasked: true,
      openSnackbar: false,
      verification: false,
      authPin: '',
      authError: '',
      resendPinMessage: false,
      showResetPasswordForm: false,
      email: '',
      loadingResetPassword: false,
      forgotEmailMessage: '',
    };
  }

  async handleVerification(values) {
    const result = await axios.post('/api/two-factor-auth', values);
    if (!result.data.error) {
      this.setState({ verification: true });
    } else {
      this.setState({ authError: result.data.error });
    }
  }

  handleLogin(values) {
    this.props.pharmacyLogin(values, this.props.history);
  }

  async handleResendPin(email) {
    const result = await axios.post('/api/resend-pin', { email: email });
    if (!result.data.error) {
      this.setState({ resendPinMessage: true });
    }
  }

  async submitForgotEmail(email) {
    const res = await this.loginService.forgotPharmacyPassword(email);
    this.setState({ forgotEmailMessage: res, loadingResetPassword: false });
  }

  handleClose = () => {
    this.setState({
      forgotEmailMessage: '',
      email: '',
      loadingResetPassword: false,
    });
  };

  render() {
    const { handleSubmit, classes, auth } = this.props;
    const {
      verification,
      authError,
      pinError,
      showResetPasswordForm,
      loadingResetPassword,
    } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <img
                alt="SnapMed Logo"
                src={logoMain}
                srcSet={`${logoMainRetina} 2x,`}
                style={{
                  marginBottom: '10px',
                }}
              />
              <Typography variant="h5" color="primary">
                PHARMACY
              </Typography>
              {!verification ? (
                <Fragment>
                  <form
                    className={classes.form}
                    onSubmit={handleSubmit((values) =>
                      this.handleVerification(values)
                    )}
                  >
                    <Field
                      name="username"
                      label="Pharmacy Username"
                      type="email"
                      component={usernameField}
                    />
                    <Field
                      name="password"
                      label="Password"
                      type="password"
                      classes={this.props.classes}
                      component={passwordField}
                      passwordIsMasked={this.state.passwordIsMasked}
                    />
                    {authError !== '' && (
                      <Typography color="error" variant="body1">
                        {authError}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign in
                    </Button>
                  </form>

                  {/* control for show reset password form*/}
                  <TextButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        showResetPasswordForm: !showResetPasswordForm,
                      });
                    }}
                  >
                    forgot password?
                  </TextButton>

                  {/* reset password form content */}
                  <Collapse in={showResetPasswordForm}>
                    <Grid container className={classes.passwordCard}>
                      <Grid item xs={12} container justify="flex-end">
                        <IconButton
                          onClick={() =>
                            this.setState({ showResetPasswordForm: false })
                          }
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                      <Grid
                        itemasync
                        container
                        xs={12}
                        justify="center"
                        style={{
                          padding: '0px 20px 20px 20px',
                        }}
                      >
                        <Typography variant="body1" color="primary">
                          Email associated to your account:
                        </Typography>
                        <TextField
                          id="email"
                          type="email"
                          label="Email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          fullWidth
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: '15px 0 0 10px',
                          }}
                          onClick={(_) => {
                            this.submitForgotEmail(this.state.email);
                            this.setState({
                              showResetPasswordForm: false,
                              loadingResetPassword: true,
                            });
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Collapse>
                  {loadingResetPassword && (
                    <CircularProgress size={25} color="primary" />
                  )}
                </Fragment>
              ) : (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(this.handleLogin)}
                >
                  <Grid item xs={12} style={{ margin: '15px 0px' }}>
                    <Typography color="primary">
                      We've sent a 6 digit PIN to <b>{this.props.email}</b>.
                    </Typography>
                  </Grid>
                  <Field
                    name="authPin"
                    label="Enter PIN"
                    type="text"
                    component={authPinField}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '10px' }}
                    container
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12} c container justify="center">
                      <Typography color="primary">
                        Didn't get the PIN?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => this.handleResendPin(this.props.email)}
                      >
                        Resend PIN
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.resendPinMessage}
                onClose={() => this.setState({ resendPinMessage: false })}
                autoHideDuration={2000}
                message="Email with PIN was sent."
              />
            </Paper>
          </Fade>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.forgotEmailMessage ? true : false}
            autoHideDuration={3000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id">{this.state.forgotEmailMessage}</span>
            }
            action={
              <Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <Close />
                </IconButton>
              </Fragment>
            }
          />
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.username) {
    errors.username = 'Field required';
  }
  if (!values.password) {
    errors.password = 'Field required';
  }

  return errors;
}

PharmacyLoginForm = reduxForm({
  validate,
  form: 'pharmacyLoginForm',
  destroyOnUnmount: true,
})(PharmacyLoginForm);

const selector = formValueSelector('pharmacyLoginForm');

function mapStateToProps(state) {
  return {
    auth: state.auth,
    idleLogout: state.idleLogout,
    email: selector(state, 'username'),
  };
}
PharmacyLoginForm = connect(
  mapStateToProps,
  actions
)(withRouter(PharmacyLoginForm));

export default withStyles(styles)(PharmacyLoginForm);
