import React, { Fragment } from 'react';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const PersonalMedicalCompletionPrompt = (props) => {
  let url = props.selectedUser._id;
  if (props.auth.finishedProfile) {
    return (
      <Fragment>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            props.setPatientId(props.selectedUser._id);
            props.history.push('/your_symptoms');
          }}
          disabled={
            (props.selectedUser.completed && !props.selectedUser.isActive) ||
            props.disabled
          }
          style={{ minWidth: '183px' }}
        >
          I will pay privately.
          <br />
          See doctor
        </Button>
        <br />
      </Fragment>
    );
  } else {
    url = '/medicalprofileform/' + url;
    return (
      <Fragment>
        <Typography variant="subtitle1">
          Please complete your Medical Profile in order to see a doctor.
        </Typography>
        <Button variant="contained" color="primary">
          <Link
            style={{ color: 'white', textDecoration: 'none', maxWidth: 220 }}
            to={url}
          >
            Complete Medical Profile - {props.auth.name.first}
          </Link>
        </Button>
        <br />
      </Fragment>
    );
  }
};

export default PersonalMedicalCompletionPrompt;
