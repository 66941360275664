import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  ExpandMore,
  Update,
  Save,
  Cancel,
  LocalPharmacy,
  CheckCircleOutline,
} from '@material-ui/icons';

const styles = (theme) => ({
  title: {
    marginBottom: '15px',
  },
  tableHeader: {
    padding: '0px 55px 0px 15px',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  infoTag: {
    color: theme.palette.text.secondary,
  },
  confirmButton: {
    color: 'mediumseagreen',
  },
});

const FormTextField = ({
  input,
  name,
  label,
  disabled,
  meta: { touched, error },
}) => (
  <FormControl fullWidth id={name} error={touched && !!error}>
    <TextField
      {...input}
      //required
      size="small"
      disabled={disabled}
      fullWidth
      multiline
      variant="outlined"
      label={label}
      type="text"
      id={name}
      error={error && touched}
      helperText={error && touched ? error : null}
    />
  </FormControl>
);

class ActiveDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedPanel: '',
      selectedDelivery: '',
      openDialog: false,
      statusValue: '',
      notesValue: '',
      disableSaveButton: false,
      disablePrescriptionButton: false,
      renderWarning: false,
    };
  }

  componentDidUpdate() {
    this.setState((prevState) => {
      if (prevState.notesValue === '') {
        return { notesValue: this.state.selectedDelivery.notes };
      }
    });
  }

  handleOpenPrescription = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  renderStatusChip = (status) => {
    return (
      <Chip
        label={status.name}
        style={{
          color: 'white',
          backgroundColor: status.color,
        }}
        size="small"
      />
    );
  };

  handleStatusChange(value) {
    this.setState({ statusValue: value });
    const selectedValue = value;
    const optionsWithSms = this.props.statuses.filter((status) => {
      return status.smsMessage;
    });
    const isNotSmsOption = optionsWithSms.every(
      (option) => selectedValue !== option._id
    );
    if (!isNotSmsOption) {
      this.setState({ renderWarning: true, disableSaveButton: true });
    } else {
      this.setState({ renderWarning: false, disableSaveButton: false });
    }
  }

  handleDialogClose() {
    this.setState({
      openDialog: false,
      statusValue: '',
      notesValue: '',
      renderWarning: false,
      disableSaveButton: false,
    });
  }

  async handleSaveStatus() {
    const { selectedDelivery, statusValue, notesValue } = this.state;
    const values = {
      delivery: selectedDelivery._id,
      status: statusValue,
      notes: notesValue,
      customerName: selectedDelivery.customer.name,
      customerPhoneNumber: selectedDelivery.customer.mobilePhone,
      pharmacy: selectedDelivery.pharmacy,
    };
    const result = await axios.post('/api/delivery-status-update', values);
    if (result.data) {
      this.setState({
        statusValue: '',
        expandedPanel: '',
        renderWarning: false,
        disableSaveButton: false,
      });
      this.props.getDeliveriesList();
      this.handleDialogClose();
    }
  }

  sortStatuses(statuses) {
    const sorted = statuses.sort((a, b) => {
      return a.status - b.status;
    });
    return sorted;
  }

  addDigits = (value) => {
    switch (value.toString().length) {
      case 3:
        return '0';
      case 2:
        return '00';
      default:
        return '000';
    }
  };

  renderWarningMessage() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="button" color="error">
            SMS will be sent to customer!
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.confirmButton}
            startIcon={<CheckCircleOutline />}
            size="small"
            onClick={() => this.setState({ disableSaveButton: false })}
          >
            Confirm?
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderAccordion() {
    const { classes } = this.props;
    const { disablePrescriptionButton } = this.state;
    const { expandedPanel } = this.state;
    const deliveries = this.props.deliveries;
    const addDigits = (value) => {
      switch (value.toString().length) {
        case 3:
          return '0';
        case 2:
          return '00';
        default:
          return '000';
      }
    };
    const accordion = [];
    deliveries.forEach((item) => {
      if (
        item.status &&
        item.status.status !== 5 &&
        item.status.status !== 6 &&
        item.status.status !== 7
      ) {
        accordion.push(
          <Accordion
            key={item._id}
            id={item._id}
            expanded={expandedPanel === item._id}
            onChange={(event, isExpanded) => {
              this.setState({
                expandedPanel: isExpanded ? item._id : false,
                selectedDelivery: item,
              });
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid item xs={1}>
                <Typography className={classes.accordionHeading} align="center">
                  {addDigits(item.number)}
                  {item.number}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.accordionHeading} align="center">
                  {item.customer.name.first} {item.customer.name.last}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.accordionHeading} align="center">
                  {new Date(item.date).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.accordionHeading} align="center">
                  {new Date(item.dueDate).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={2} container justify="center">
                {this.renderStatusChip(item.status)}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Patient
                  </Typography>
                  <Typography color="primary">
                    {item.customer.name.first} {item.customer.name.last}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Phone Number
                  </Typography>
                  <Typography color="primary">{item.phoneNumber}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Address
                  </Typography>
                  <Typography color="primary">{item.address}</Typography>
                  <Typography color="primary">
                    {item.city}, {item.province}
                  </Typography>
                  <Typography color="primary">{item.postalCode}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Delivery Notes
                  </Typography>
                  <Typography
                    color="primary"
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {item.notes}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
            <AccordionActions>
              <Button
                startIcon={<LocalPharmacy />}
                variant="outlined"
                color="primary"
                size="small"
                disabled={!item.prescription || disablePrescriptionButton}
                onClick={() =>
                  this.handleOpenPrescription(item.prescription.documentSrc)
                }
              >
                Prescription
                {this.state.disablePrescriptionButton && (
                  <CircularProgress
                    size={15}
                    color="white"
                    style={{
                      marginLeft: 5,
                    }}
                  />
                )}
              </Button>
              <Button
                startIcon={<Update />}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.setState({ openDialog: true })}
              >
                Update
              </Button>
            </AccordionActions>
          </Accordion>
        );
      }
    });
    if (accordion.length > 0) {
      return accordion;
    } else {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            color: '#bcbcbc',
            height: '30vh',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              No active deliveries.
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }

  render() {
    const { classes, auth, statuses } = this.props;
    const {
      openDialog,
      selectedDelivery,
      statusValue,
      notesValue,
      disableSaveButton,
      renderWarning,
    } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h5" color="primary">
            Active Deliveries
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container className={classes.tableHeader}>
            <Grid item xs={1}>
              <Typography variant="subtitle2" align="center">
                No.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Customer
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Due
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" align="center">
                Status
              </Typography>
            </Grid>
          </Grid>
          {this.renderAccordion()}
        </Grid>
        <Dialog
          fullWidth
          open={openDialog}
          onClose={() => this.handleDialogClose()}
        >
          <DialogTitle>Update Delivery</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Prescription No.
                </Typography>
                <Typography color="primary">
                  {this.addDigits(
                    selectedDelivery ? selectedDelivery.number : ''
                  )}
                  {selectedDelivery.number}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Patient
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.customer &&
                    selectedDelivery.customer.name.first}{' '}
                  {selectedDelivery.customer &&
                    selectedDelivery.customer.name.last}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Address
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.address}
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.city}, {selectedDelivery.province}
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Phone Number
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    variant="outlined"
                    label="Delivery Notes"
                    type="text"
                    id="deliveryNotes"
                    value={notesValue ? notesValue : selectedDelivery.notes}
                    onChange={(e) =>
                      this.setState({ notesValue: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  // error={touched && error}
                >
                  <InputLabel>Update Status</InputLabel>
                  <Select
                    id="active-status-update"
                    input={<OutlinedInput label="Update Status" />}
                    value={
                      statusValue
                        ? statusValue
                        : selectedDelivery.status && selectedDelivery.status._id
                    }
                    onChange={(e) => this.handleStatusChange(e.target.value)}
                  >
                    {this.sortStatuses(statuses).map((status) => (
                      <MenuItem value={status._id} key={status._id}>
                        {status.name}{' '}
                        {status.smsMessage && '- SMS WILL BE SENT'}
                      </MenuItem>
                    ))}
                  </Select>
                  {/*<FormHelperText>{touched && error}</FormHelperText>*/}
                </FormControl>
              </Grid>
              {renderWarning && this.renderWarningMessage()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Cancel />}
              style={{
                color: 'crimson',
              }}
              onClick={() => this.handleDialogClose()}
            >
              Cancel
            </Button>
            <Button
              startIcon={<Save />}
              variant="contained"
              color="primary"
              size="small"
              disabled={disableSaveButton || !statusValue}
              onClick={() => this.handleSaveStatus()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
    statuses: state.deliveryStatusesList,
  };
}

ActiveDeliveries = connect(
  mapStateToProps,
  actions
)(withRouter(ActiveDeliveries));

export default withStyles(styles)(ActiveDeliveries);
